import { defineStyleConfig } from '@chakra-ui/react';

const DEFAULT = {
  color: 'white',
  cursor: 'pointer',
};

const COMPLETED = {
  ...DEFAULT,
  borderRadius: 'full',
  bg: 'clay.0',
};

const UNCOMPLETED = {
  ...COMPLETED,
  bg: 'onyx.0',
};

const TODAY = {
  ...DEFAULT,
  border: '2px solid',
  borderColor: 'transparent',
  borderRadius: 'full',
  bg: 'sand.0',
};

const SELECTED = {
  color: 'white',
  borderRadius: 'full',
  border: '2px solid',
  borderColor: 'beige.0',
};

// for ease of readability
const FUTURE = DEFAULT;

export const CALENDAR_DAY_CELL_CONFIG = defineStyleConfig({
  // The styles all Cards have in common
  baseStyle: {
    h: '40px',
    w: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 6,
    position: 'relative',
    cursor: 'default',
    _hover: {
      borderRadius: 'full',
      opacity: 0.63,
    },
  },
  variants: {
    default: DEFAULT,
    'default-COMPLETED': COMPLETED,
    'default-UNCOMPLETED': UNCOMPLETED,
    nextMonth: {
      color: 'rgba(235, 235, 245, 0.3)',
    },
    today: TODAY,
    'today-COMPLETED': TODAY, // I think this is correct, but it was decided differently { ...TODAY, ...COMPLETED },
    'today-UNCOMPLETED': TODAY, // I think this is correct, but it was decided differently { ...TODAY, ...UNCOMPLETED },
    selected: SELECTED,
    'selected-today': {
      ...SELECTED,
    },
    future: FUTURE,
  },
  // The default variant value
  defaultProps: {
    variant: 'default',
  },
});
