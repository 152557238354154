import { Box } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';

export const FilterContentBlock: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box borderTop="1px solid" borderColor="stone.0" py="6" as="section">
      {children}
    </Box>
  );
};
