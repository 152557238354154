import Helmet from 'react-helmet';
import { OG_IMAGE_DEFAULT } from '../../constants';

type OgImageType = {
  url: string;
  type: string;
  width: number | string;
  height: number | string;
};

export const OgMetaTags = ({
  image = {
    url: OG_IMAGE_DEFAULT,
    type: 'image/jpeg',
    width: '1024',
    height: '779',
  },
}: {
  image?: OgImageType | null;
}) => {
  return (
    image && (
      <Helmet>
        <meta property="og:image" content={image.url} />
        <meta property="og:image:type" content={image.type} />
        <meta property="og:image:width" content={String(image.width)} />
        <meta property="og:image:height" content={String(image.height)} />
      </Helmet>
    )
  );
};
