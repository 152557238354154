import { collection, getDocs } from 'firebase/firestore';
import { useEffect, useMemo } from 'react';

import { firestore } from '../../../firebase';
import { TrainingDetail, useStore } from '../store';

export const useTrainingDetails = () => {
  const trainingDetails = useStore((state) => state.trainingDetails);
  const setTrainingDetails = useStore((state) => state.setTrainingDetails);

  const trainingsRef = useMemo(() => collection(firestore, 'classTypes'), []);

  useEffect(() => {
    if (trainingDetails.length === 0) {
      let tempTraining: TrainingDetail[] = [];

      const loadTrainingDetails = async () => {
        const docsRef = await getDocs(trainingsRef);
        docsRef.forEach((doc) => {
          if (doc.exists()) {
            tempTraining.push(doc.data() as TrainingDetail);
          }
        });

        setTrainingDetails(tempTraining);
      };

      loadTrainingDetails();
    }
  }, [trainingsRef, trainingDetails, setTrainingDetails]);

  return trainingDetails;
};
