import { Video, isH265Supported, WithChakraClassNameWrapper } from '../../';
import { SwiperSlide } from 'swiper/react';
import { CSSProperties, FC, PropsWithChildren, ReactNode } from 'react';
import { ClassSession } from '../../types/class';

export type AnySwiperEntity = Pick<
  ClassSession,
  'id' | 'thumbnail' | 'trailer'
> & { children?: ReactNode; videoStyles?: CSSProperties };

type TSwipeFeedItem = {
  slideItem: AnySwiperEntity;
  shouldStop: boolean;
  muted: boolean;
  onClick: (video: HTMLVideoElement) => void;
  className?: string;
  children?: ReactNode;
};

export const SwiperVideoFeedItem: FC<PropsWithChildren<TSwipeFeedItem>> = ({
  slideItem,
  shouldStop,
  muted,
  onClick,
  children,
}) => (
  <WithChakraClassNameWrapper styleConfigName="ChakraSwiperSlide">
    <SwiperSlide>
      <Video
        poster={{
          mobile: slideItem?.thumbnail?.portrait,
          desktop: slideItem?.thumbnail?.landscape,
        }}
        src={{
          mobile: isH265Supported
            ? slideItem?.trailer.portrait.h265
            : slideItem?.trailer.portrait.h264,
          desktop: isH265Supported
            ? slideItem?.trailer.landscape.h265
            : slideItem?.trailer.landscape.h264,
        }}
        shouldStop={shouldStop}
        muted={muted}
        onClick={onClick}
        videoStyles={slideItem.videoStyles}
      />
      {children}
    </SwiperSlide>
  </WithChakraClassNameWrapper>
);

SwiperVideoFeedItem.displayName = 'SwiperSlide';
