import { ROUTES } from '../../router';
import { DescreteSlider, StepWrapper } from '../components';
import { useOnboardingStep } from '../presenter';

export const Train = () => {
  const { descreteSliderProps, goToNextStep, setOnboardingData, name } =
    useOnboardingStep(`${ROUTES.ONBOARDING}/${ROUTES.ONBOARDING_FUEL}`, 3);

  const onNextClick = () => {
    setOnboardingData({
      train: descreteSliderProps.sliderValue,
    });
    goToNextStep();
  };

  return (
    <StepWrapper
      title="Train"
      text={`${name}, how many times a week do you workout?`}
      buttonText="Next"
      onClick={onNextClick}
      step={1}
    >
      <DescreteSlider
        {...descreteSliderProps}
        min={1}
        max={8}
        startTitle="1"
        endTitle="7+"
      />
    </StepWrapper>
  );
};
