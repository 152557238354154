import { Box, useStyleConfig } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';
import { ScheduleItem } from '../../model/types';

export type CalendarDayCellProps = {
  variant: string;
  selected: boolean;
  daysData?: Partial<ScheduleItem>[];
  setSelected?: () => void;
  isDayInFuture?: boolean;
};

export const CalendarDayCell: FC<PropsWithChildren<CalendarDayCellProps>> = (
  props
) => {
  const { variant, isDayInFuture, daysData, selected, setSelected, children } =
    props;

  const dayResult = daysData
    ? daysData.some((day) => day.dayResult === 'COMPLETED')
      ? 'COMPLETED'
      : 'UNCOMPLETED'
    : false;

  const variantToSet =
    selected && variant === 'today'
      ? 'selected-today'
      : selected
      ? 'selected'
      : isDayInFuture
      ? 'future'
      : dayResult
      ? `${variant}-${dayResult}`
      : variant;

  const styles = useStyleConfig('CalendarDayCell', {
    variant: variantToSet,
  });

  return (
    <Box
      __css={styles}
      data-testid={variantToSet}
      onClick={setSelected}
      children={children}
    />
  );
};
