import { useEffect, useMemo, useState } from 'react';
import { Badge, BadgesData } from '../../types/badge';
import { useStore } from '../store';
import { firestore } from '../../../firebase';
import { doc, DocumentReference, getDoc, onSnapshot } from 'firebase/firestore';

export type BadgesLoadingData = {
  data: Badge[] | null;
  loading: boolean;
  error: null | string;
};

export const Badges_INITIAL_STATE = {
  data: null,
  loading: false,
  error: null,
};

async function asyncQuery(
  ref: DocumentReference,
  setter: (d: BadgesLoadingData) => void
) {
  const docSnap = await getDoc(ref);

  if (!docSnap.exists()) {
    setter({
      data: null,
      loading: false,
      error: 'No data for week!',
    });
  }
}

export const useBadgesData = () => {
  const [badges, setBadges] = useState<BadgesLoadingData>(Badges_INITIAL_STATE);
  const user = useStore((state) => state.user);
  const badgesRef = useMemo(() => doc(firestore, 'users', user!.uid), [user]);

  useEffect(() => {
    if (!badgesRef) return;
    asyncQuery(badgesRef, setBadges);
  }, [badgesRef]);

  // Initial set of data and subscription for future data updates.
  // initial happens after `asyncQuery` is completed
  useEffect(() => {
    if (!badgesRef) return;
    onSnapshot(badgesRef, {
      next(snapshot) {
        if (snapshot.exists()) {
          setBadges({
            data: Object.values(snapshot.data().badges_v2 as BadgesData).sort(
              (a, b) => a.order - b.order
            ),
            loading: false,
            error: null,
          });
        }
      },
      error(error) {
        console.error(error);
      },
    });
  }, [setBadges, badgesRef]);

  return badges;
};
