import { useBoolean } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useClassesData } from '../hooks/use-classes';

import { isDayInFuture } from '../../common/utils/isDayInFuture';
import { TrailerFooter } from '../../home/components/trailer-footer';
import { useStore } from '../../common/model';

const isFiltered = <T extends unknown>(filters: T[], value: T): boolean => {
  if (filters.length === 0) return true;
  return filters.includes(value);
};

export const useTrainingList = () => {
  const [isOpen, setIsOpen] = useBoolean(false);
  const filters = useStore((state) => state.filters);

  const { data } = useClassesData();

  const training = useMemo(() => {
    if (!data) return [];

    return data
      .filter((training) => {
        if (isDayInFuture(training)) return false;
        if (filters.coach && filters.coach !== training.coach) return false;
        if (
          !isFiltered(
            filters.duration,
            Number(training.duration.replace(' min', ''))
          )
        )
          return false;
        if (!isFiltered(filters.type, training.type)) return false;

        return true;
      })
      .map((training) => ({
        ...training,
        children: <TrailerFooter slideItem={training} />,
      }));
  }, [data, filters.coach, filters.duration, filters.type]);

  return { isOpen, setIsOpen, training };
};
