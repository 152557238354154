import {
  VStack,
  Heading,
  Text,
  Button,
  Link,
  Divider,
  Image,
  HStack,
} from '@chakra-ui/react';
import { FC } from 'react';
import { DEVICES_IMG_URL } from '../../common';

import { SubscriptionItem } from '../../subscription/components/subscription-item';
import { InfoCarousel } from '../components';
import { FAQ } from '../components/faq';
import { useStartInfo } from '../presenter';

interface StartInfoProps {
  onSetTrainingDetails(): void;
}

export const StartInfo: FC<StartInfoProps> = ({ onSetTrainingDetails }) => {
  const { onStartClick, onHaveAccountClick } = useStartInfo();
  return (
    <VStack spacing="4" color="clay.3" w="100%">
      <Heading textAlign="center" fontSize="2xl" fontWeight="semibold">
        House of Athlete Plus
      </Heading>
      <Text textAlign="center" fontWeight="normal" lineHeight="tall">
        Real Coaches. Real Programs. <br />
        Real Results. <br />
        Any Athlete, Anywhere.
      </Text>
      <VStack py="2" spacing="4" w="100%">
        <Button
          variant="hoa-primary"
          onClick={onStartClick}
          w="100%"
          maxW="20rem"
        >
          Start
        </Button>
        <Link onClick={onHaveAccountClick} color="clay.0" fontWeight="medium">
          I already have an account
        </Link>
      </VStack>
      <Text textAlign="center" fontWeight="normal" lineHeight="tall">
        HOA+ is an all-new digital experience designed to provide high-level,
        holistic training for the athlete within us all.
      </Text>
      <Text textAlign="center" fontWeight="normal" lineHeight="tall">
        Connecting real athletes with real coaches to unlock real physical and
        mental fitness results.
      </Text>
      <Divider pt="4" />
      <Heading textAlign="center" fontSize="xl" fontWeight="medium" pt="4">
        HOA+ Easy to use, anywhere anytime
      </Heading>
      <Image src={DEVICES_IMG_URL} />
      <HStack spacing="3.5" w="100%" maxW="20rem">
        <SubscriptionItem
          subscriptionType="Coaching"
          price={39.99}
          description="Includes daily classes and a personal coach to keep you on track with your goals."
          borderColor="stone.400"
        />
        <SubscriptionItem
          subscriptionType="Personal Training"
          price={149.99}
          description="Includes daily classes, a personal coach and live 1:1 personal training every week."
          borderColor="sand.0"
        />
      </HStack>
      <Divider pt="4" />
      <InfoCarousel />
      <Button
        variant="unstyled"
        color="clay.0"
        fontWeight="medium"
        onClick={onSetTrainingDetails}
        h="auto"
      >
        Learn more about our classes
      </Button>
      <Divider pt="4" />
      <FAQ />
    </VStack>
  );
};
