import {
  Box,
  Heading,
  Image,
  VStack,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@chakra-ui/react';
import { FC } from 'react';
import type { TrainingDetail } from '../../common/model';
import { CoachesIcon } from './coaches-icon';
import { DurationIcon } from './duration-icon';
import { EquipmentIcon } from './equipments-icon';
import { TrainingDetailsItems } from './training-details-item';

interface TrainingDetailsAccordionProps {
  trainingDetails: TrainingDetail[];
}

export const TrainingDetailsAccordion: FC<TrainingDetailsAccordionProps> = ({
  trainingDetails,
}) => (
  <Box maxWidth="326px">
    <Heading
      as="h5"
      fontSize="xl"
      fontWeight="medium"
      textAlign="center"
      mb="6"
    >
      HOA+ Classes
    </Heading>
    <Accordion allowToggle>
      {trainingDetails.map(
        ({
          id,
          imageUrl,
          name,
          description,
          coaches,
          equipment,
          duration,
        }: TrainingDetail) => (
          <AccordionItem
            borderTop={0}
            mb="4"
            key={id}
            borderBottom="0"
            w="100%"
          >
            <AccordionButton
              position="relative"
              borderRadius="8px"
              overflow="hidden"
              h="206px"
              p="0"
            >
              <Image w="100%" h="100%" src={imageUrl} objectFit="cover" />
              <Text position="absolute" left="24px" bottom="20px" fontSize="xl">
                {name}
              </Text>
            </AccordionButton>
            <AccordionPanel>
              <Heading fontSize="xl" fontWeight="normal" mt="4">
                {description?.short}
              </Heading>
              <VStack
                w="100%"
                spacing="2"
                py="4"
                borderY="1px solid"
                borderColor="stone.0"
                alignItems="flex-start"
                my="4"
              >
                <TrainingDetailsItems text={coaches} Icon={CoachesIcon} />
                <TrainingDetailsItems text={equipment} Icon={EquipmentIcon} />
                <TrainingDetailsItems text={duration} Icon={DurationIcon} />
              </VStack>
              <Text fontSize="xs" color="stone.400">
                {description.long}
              </Text>
            </AccordionPanel>
          </AccordionItem>
        )
      )}
    </Accordion>
  </Box>
);
