export const MODAL_CONFIG = {
  variants: {
    'hoa-bottom-modal': {
      dialog: {
        mb: 0,
        mt: '144px',
        position: 'absolute',
        bottom: '0',
        bg: 'card',
        borderRadius: '24px 24px 0 0',
        p: '32px',
      },
      header: {
        p: 0,
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        alignItems: 'center',
        mb: 8,
      },
    },
  },
};
