import { Flex, Text } from '@chakra-ui/react';

export const TrainingDetailsItems = ({ Icon, text }: any) => (
  <Flex w="100%" alignItems="center">
    <Icon fontSize="24px" color="stone.400" mr="2" />
    <Text fontSize="xs" fontWeight="500">
      {text}
    </Text>
  </Flex>
);
