import { Appearance, loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { Card } from './card';
import { Plan } from './plan';
import { useSubscriptionContext } from '../model/subscription-provider';

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string
);

const appearance: Appearance = {
  theme: 'night',
};

export const Payment = () => {
  const { view, stripeData } = useSubscriptionContext();

  if (view === 'card') {
    if (!stripeData.clientSecret) return <div>Loading</div>;

    return (
      <Elements
        stripe={stripePromise}
        options={{
          // clientSecret: 'pi_3LwSE7IjHajTRE2w1KEM5RMm_secret_ICVppwO7Dc2TbIbxDvk2vY2Oa',
          clientSecret: stripeData.clientSecret,
          appearance,
        }}
      >
        <Card />
      </Elements>
    );
  }

  return <Plan />;
};
