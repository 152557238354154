import { collection, getDocs, onSnapshot } from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { Week } from '../../types/week';
import { useStore } from '../store';
import { firestore } from '../../../firebase';
import { CollectionReference } from '@firebase/firestore-types';

export type WeeksLoadingData = {
  data: null | Week[];
  loading: boolean;
  error: null | string;
};

export const Weeks_INITIAL_STATE = {
  data: null,
  loading: false,
  error: null,
};

async function asyncQuery(
  ref: CollectionReference,
  setter: (d: WeeksLoadingData) => void
) {
  // @ts-ignore // TODO
  const docSnap = await getDocs(ref);

  if (!docSnap) {
    setter({
      data: null,
      loading: false,
      error: 'No data for week!',
    });
  }
}

export const useWeeksData = (userId?: string) => {
  const [weeks, setWeeks] = useState<WeeksLoadingData>(Weeks_INITIAL_STATE);
  const user = useStore((state) => state.user);
  const weeksRef = useMemo(
    () => collection(firestore, 'users', userId || user!.uid, 'weeks'),
    [user, userId]
  );

  useEffect(() => {
    // @ts-ignore // TODO
    asyncQuery(weeksRef, setWeeks);
  }, [weeksRef]);

  // Initial set of data and subscription for future data updates.
  // initial happens after `asyncQuery` is completed
  useEffect(() => {
    onSnapshot(weeksRef, {
      async next(snapshot) {
        if (snapshot) {
          const docs = snapshot.docs;
          const data = docs.map((doc) => doc.data());

          setWeeks({
            data: data as Week[],
            loading: false,
            error: null,
          });
        }
      },
      error(error) {
        console.error(error);
      },
    });
  }, [setWeeks, weeksRef]);

  return weeks;
};
