import { IoLogoReact } from 'react-icons/io5';
import { Icon, keyframes } from '@chakra-ui/react';

const constantRotationAnimation = keyframes`
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

export const Loader = ({ ...props }) => {
  return (
    <Icon
      as={IoLogoReact}
      animation={`${constantRotationAnimation} 1s linear infinite`}
      w={10}
      h={10}
      {...props}
    />
  );
};
