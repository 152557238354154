import { Box, chakra } from '@chakra-ui/react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import { INFO_SLIDES } from '../constants';
import { InfoCarouselSlide } from './info-carousel-slide';

const SwiperWrapper = chakra(Box, {
  baseStyle: {
    w: '100%',
    textAlign: 'center',

    '& .swiper-pagination': {
      mt: 6,
      position: 'relative',
    },

    '& .swiper-pagination-bullet': {
      bg: 'stone.400',
      opacity: 1,
      margin: '0 8px !important',
    },

    '& .swiper-pagination-bullet-active': {
      bg: 'sand.0',
      border: '1px solid',
      borderColor: 'card',
      boxShadow: '0 0 0 1px #A78256',
    },
  },
});

export const InfoCarousel = () => {
  return (
    <SwiperWrapper>
      <Swiper
        modules={[Pagination]}
        spaceBetween={32}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {INFO_SLIDES.map((slide, idx) => (
          <SwiperSlide key={`info-slider-${idx}`}>
            <InfoCarouselSlide {...slide} />
          </SwiperSlide>
        ))}
      </Swiper>
    </SwiperWrapper>
  );
};
