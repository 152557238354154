import { Button, Icon } from '@chakra-ui/react';
import { SendButtonProps } from 'stream-chat-react';

import { IoArrowForwardCircle } from 'react-icons/io5';
import { DefaultGenerics } from 'stream-chat';

export const SendButton: React.FC<SendButtonProps<DefaultGenerics>> = ({
  sendMessage,
}) => {
  return (
    <Button
      _disabled={{ cursor: 'default' }}
      w="40px"
      h="40px"
      bg="transparent"
      color="clay.0"
      fontSize="24px"
      p="0"
      _hover={{ bg: 'transparent' }}
      onClick={sendMessage}
    >
      <Icon as={IoArrowForwardCircle} />
    </Button>
  );
};
