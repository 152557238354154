import { Button, ButtonProps } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';

export const DatePickerHeaderButton: FC<PropsWithChildren<ButtonProps>> = ({
  children,
  ...props
}) => (
  <Button
    variant="link"
    color="sand.0"
    fontSize="2xl"
    height="40px"
    opacity="0.7"
    transition="opacity 200ms linear 0ms"
    _hover={{ opacity: 1 }}
    {...props}
  >
    {children}
  </Button>
);
