import { Button, Flex, VStack, GridItem } from '@chakra-ui/react';

import { toDPFormat } from '../../common';
import { useStore } from '../../common/model';
import {
  ProfileAvatar,
  ContentBlock,
  ProfileHeading,
  ProfilePageContainer,
  ProfilePageHeader,
  SettingsButton,
} from '../components';
import { DATE_OF_BIRTH_PLACEHOLDER, DEFAULT_HEALTH } from '../constants';
import { useProfilePage } from '../presenter';

export const ProfilePage = () => {
  const { name, onSignOutClick, onEditClick } = useProfilePage();
  const {
    health: {
      weight = 0,
      height = '',
      dateOfBirth = undefined,
    } = DEFAULT_HEALTH,
  } = useStore((state) => state.userInfo)!;

  const currentDateOfBirth = dateOfBirth
    ? toDPFormat(dateOfBirth)
    : DATE_OF_BIRTH_PLACEHOLDER;

  return (
    <>
      <GridItem w="100%">
        <ProfilePageHeader RightButton={<SettingsButton />} />
      </GridItem>
      <GridItem w="100%" h="100%">
        <ProfilePageContainer>
          <Flex flexDir="column" alignItems="center" w="100%">
            <ProfileAvatar />
            <ProfileHeading name={name as string} />
            <ContentBlock text="Height" value={`${height || 'in'}`} />
            <ContentBlock text="Weight" value={`${weight || ''}lbs`} />
            <ContentBlock text="Date of Birth" value={currentDateOfBirth} />
          </Flex>
          <VStack spacing="6" w="100%">
            <Button variant="hoa-primary" onClick={onEditClick} w="100%">
              Edit profile
            </Button>
            <Button
              variant="link"
              onClick={onSignOutClick}
              w="100%"
              color="clay.100"
            >
              Sign out
            </Button>
          </VStack>
        </ProfilePageContainer>
      </GridItem>
    </>
  );
};
