import { Heading, Image, Text, VStack } from '@chakra-ui/react';

import { useCoaches } from '../../common/model';
import { useParams } from 'react-router-dom';
import { Error, Loader } from '../../common';

export const Coach = () => {
  const params = useParams();
  const { data, loading, error } = useCoaches();

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Error />;
  }

  const coach = data && data.find((coach) => coach.id === params!.id);

  if (!coach) {
    return <Error />;
  }

  const src = coach.imageUrl;
  const name = `${coach.firstName} ${coach.lastName}`;

  return (
    <VStack justifyContent="flex-end" h="100%" position="relative">
      <Image
        width="100%"
        height="100%"
        position="absolute"
        top="0"
        left="0"
        fit="cover"
        objectPosition="top center"
        src={src}
      />
      <VStack
        position="relative"
        p={{ base: 2, md: 6 }}
        bg="linear-gradient(180deg, transparent, rgba(0,0,0,100))"
        w="100%"
      >
        <Heading
          as="h1"
          variant="hoa-h1"
          color="white"
          textAlign="center"
          fontWeight="bold"
        >
          {name}
        </Heading>
        <Text>{coach.nickname}</Text>
        <Text>"{coach.description?.short}"</Text>
        <Text>{coach.description?.long}</Text>
      </VStack>
    </VStack>
  );
};
