import { FC } from 'react';
import { REGISTER_FLOW } from '../../auth';
import { useStore } from '../../common/model';
import { ROUTES } from '../../router';
import { DescreteSlider, StepWrapper } from '../components';
import { useOnboardingStep } from '../presenter';

interface MentalFitnessProps {
  privateRouter: boolean;
}

export const MentalFitness: FC<MentalFitnessProps> = ({ privateRouter }) => {
  const { descreteSliderProps, goToNextStep, name, setOnboardingData } =
    useOnboardingStep(ROUTES.CREATE_ACCOUNT, 5);
  const setAuthFlow = useStore((state) => state.setAuthFlow);

  const onNextClick = () => {
    setOnboardingData({
      mentalFitness: descreteSliderProps.sliderValue,
    });

    if (privateRouter) {
      // It will trigger setUserToFireStore inside '/router/model/use-user-info'
      setAuthFlow(REGISTER_FLOW);
    } else {
      goToNextStep();
    }
  };

  return (
    <StepWrapper
      title="Mental Fitness"
      text={`${name}, how many days a week do you meditate or create space for mental fitness?`}
      buttonText="Next"
      onClick={onNextClick}
      step={5}
    >
      <DescreteSlider
        {...descreteSliderProps}
        min={1}
        max={10}
        startTitle="Almost Never"
        endTitle="Everyday"
      />
    </StepWrapper>
  );
};
