import { Flex, Text } from '@chakra-ui/react';

export const ContentBlock = ({ text, value }: any) => (
  <Flex
    w="100%"
    justifyContent="space-between"
    mb="8"
    pb="4"
    borderBottom="1px solid"
    borderColor="stone.0"
    color="sand.0"
    fontWeight="semibold"
  >
    <Text fontSize="md" color="white">
      {text}
    </Text>
    <Text fontSize="md">{value}</Text>
  </Flex>
);
