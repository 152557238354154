import { HStack, Icon, Text, useDisclosure } from '@chakra-ui/react';
import { IoCheckmarkSharp } from 'react-icons/io5';
import { useEffect } from 'react';

export const Completed = ({ value = false, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: value,
  });

  useEffect(() => {
    if (value) {
      onOpen();
    }
  }, [onOpen, value]);

  useEffect(() => {
    if (value) {
      setTimeout(() => {
        onClose();
      }, 5000);
    }
  }, [value, onClose]);

  return (
    <HStack
      position="absolute"
      top={5}
      right={5}
      pointerEvents="none"
      opacity={isOpen ? 100 : 0}
      transition="opacity 1s ease-in-out"
      borderRadius="lg"
      color="beige.0"
      {...props}
    >
      <Icon as={IoCheckmarkSharp} />
      <Text>Completed</Text>
    </HStack>
  );
};
