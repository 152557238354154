import { Navigate, RouteObject } from 'react-router-dom';
import {
  Name,
  Train,
  Fuel,
  Recover,
  Tribe,
  MentalFitness,
  OnboardingContainer,
} from '../../onboarding';
import {} from '../../onboarding/view/tribe';
import * as ROUTES from '../routes';

type OnboardingRoutes = (privateRouter: boolean) => RouteObject;

export const onboardingRoutes: OnboardingRoutes = (privateRouter: boolean) => ({
  path: ROUTES.ONBOARDING,
  element: <OnboardingContainer />,
  children: [
    {
      index: true,
      element: <Navigate to={ROUTES.ONBOARDING_NAME} replace />,
    },
    {
      path: ROUTES.ONBOARDING_NAME,
      element: <Name />,
    },
    {
      path: ROUTES.ONBOARDING_TRAIN,
      element: <Train />,
    },
    {
      path: ROUTES.ONBOARDING_FUEL,
      element: <Fuel />,
    },
    {
      path: ROUTES.ONBOARDING_RECOVER,
      element: <Recover />,
    },
    {
      path: ROUTES.ONBOARDING_TRIBE,
      element: <Tribe />,
    },
    {
      path: ROUTES.ONBOARDING_MENTAL_FITNESS,
      element: <MentalFitness privateRouter={privateRouter} />,
    },
  ],
});
