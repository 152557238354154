import { extendTheme } from '@chakra-ui/react';
import {
  BUTTON_CONFIG,
  BRAND_COLORS,
  INPUT_CONFIG,
  H1_CONFIG,
  CONTAINER_CONFIG,
  CALENDAR_DAY_CELL_CONFIG,
  CHAKRA_SWIPER_CONFIG,
  CHAKRA_SWIPER_SLIDE_CONFIG,
  SWITCH_CONFIG,
  MODAL_CONFIG,
  SELECT_CONFIG,
} from '../utils';

export const theme = extendTheme({
  styles: {
    global: {
      'html, body, #root': {
        minHeight: '100%',
        height: '100%',
      },
      body: {
        bg: 'card',
      },
      'body, #root': {
        width: '100%',
        overflowX: 'hidden',
        position: 'relative',
        color: 'white',
      },
      '#root': {
        bg: 'linear-gradient(transparent 0%, transparent 50%, rgba(0,0,0,.85) 100%)',
      },
      '.firebase-emulator-warning': {
        display: 'none !important',
      },
    },
  },
  fonts: {
    body: "'Libre Franklin', sans-serif",
    heading: "'Libre Franklin', sans-serif",
  },
  colors: {
    ...BRAND_COLORS,
  },
  components: {
    Button: BUTTON_CONFIG,
    Input: INPUT_CONFIG,
    Heading: H1_CONFIG,
    Container: CONTAINER_CONFIG,
    Modal: MODAL_CONFIG,
    Select: SELECT_CONFIG,
    Switch: SWITCH_CONFIG,

    // Custom components (not included in Chakra)
    CalendarDayCell: CALENDAR_DAY_CELL_CONFIG,
    ChakraSwiper: CHAKRA_SWIPER_CONFIG,
    ChakraSwiperSlide: CHAKRA_SWIPER_SLIDE_CONFIG,
  },
});
