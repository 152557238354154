export const DAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];
export const DAYS_FIRST_CAPITAL = DAYS.map(
  (d) => `${d.charAt(0).toUpperCase()}${d.slice(1)}`
);
export const DAYS_SHORT = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
