import { FC, ReactNode } from 'react';
import { ChakraDomLink } from '../link';

interface ClassesCoachesSwitcherLinkProps {
  to: string;
  children: ReactNode;
  active: boolean;
}

export const ClassesCoachesSwitcherLink: FC<
  ClassesCoachesSwitcherLinkProps
> = ({ to, children, active }) => (
  <ChakraDomLink
    to={to}
    w="50%"
    h="100%"
    borderRadius="18px"
    display="flex"
    justifyContent="center"
    alignItems="center"
    fontSize="xs"
    bg={active ? 'onyx.0' : 'transparent'}
  >
    {children}
  </ChakraDomLink>
);
