import { User } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';

import { firestore } from '../../../firebase';
import { UserInfo } from '../store';

export const useSetUserInfoToFirestore = () => {
  const createDocRef = (id: User['uid']) => doc(firestore, 'users', id);

  const updateUserInfoInFirestore = async (
    id: User['uid'],
    data: Partial<UserInfo>
  ) => {
    try {
      await updateDoc(createDocRef(id), data);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    updateUserInfoInFirestore,
  };
};
