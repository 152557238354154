import {
  Box,
  Divider,
  HStack,
  Icon,
  Spacer,
  Text,
  useBreakpoint,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { CollapsableText } from '../../common';
import { StartButton } from './start-button';
import { CoachToLoad } from './coach-to-load';
import dayjs from 'dayjs';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';
import { ReactNode, useCallback, useMemo } from 'react';
import { isDayInFuture } from '../../common/utils/isDayInFuture';
import { ClassSession } from '../../common/types/class';
import { useClassTypeData } from '../hooks/useClassTypeData';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(advancedFormat);

export const TextBox = ({
  withDivider = true,
  children,
}: {
  withDivider?: boolean;
  children: ReactNode;
}) =>
  !children ? null : (
    <>
      {withDivider ? (
        <Divider orientation="vertical" h="12px" borderColor="#E3DFDC" />
      ) : null}
      {/* TODO color="clay.400" color now not white but in design it is whitish (#E3DFDC) */}
      <Text color="#E3DFDC" fontSize="sm">
        {children}
      </Text>
    </>
  );

export const TrailerFooter = ({ slideItem }: { slideItem: ClassSession }) => {
  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen: false,
  });

  const { data: classType } = useClassTypeData(slideItem.type);

  const onTextClick = useCallback(() => {
    onToggle();
  }, [onToggle]);

  const isInFuture = isDayInFuture(slideItem);

  const formattedDate = useMemo(() => {
    return slideItem?.scheduledAt
      ? dayjs(slideItem?.scheduledAt).format('dddd, MMMM Do')
      : '';
  }, [slideItem?.scheduledAt]);

  const breakpoint = useBreakpoint();

  return (
    <VStack
      as="footer"
      position="absolute"
      p={{ base: 4, md: 8 }}
      bottom="0"
      left="0"
      bg="linear-gradient(180deg, transparent, rgba(0,0,0,100))"
      w="100%"
      alignItems="flex-start"
      justifyContent={{ md: 'space-between' }}
      flexDirection={{ md: 'row' }}
    >
      <VStack
        alignItems="flex-start"
        w={{ base: '100%', md: '540px', lg: '665px' }}
        m={{ xl: isOpen ? '0 0 0 50px' : '50px 0 -30px 50px' }}
      >
        <Box>
          <CoachToLoad coach={slideItem.coach} />
        </Box>

        <VStack
          cursor="pointer"
          w="100%"
          onClick={onTextClick}
          alignItems="flex-start"
        >
          <HStack alignItems="center" justifyContent="space-between" w="100%">
            <HStack>
              <TextBox withDivider={false}>{slideItem?.duration}</TextBox>
              <TextBox>{classType?.name}</TextBox>
              <TextBox>
                {slideItem?.scheduledAt
                  ? isInFuture
                    ? `Class Available ${formattedDate}`
                    : formattedDate
                  : null}
              </TextBox>
              <TextBox>{slideItem?.completed ? 'Completed' : null}</TextBox>
            </HStack>
            <Spacer />
            <Icon as={isOpen ? IoChevronUp : IoChevronDown} color="white" />
          </HStack>

          <Divider />

          <CollapsableText
            collapsedText={slideItem.description?.short}
            expandedText={slideItem.description?.long}
            limit={breakpoint === 'base' ? 6 : 2}
            textAlign="left"
            fontSize="xs"
            color="#E3DFDC" // TODO clay.400
            pb={{ base: isOpen ? '60px' : 0, md: 0 }}
            isOpen={isOpen}
          />
        </VStack>
      </VStack>

      {isInFuture ? null : (
        <StartButton
          id={slideItem.id}
          position={{ base: 'absolute', md: 'relative' }}
          bottom={{ base: 6, md: 0 }}
          right={{ base: 'auto', md: 0 }}
          marginLeft={{ md: '4 !important' }}
          w={{ base: 'calc(100% - 32px)', md: '255px' }}
          alignSelf="flex-end"
        />
      )}
    </VStack>
  );
};
