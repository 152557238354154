import { FC, PropsWithChildren } from 'react';
import { Pagination } from 'swiper';
import { Swiper } from 'swiper/react';
import { WithChakraClassNameWrapper } from '../../hoc/with-chakra-class-name';
import { SwiperProps } from 'swiper/react/swiper-react';

// Import Swiper styles
import 'swiper/css/bundle';
import 'swiper/css';
import 'swiper/css/pagination';

export const AChakraSwiper: FC<PropsWithChildren<SwiperProps>> = (props) => {
  const {
    children,
    pagination,
    onSlideChange,
    initialSlide,
    className,
    ...rest
  } = props;

  // Prevent issue with incorrect initial slide applied (somehow it applies 1 slide as active
  // instead of `initialSlide` passed. It happens because of React.cloneChild in `WithChakraClassName`,
  // but the real reason is that React is a piece of s..., but we have no choice ¯\_(ツ)_/¯)
  // also to prevent rendering without proper styles
  if (!className) {
    return null;
  }

  return (
    <Swiper
      cssMode
      direction={'vertical'}
      pagination={pagination}
      modules={[Pagination]}
      preventClicksPropagation={false}
      preventClicks={false}
      onSlideChange={onSlideChange}
      initialSlide={initialSlide}
      className={className}
      {...rest}
    >
      {children}
    </Swiper>
  );
};

type ChakraSwiperProps = SwiperProps & {
  variant?: string;
};

export const ChakraSwiper: FC<PropsWithChildren<ChakraSwiperProps>> = ({
  variant,
  ...props
}) => {
  return (
    <WithChakraClassNameWrapper
      variant={variant}
      styleConfigName="ChakraSwiper"
    >
      <AChakraSwiper {...props} />
    </WithChakraClassNameWrapper>
  );
};
