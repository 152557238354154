import { Icon, IconProps } from '@chakra-ui/react';
import { FC } from 'react';

export const DurationIcon: FC<IconProps> = (props) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 25"
    {...props}
  >
    <g clipPath="url(#clip0_526_13555)">
      <path
        d="M3 14.252C3 15.4338 3.23279 16.6042 3.68508 17.6961C4.13738 18.788 4.80031 19.7802 5.63604 20.6159C6.47177 21.4516 7.46392 22.1146 8.55585 22.5669C9.64778 23.0192 10.8181 23.252 12 23.252C13.1819 23.252 14.3522 23.0192 15.4442 22.5669C16.5361 22.1146 17.5282 21.4516 18.364 20.6159C19.1997 19.7802 19.8626 18.788 20.3149 17.6961C20.7672 16.6042 21 15.4338 21 14.252C21 13.0701 20.7672 11.8997 20.3149 10.8078C19.8626 9.71587 19.1997 8.72372 18.364 7.88799C17.5282 7.05226 16.5361 6.38933 15.4442 5.93704C14.3522 5.48474 13.1819 5.25195 12 5.25195C10.8181 5.25195 9.64778 5.48474 8.55585 5.93704C7.46392 6.38933 6.47177 7.05226 5.63604 7.88799C4.80031 8.72372 4.13738 9.71587 3.68508 10.8078C3.23279 11.8997 3 13.0701 3 14.252V14.252Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 7.50195L19.875 5.62695"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 5.25195L20.25 6.00195"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 5.25195V0.751953"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 0.751953H9.75"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15.0021L8.25 10.8511"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_526_13555">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.00195312)"
        />
      </clipPath>
    </defs>
  </Icon>
);
