import { useState, useEffect } from 'react';
import { getTime } from '../utils/getTime';

export const useCalculateActiveTime = (
  videoRefCurrent: HTMLVideoElement | null
) => {
  const [elapsedTime, setElapsedTime] = useState(0);
  const [prevTime, setPrevTime] = useState(0);

  useEffect(() => {
    const handler = () => {
      const timeOnVideo: string = getTime(videoRefCurrent!.currentTime);
      const elapsedSeconds = parseInt(timeOnVideo.match(/:(\d+)/)![1]);
      if (prevTime !== elapsedSeconds) {
        setElapsedTime(elapsedTime + 1);
        setPrevTime(elapsedSeconds);
      }
    };

    if (!videoRefCurrent) {
      return;
    }

    videoRefCurrent.addEventListener('timeupdate', handler);

    return () => {
      videoRefCurrent.removeEventListener('timeupdate', handler);
    };
  }, [elapsedTime, prevTime, videoRefCurrent]);

  return elapsedTime;
};
