import { VStack, chakra, Heading, Input, Text, Button } from '@chakra-ui/react';
import { vestResolver } from '@hookform/resolvers/vest';
import { useForm } from 'react-hook-form';
import { create } from 'vest';

import { emailValidation } from '../../common';
import { useResetPassword } from '../presenter';
interface EmailFormData {
  email: string;
}

const suite = create(({ email }: EmailFormData) => {
  emailValidation(email);
});

export const ForgotPassword = () => {
  const resetPassword = useResetPassword();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EmailFormData>({
    resolver: vestResolver(suite),
    reValidateMode: 'onSubmit',
  });

  const onSubmit = async ({ email }: EmailFormData) => resetPassword(email);

  return (
    <VStack spacing="12">
      <chakra.form onSubmit={handleSubmit(onSubmit)} w="100%">
        <VStack spacing="6" w="100%" alignItems="center">
          <Heading fontSize="xl" fontWeight="semibold">
            Password Reset
          </Heading>
          <Text color="stone.400" textAlign="center">
            Enter your email and we’ll send you a link to reset your password
          </Text>
          <VStack w="100%" spacing="3" alignItems="flex-start">
            <Input
              isInvalid={!!errors.email}
              variant="hoa-filled"
              placeholder="Enter your email"
              size="md"
              {...register('email')}
            />
            {!!errors.email && (
              <Text fontSize="xs" color="red.600">
                {errors.email.message}
              </Text>
            )}
          </VStack>
          <Button variant="hoa-primary" w="100%" type="submit">
            Reset Password
          </Button>
        </VStack>
      </chakra.form>
    </VStack>
  );
};
