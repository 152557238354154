import { Icon, IconProps } from '@chakra-ui/react';

export const TrainIcon: React.FC<IconProps> = (props) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 25"
    {...props}
  >
    <path
      d="M9.96.74a.84.84 0 0 0-.47.21S7.98 2.33 6.9 4.37c-1.1 2.04-1.8 4.94.05 7.62.82 1.2.77 2.04.5 2.6a1.5 1.5 0 0 1-1.4.83c-.53-.03-1.01-.17-1.45-.69-.45-.52-.88-1.51-.98-3.36a.84.84 0 0 0-1.2-.72s-.72.36-1.3 1.2a6.36 6.36 0 0 0-.91 4.11A8.9 8.9 0 0 0 17 19.54c.43-.83.7-2 .69-3.3a6.96 6.96 0 0 0-1.2-3.96c-.83-1.18-1.8-1.87-2.66-2.53a8.28 8.28 0 0 1-2.33-2.49c-1.23-2.07-.61-5.51-.61-5.51A.84.84 0 0 0 9.96.74Zm-.92 3.45a8.12 8.12 0 0 0 1 3.93 10.14 10.14 0 0 0 2.76 2.96c.9.7 1.66 1.24 2.32 2.17.62.88.87 1.97.88 3.01.01 1.04-.24 2.02-.5 2.5a7.15 7.15 0 0 1-5.89 3.92 7.14 7.14 0 0 1-7.73-6.83c-.09-1.21.12-1.93.37-2.48a5.87 5.87 0 0 0 1.06 2.46 3.7 3.7 0 0 0 2.63 1.27 3.18 3.18 0 0 0 3-1.77c.58-1.16.46-2.74-.61-4.3-1.42-2.06-.9-4.12.04-5.87.21-.4.44-.64.67-.97Z"
      fill="currentColor"
    />
  </Icon>
);
