import { Icon, IconProps } from '@chakra-ui/react';

export const FacebookIcon: React.FC<IconProps> = (props) => (
  <Icon fill="none" viewBox="0 0 26 26" display="block" {...props}>
    <path
      d="M15.01 24.54V14.1h3.4l.5-4.09h-3.9V7.4c0-1.19.32-2 1.95-2h2.07V1.76c-1-.12-2.02-.17-3.03-.17-3 0-5.07 1.9-5.07 5.4v3H7.56v4.1h3.38v10.45h4.07Z"
      fill="currentColor"
    />
  </Icon>
);
