import create from 'zustand';
import { authSlice, AuthSlice } from './auth-slice';
import { ClassSlice, classSlice } from './class-slice';
import { FiltersSlice, filtersSlice } from './filters-slice';
import { chatSlice, ChatSlice } from './chat-slice';
import { onboardingSlice, OnboardingSlice } from './onboarding-slice';
import { ProfileSlice, profileSlice } from './profile-slice';
import { trainingSlice, TrainingSlice } from './training-slice';
import { userSlice, UserSlice } from './user-slice';

type Store = AuthSlice &
  ChatSlice &
  ClassSlice &
  FiltersSlice &
  OnboardingSlice &
  ProfileSlice &
  TrainingSlice &
  UserSlice;

export const useStore = create<Store>((...args) => ({
  ...authSlice(...args),
  ...chatSlice(...args),
  ...classSlice(...args),
  ...filtersSlice(...args),
  ...onboardingSlice(...args),
  ...profileSlice(...args),
  ...trainingSlice(...args),
  ...userSlice(...args),
}));
