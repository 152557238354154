import { FC } from 'react';
import { Box, HStack, Icon } from '@chakra-ui/react';
import { IoStar, IoStarOutline } from 'react-icons/io5';
import { hoverOpacityTransition } from '../../common';
import { SystemProps } from '@chakra-ui/styled-system';

type StarBoxProps = {
  value?: number;
  onRatingChange: (val: number | string) => void;
} & SystemProps;

export const StarBox: FC<StarBoxProps> = ({
  value = 4,
  onRatingChange,
  ...props
}) => (
  <HStack
    alignItems="center"
    justifyContent="space-around"
    w="100%"
    spacing={2}
    {...props}
  >
    {new Array(5).fill(0).map((_, index) => {
      const onClickHandler = () => onRatingChange(index + 1);
      if (index < value) {
        return (
          <Box key={index}>
            <Icon {...iconProps} onClick={onClickHandler} />
          </Box>
        );
      } else {
        return (
          <Box key={index}>
            <Icon
              {...iconProps}
              as={IoStarOutline}
              key={index}
              onClick={onClickHandler}
            />
          </Box>
        );
      }
    })}
  </HStack>
);
const iconProps = {
  as: IoStar,
  boxSize: '30px',
  focusable: true,
  color: 'sand.0',
  margin: 0,
  cursor: 'pointer',
  ...hoverOpacityTransition,
};
