import { FC, PropsWithChildren } from 'react';
import { Box, Slider, SliderThumb, SliderTrack, Text } from '@chakra-ui/react';
import { StarBox } from './star-box';
import { SliderMarkThemed } from './slider-mark-themed';
import { ThumbsBox } from './thumbs-box';

export const RatingBox: FC<PropsWithChildren<RatingBoxTypes>> = ({
  children,
  type = 'star',
  value = 4,
  onRatingChange,
  ...props
}) => (
  <Box w="100%" {...props}>
    <Text fontSize="md" color="stone.300" fontWeight={600} align="center">
      {children}
    </Text>
    {type === 'thumbs' ? (
      <ThumbsBox mt={4} onRatingChange={onRatingChange} value={value} />
    ) : type === 'star' ? (
      <StarBox mt={4} onRatingChange={onRatingChange} value={value} />
    ) : (
      <Slider
        mt={4}
        defaultValue={value}
        min={0}
        max={5}
        step={0.5}
        onChange={onRatingChange}
        position="relative"
      >
        <SliderTrack bg="stone.0" height="2px" />
        <SliderMarkThemed val={0} />
        <SliderMarkThemed val={0.5} />
        <SliderMarkThemed val={1} />
        <SliderMarkThemed val={1.5} />
        <SliderMarkThemed val={2} />
        <SliderMarkThemed val={2.5} />
        <SliderMarkThemed val={3} />
        <SliderMarkThemed val={3.5} />
        <SliderMarkThemed val={4} />
        <SliderMarkThemed val={4.5} />
        <SliderMarkThemed val={5} />
        <SliderThumb
          borderRadius="full"
          border="1px solid"
          borderColor="white"
          boxSize={6}
          width="40px !important"
          height="40px !important"
        >
          <Box
            h="100%"
            w="100%"
            borderRadius="full"
            border="2px solid black"
            bg="clay.0"
            display="flex"
            justifyContent="center"
            alignItems="center"
            fontSize="sm"
          >
            {value}
          </Box>
        </SliderThumb>
      </Slider>
    )}
  </Box>
);

type RatingBoxTypes = {
  type?: 'star' | 'slide' | 'thumbs';
  value?: number;
  onRatingChange: (val: number | string) => void;
};
