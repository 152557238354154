import {
  Text,
  Box,
  Slider,
  SliderMark,
  SliderTrack,
  SliderThumb,
  Flex,
} from '@chakra-ui/react';

interface DescreteSliderProps {
  onChange(val: number): void;
  sliderValue: number;
  min: number;
  max: number;
  step?: number;
  startTitle: string;
  endTitle: string;
}

export const DescreteSlider: React.FC<DescreteSliderProps> = ({
  onChange,
  sliderValue,
  min,
  max,
  step = 1,
  startTitle,
  endTitle,
}) => {
  return (
    <Box w="100%">
      <Flex w="100%" justifyContent="space-between" mb="4">
        <Text fontSize="xs" color="stone.400">
          {startTitle}
        </Text>
        <Text fontSize="xs" color="stone.400">
          {endTitle}
        </Text>
      </Flex>
      <Slider
        aria-label="Fitness goal"
        onChange={onChange}
        min={min}
        max={max}
        step={step}
        defaultValue={sliderValue}
      >
        {Array(max)
          .fill(1)
          .map((el: number, index: number) => (
            <SliderMark
              value={el + index}
              key={`slider-mark-${index}`}
              color={el + index === sliderValue ? 'sand.0' : 'onyx.400'}
              fontWeight={el + index === sliderValue ? 'medium' : 'normal'}
              height="8px"
              width="1px"
              paddingLeft="5px"
              bg="card"
              marginTop="-4px"
              position="relative"
              zIndex={1}
            >
              <Box
                position="absolute"
                w="1px"
                height="100%"
                bg="stone.0"
                top="0"
                left="2px"
              />
            </SliderMark>
          ))}
        <SliderTrack height="1px" bg="stone.0" />
        <SliderThumb
          boxSize="32px"
          border="1px solid"
          bg="clay.0"
          borderColor="card"
          boxShadow="0 0 0 1px #fff"
          zIndex={2}
          _focus={{ boxShadow: '0 0 0 2px #fff' }}
          fontSize="sm"
        >
          {sliderValue}
        </SliderThumb>
      </Slider>
    </Box>
  );
};
