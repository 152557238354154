import { useParams } from 'react-router-dom';
import { useClassesData } from './use-classes';
import { useMemo } from 'react';

export const useTraining = (trainingId?: string) => {
  const params = useParams();
  const { data: classes, loading, error } = useClassesData();
  const training = useMemo(
    () =>
      classes?.find(
        (clas) => clas.id === (trainingId ? trainingId : params.id)
      ),
    [classes, trainingId, params.id]
  );

  return { data: training, loading, error };
};
