const onyx = {
  0: '#000',
  '50': '#f7f7f7',
  '100': '#e0e0e0',
  '200': '#c5c5c5',
  '300': '#a6a6a6',
  '400': '#949494',
  '500': '#7d7d7d',
  '600': '#696969',
  '700': '#545454',
  '800': '#474747',
  '900': '#333333',
};

const sand = {
  0: '#A78256',
  '50': '#f9f7f4',
  '100': '#e8dfd3',
  '200': '#d5c3ae',
  '300': '#bda180',
  '400': '#b08e67',
  '500': '#99774f',
  '600': '#816442',
  '700': '#675035',
  '800': '#57442d',
  '900': '#3f3120',
};

const clay = {
  0: '#8C7E70',
  '50': '#f8f7f6',
  '100': '#e3e0dc',
  '200': '#cbc5be',
  '300': '#aea49a',
  '400': '#9e9286',
  '500': '#887b6d',
  '600': '#73675c',
  '700': '#5c534a',
  '800': '#4e463e',
  '900': '#38322d',
};

const stone = {
  0: '#3D3935',
  '50': '#f7f7f7',
  '100': '#e1e0df',
  '200': '#c6c5c4',
  '300': '#a7a5a4',
  '400': '#969492',
  '500': '#7f7c7a',
  '600': '#6c6966',
  '700': '#575450',
  '800': '#4a4743',
  '900': '#36332f',
};

const gray = {
  0: '#999',
  '50': '#8a8a8a',
  '100': '#7a7a7a',
  '200': '#6b6b6b',
  '300': '#5c5c5c',
  '400': '#4d4d4d',
  '500': '#3d3d3d',
  '600': '#2e2e2e',
  '700': '#1f1f1f',
  '800': '#0f0f0f',
  '900': '#000000',
};

const beige = {
  0: '#C2AF9B',
  '50': '#af9e8c',
  '100': '#9b8c7c',
  '200': '#887a6d',
  '300': '#74695d',
  '400': '#61584e',
  '500': '#4e463e',
  '600': '#3a342e',
  '700': '#27231f',
  '800': '#13110f',
  '900': '#000000',
};

export const BRAND_COLORS = {
  onyx,
  sand,
  clay,
  stone,
  gray,
  beige,
  card: '#1C1C1E',
};
