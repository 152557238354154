import { Icon, IconProps } from '@chakra-ui/react';

export const InboxIcon: React.FC<IconProps> = (props) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 21"
    {...props}
  >
    <path
      d="M5.75 20.92c-2.22 0-2.44-1.49-2.44-2.96v-1.7A4.23 4.23 0 0 1 0 12.2V4.43C0 2.12 1.96.24 4.37.24h12.15a4.28 4.28 0 0 1 4.37 4.19v7.78c0 2.3-1.96 4.18-4.37 4.18h-5.08c-.22 0-.42.11-.54.28-.92 1.06-1.86 1.97-3.13 3.39-.5.37-1.41.86-2.02.86ZM4.37 2.28a2.2 2.2 0 0 0-2.25 2.15v7.78c0 1.18 1.01 2.15 2.25 2.15.59 0 1.06.45 1.06 1.01v2.6c0 .38-.15 1.13.65.62.13 0 .26-.05.37-.13 1.04-.78 2.63-2.85 2.63-2.85a2.8 2.8 0 0 1 2.36-1.26h5.07a2.2 2.2 0 0 0 2.25-2.15V4.43c0-1.19-1-2.15-2.25-2.15H4.37Z"
      fill="currentColor"
    />
  </Icon>
);
