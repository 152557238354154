import { Button } from '@chakra-ui/react';

import { MainNavigationItem } from './main-navigation-item';

const ChakraButton = MainNavigationItem(Button);

export const MainNavigationButton: React.FC<any> = ({ onClick, children }) => {
  return (
    <ChakraButton variant="unstyled" onClick={onClick}>
      {children}
    </ChakraButton>
  );
};
