import { Box, GridItem } from '@chakra-ui/react';
import { PRIVACY_POLICY, TERMS_OF_USE } from '../../common';
import {
  BackButton,
  ProfilePageContainer,
  ProfilePageHeader,
  SettingsBlock,
  SettingsLink,
} from '../components';
import { useProfileSettings } from '../presenter';

/* @TODO put this inside ProfilePageContainer + add logic
<SettingsBlock mt="12">
<Heading fontSize="2xl" fontWeight="normal" mb="12">Notification</Heading>
<NotificationBlock title="General" isChecked={general} onChange={setGeneral.toggle} />
<NotificationBlock title="Daily class alert" isChecked={dailyClasses} onChange={setDailyClasses.toggle} />
<NotificationBlock title="Chat notifications" isChecked={chatNotifications} onChange={setChatNotifications.toggle} mb="0" />
</SettingsBlock>
*/

export const ProfileSettings = () => {
  const { id, portalLink } = useProfileSettings();

  return (
    <>
      <GridItem>
        <ProfilePageHeader title="Settings" LeftButton={<BackButton />} />
      </GridItem>
      <GridItem>
        <ProfilePageContainer>
          <Box w="100%">
            {portalLink && (
              <SettingsBlock>
                <SettingsLink href={portalLink} isExternal mb={0}>
                  Manage payments
                </SettingsLink>
              </SettingsBlock>
            )}
            <SettingsBlock>
              <SettingsLink href="https://support.hoa.plus/en/" isExternal>
                Support
              </SettingsLink>
              <SettingsLink href={TERMS_OF_USE} isExternal>
                Terms &amp; Conditions
              </SettingsLink>
              <SettingsLink href={PRIVACY_POLICY} isExternal mb="0">
                Privacy Policy
              </SettingsLink>
            </SettingsBlock>
          </Box>
          <Box fontSize="8px" opacity="0.5">
            {id}
          </Box>
        </ProfilePageContainer>
      </GridItem>
    </>
  );
};
