import { StateCreator } from 'zustand';
import { produce } from 'immer';

export type AuthFlow = undefined | 'login' | 'register';

interface AuthSliceData {
  flow: AuthFlow;
}

export interface AuthSlice {
  auth: AuthSliceData;
  setAuthFlow(flow: AuthFlow): void;
}

export const authSlice: StateCreator<AuthSlice, [], [], AuthSlice> = (set) => ({
  auth: { flow: undefined },
  setAuthFlow(flow: AuthFlow) {
    set(
      produce((state) => {
        state.auth.flow = flow;
      })
    );
  },
});
