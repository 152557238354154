import { collection, getDocs, onSnapshot } from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { firestore } from '../../firebase';
import { CollectionReference } from '@firebase/firestore-types';
import {
  SimpleResponsiveUrls,
  VideoResponsive,
} from '../../common/types/responsive-urls';

export type DiscoverItem = {
  button: {
    action: string; // "https://houseofathlete.com/S22-seamless-capsule"
    text: string; // "View Collection"
  };
  description: string; // "An athletic body comes in all shapes and sizes - yes, curves included. Our unique body-mapping and super compression tech help accentuate your figure and keep your body firm and supported in all the right places, no matter the activity."
  expiredAt: string;
  id: string;
  name: string;
  publishedAt: string;
  thumbnail: SimpleResponsiveUrls;
  video: VideoResponsive;
};

export type DiscoverLoadingData = {
  data: null | DiscoverItem[];
  loading: boolean;
  error: null | string;
};

export const Discover_INITIAL_STATE = {
  data: null,
  loading: false,
  error: null,
};

async function asyncQuery(
  ref: CollectionReference,
  setter: (d: DiscoverLoadingData) => void
) {
  // @ts-ignore // TODO
  const docSnap = await getDocs(ref);

  if (!docSnap) {
    setter({
      data: null,
      loading: false,
      error: 'No data for week!',
    });
  }
}

export const useDiscover = () => {
  const [discover, setDiscover] = useState<DiscoverLoadingData>(
    Discover_INITIAL_STATE
  );
  const discoverRef = useMemo(() => collection(firestore, 'discoverItems'), []);

  useEffect(() => {
    // @ts-ignore // TODO
    asyncQuery(discoverRef, setDiscover);
  }, [discoverRef]);

  // Initial set of data and subscription for future data updates.
  // initial happens after `asyncQuery` is completed
  useEffect(() => {
    onSnapshot(discoverRef, {
      async next(snapshot) {
        if (snapshot) {
          const docs = snapshot.docs;
          const data = docs.map((doc) => doc.data());

          setDiscover({
            data: data as DiscoverItem[],
            loading: false,
            error: null,
          });
        }
      },
      error(error) {
        console.error(error);
      },
    });
  }, [setDiscover, discoverRef]);

  return discover;
};
