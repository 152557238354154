import { Icon, IconProps } from '@chakra-ui/react';

export const ScheduleIcon: React.FC<IconProps> = (props) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 21"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3.24a1 1 0 0 1 1 1v.9h.7a4 4 0 0 1 4 4v10.1a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V6.14a4 4 0 0 1 4-4h.78v-.9a1 1 0 1 1 2 0v.9h8.52v-.9a1 1 0 0 1 1-1ZM4.78 4.14v.82a1 1 0 1 0 2 0v-.81h8.52v.8a1 1 0 1 0 2 0v-.8h.7a2 2 0 0 1 2 2v1.62H2V6.15c0-1.1.9-2 2-2h.78ZM2 9.78v6.47c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2V9.77H2Z"
      fill="currentColor"
    />
  </Icon>
);
