import { StateCreator } from 'zustand';
import { produce } from 'immer';

export interface TrainingDetail {
  coaches: string;
  description: {
    long: string;
    short: string;
  };
  duration: string;
  id: string;
  imageUrl: string;
  name: string;
  equipment: string;
}

export interface TrainingSlice {
  trainingDetails: TrainingDetail[];
  setTrainingDetails(details: TrainingDetail[]): void;
}

export const trainingSlice: StateCreator<
  TrainingSlice,
  [],
  [],
  TrainingSlice
> = (set) => ({
  trainingDetails: [],
  setTrainingDetails(details = []) {
    set(
      produce((state) => {
        state.trainingDetails = details;
      })
    );
  },
});
