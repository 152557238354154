import {
  collection,
  getDocs,
  onSnapshot,
  query,
  orderBy,
} from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { Coach } from '../../types/coach';
import { firestore } from '../../../firebase';
import { CollectionReference } from '@firebase/firestore-types';

export type CoachesLoadingData = {
  data: null | Coach[];
  loading: boolean;
  error: null | string;
};

export const Coaches_INITIAL_STATE = {
  data: null,
  loading: false,
  error: null,
};

async function asyncQuery(
  ref: CollectionReference,
  setter: (d: CoachesLoadingData) => void
) {
  // @ts-ignore // TODO
  const docSnap = await getDocs(ref);

  if (!docSnap) {
    setter({
      data: null,
      loading: false,
      error: 'No data for week!',
    });
  }
}

export const useCoaches = () => {
  const [coaches, setCoaches] = useState<CoachesLoadingData>(
    Coaches_INITIAL_STATE
  );
  const coachesRef = useMemo(
    () => query(collection(firestore, 'coaches'), orderBy('order')),
    []
  );

  useEffect(() => {
    // @ts-ignore // TODO
    asyncQuery(coachesRef, setCoaches);
  }, [coachesRef]);

  // Initial set of data and subscription for future data updates.
  // initial happens after `asyncQuery` is completed
  useEffect(() => {
    onSnapshot(coachesRef, {
      async next(snapshot) {
        if (snapshot) {
          const docs = snapshot.docs;
          const data = docs.map((doc) => doc.data());

          setCoaches({
            data: data as Coach[],
            loading: false,
            error: null,
          });
        }
      },
      error(error) {
        console.error(error);
      },
    });
  }, [setCoaches, coachesRef]);

  return coaches;
};
