import { httpsCallable } from 'firebase/functions';
import { useEffect } from 'react';
import { StreamChat } from 'stream-chat';
import shallow from 'zustand/shallow';
import { useStore } from '../../common/model';
import { functions } from '../../firebase';

const apiKey = process.env.REACT_APP_GET_STREAM_API_KEY as string;
const getStreamUserToken = httpsCallable(functions, 'getStreamUserToken');

export const useConnectChat = () => {
  const { id: userId, subscription } =
    useStore((state) => state.userInfo) || {};
  const { client, channel, setChatData, noChatAvailable } = useStore(
    (state) => ({ ...state.chat, setChatData: state.setChatData }),
    shallow
  );

  useEffect(() => {
    if (!userId || subscription?.status !== 'active' || noChatAvailable) return;
    if (client && channel) return;

    async function initChat() {
      const chatClient = StreamChat.getInstance(apiKey);

      const { data: token } = await getStreamUserToken();

      await chatClient.connectUser(
        {
          id: userId as string,
        },
        token as string
      );

      // chatClient.on('message.read', ({ total_unread_count = 0 }) => {
      //   console.log('MESSAGE READ');
      //   setChatData({ totalUnreadCount: total_unread_count });
      // });

      chatClient.on('message.new', ({ total_unread_count = 0 }) => {
        console.log('MESSAGE NEW');
        setChatData({ totalUnreadCount: total_unread_count });
      });

      const [channel] = await chatClient.queryChannels({
        type: 'messaging',
        members: { $in: [userId as string] },
      });

      if (channel) {
        try {
          await channel.watch();
          setChatData({ client: chatClient, channel: channel });
        } catch (e) {
          console.error('USER WITHOUT CHAT', e);
          setChatData({ noChatAvailable: true });
        }
      } else {
        setChatData({ noChatAvailable: true });
      }

      // disconnect User when window is closing or reloading
      // cleanup function is not good here because we need to persist connection between routes
      window.addEventListener('beforeunload', () => client?.disconnectUser());
    }

    initChat();
    // We need to eslint-disable here because we want to init chat once per reload
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, subscription, noChatAvailable]);
};
