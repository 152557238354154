import { Link, useMatch } from 'react-router-dom';
import { MainNavigationItem } from './main-navigation-item';

const ChakraLink = MainNavigationItem(Link);

export const MainNavigationLink: React.FC<any> = ({ children, to }) => {
  const match = useMatch(to);

  return (
    <ChakraLink to={to} className={match ? 'active' : ''}>
      {children}
    </ChakraLink>
  );
};
