import { doc, DocumentReference, getDoc, onSnapshot } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { useEffect, useMemo, useState } from 'react';
import { ClassType } from '../../common/types/class-type';

export type ClassTypeLoadingData = {
  data: null | ClassType;
  loading: boolean;
  error: null | string;
};

export const ClassType_INITIAL_STATE = {
  data: null,
  loading: false,
  error: null,
};

async function asyncQuery(
  ref: DocumentReference,
  setter: (d: ClassTypeLoadingData) => void
) {
  const docSnap = await getDoc(ref);

  if (!docSnap.exists()) {
    setter({
      data: null,
      loading: false,
      error: 'No data for week!',
    });
  }
}

export const useClassTypeData = (classTypeId?: string) => {
  const [classType, setClassType] = useState<ClassTypeLoadingData>(
    ClassType_INITIAL_STATE
  );
  const classTypeRef = useMemo(
    () => (classTypeId ? doc(firestore, 'classTypes', classTypeId) : null),
    [classTypeId]
  );

  useEffect(() => {
    if (!classTypeRef) return;
    asyncQuery(classTypeRef, setClassType);
  }, [classTypeRef]);

  // Initial set of data and subscription for future data updates.
  // initial happens after `asyncQuery` is completed
  useEffect(() => {
    if (!classTypeRef) return;
    onSnapshot(classTypeRef, {
      next(snapshot) {
        if (snapshot.exists()) {
          setClassType({
            data: snapshot.data() as ClassType,
            loading: false,
            error: null,
          });
        }
      },
      error(error) {
        console.error(error);
      },
    });
  }, [setClassType, classTypeRef]);

  return classType;
};
