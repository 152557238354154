import { VStack } from '@chakra-ui/react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useAuthWithEmailAndPassword } from '../presenter';

import {
  EmailPasswordForm,
  EmailPasswordFormData,
} from '../components/email-password-form';
import { SocialAuthBlock } from '../components/social-auth-block';
import { LOGIN_FLOW } from '../constants';

export const Login = () => {
  const onSignIn = useAuthWithEmailAndPassword(
    signInWithEmailAndPassword,
    LOGIN_FLOW
  );
  const onSubmit = ({ email, password }: EmailPasswordFormData) =>
    onSignIn(email, password);

  return (
    <VStack spacing="12">
      <EmailPasswordForm
        showForgotPassword
        heading="Welcome back!"
        submitButtonText="Login"
        onSubmit={onSubmit}
      />
      <SocialAuthBlock title="Or login with" flow={LOGIN_FLOW} />
    </VStack>
  );
};
