import { Center } from '@chakra-ui/react';
import { Loader } from './';

export const LoaderCenter = ({ centerProps = {}, ...props }) => {
  return (
    <Center h="100%" {...centerProps}>
      <Loader {...props} />
    </Center>
  );
};
