import { vestResolver } from '@hookform/resolvers/vest';
import { FC } from 'react';
import {
  Button,
  Heading,
  Input,
  VStack,
  chakra,
  Text,
  useBoolean,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { IoEyeOutline, IoEyeOffOutline } from 'react-icons/io5';
import { create, test, enforce } from 'vest';

import { ROUTES } from '../../router';
import { emailValidation } from '../../common';

interface EmailPasswordFormProps {
  showForgotPassword?: boolean;
  heading: string;
  submitButtonText: string;
  onSubmit(args: EmailPasswordFormData): void;
}

export interface EmailPasswordFormData {
  email: string;
  password: string;
}

// 1 digit or 1 special symbol
const DIGIT_OR_SPECIAL_SYMBOL = /[\d@$!%*#?&(){}]/;

const suite = create(({ email, password }: EmailPasswordFormData) => {
  // Email validation
  emailValidation(email);

  // Password validation
  test('password', 'Password is required', () => {
    enforce(password).isNotEmpty();
  });

  test('password', 'Password mast be at least 6 symbols', () => {
    enforce(password).longerThanOrEquals(6);
  });

  test('password', 'Password mast have 1 digit or 1 special symbol', () => {
    enforce(password).matches(DIGIT_OR_SPECIAL_SYMBOL);
  });
});

export const EmailPasswordForm: FC<EmailPasswordFormProps> = ({
  showForgotPassword,
  heading,
  submitButtonText,
  onSubmit,
}) => {
  const navigate = useNavigate();
  const [show, setShow] = useBoolean(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EmailPasswordFormData>({
    resolver: vestResolver(suite),
    reValidateMode: 'onSubmit',
  });

  const onForgotPasswordClick = () => navigate(ROUTES.FORGOT_PASSWORD);

  return (
    <chakra.form onSubmit={handleSubmit(onSubmit)} w="100%">
      <VStack spacing="5" w="100%" alignItems="center">
        <Heading fontSize="xl" fontWeight="semibold">
          {heading}
        </Heading>
        <VStack w="100%" spacing="3" alignItems="flex-start">
          <Input
            isInvalid={!!errors.email}
            variant="hoa-filled"
            placeholder="Enter your email"
            size="md"
            {...register('email')}
          />
          {!!errors.email && (
            <Text fontSize="xs" color="red.600">
              {errors.email.message}
            </Text>
          )}
        </VStack>
        <VStack w="100%" spacing="3" alignItems="flex-start">
          <InputGroup size="md">
            <Input
              isInvalid={!!errors.password}
              variant="hoa-filled"
              placeholder="Enter your password"
              type={show ? 'text' : 'password'}
              {...register('password')}
            />
            <InputRightElement w="48px">
              <Button variant="hoa-icon" onClick={setShow.toggle} size="46px">
                {show ? <IoEyeOffOutline /> : <IoEyeOutline />}
              </Button>
            </InputRightElement>
          </InputGroup>
          {!!errors.password && (
            <Text fontSize="xs" color="red.600">
              {errors.password.message}
            </Text>
          )}
        </VStack>
        <VStack w="100%" spacing="4" pt="2">
          {showForgotPassword && (
            <Button
              variant="link"
              fontSize="md"
              fontWeight="normal"
              onClick={onForgotPasswordClick}
              color="clay.0"
            >
              Forgot Password?
            </Button>
          )}
          <Button variant="hoa-primary" w="100%" type="submit">
            {submitButtonText}
          </Button>
        </VStack>
      </VStack>
    </chakra.form>
  );
};
