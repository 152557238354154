import { Flex } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';

import { Logo } from '../../common';

export const DummyPage: FC<PropsWithChildren> = ({ children }) => (
  <Flex
    w="100%"
    h="100%"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    bg="clay.0"
  >
    <Logo color="white" w={{ base: '9rem', md: '22rem' }} h="auto" />
    {children}
  </Flex>
);
