import { chakra } from '@chakra-ui/react';

interface BackgroundVideoProps {
  url: string;
}

export const BackgroundVideo: React.FC<BackgroundVideoProps> = ({ url }) => (
  <chakra.video
    src={url}
    autoPlay
    loop
    muted
    playsInline
    position="fixed"
    width="100%"
    height="100%"
    top="0"
    left="0"
    zIndex="base"
    objectFit="cover"
    onClick={(e) => e.preventDefault()}
  />
);
