import { FC, PropsWithChildren } from 'react';
import { Flex } from '@chakra-ui/react';

export const ProfilePageContainer: FC<PropsWithChildren> = ({ children }) => (
  <Flex
    w="100%"
    h="100%"
    maxWidth="400px"
    px="4"
    flexDirection="column"
    alignItems="center"
    paddingBottom="32px"
    justifyContent="space-between"
    margin="0 auto"
  >
    {children}
  </Flex>
);
