import { Button } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';

interface FilterButtonProps {
  onClick(): void;
  active?: boolean;
}

export const FilterButton: FC<PropsWithChildren<FilterButtonProps>> = ({
  onClick,
  children,
  active,
}) => (
  <Button
    variant="unstyled"
    onClick={onClick}
    w="68px"
    h="68px"
    border="1px solid"
    borderColor={active ? 'sand.0' : 'clay.0'}
    borderRadius="8px"
    bg={active ? 'sand.0' : 'transparent'}
  >
    {children}
  </Button>
);
