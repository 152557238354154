import { MediaBreakpoints, useIsDesktop } from '../../';
import {
  CSSProperties,
  FC,
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { IoVolumeHighOutline, IoVolumeMuteOutline } from 'react-icons/io5';
import { Icon, Square } from '@chakra-ui/react';

export type VideoProps = {
  poster: {
    [key in keyof typeof MediaBreakpoints]: string;
  };
  src: {
    [key in keyof typeof MediaBreakpoints]: string;
  };
  shouldStop?: boolean;
  muted?: boolean;
  onClick: (video: HTMLVideoElement) => void;
  videoStyles?: CSSProperties;
};

const volumeIconStyles = {
  w: '40px',
  h: '40px',
};

const volumeContainerStyles = {
  size: '80px',
  position: 'absolute',
  color: 'white',
  pointerEvents: 'none',
  zIndex: 10,
};

export const Video: FC<PropsWithChildren<VideoProps>> = memo(
  ({
    poster,
    src,
    shouldStop = false,
    muted = true,
    onClick,
    children,
    videoStyles,
  }) => {
    const [iconVisible, setIconVisible] = useState(false);
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const isDesktop = useIsDesktop();

    const onClickHandler = useCallback(() => {
      onClick(videoRef.current as HTMLVideoElement);
    }, [onClick]);

    useEffect(() => {
      if (shouldStop) {
        videoRef.current?.pause();
      } else {
        videoRef.current?.play().catch((e) => console.error('E', e));
      }
    }, [shouldStop]);

    useEffect(() => {
      setIconVisible(true);
      const tmt = setTimeout(() => setIconVisible(false), 350);
      return () => clearTimeout(tmt);
    }, [muted]);

    return (
      <>
        <video
          ref={videoRef}
          autoPlay
          muted={muted}
          loop
          playsInline
          poster={isDesktop ? poster.desktop : poster.mobile}
          src={isDesktop ? src.desktop : src.mobile}
          onClick={onClickHandler}
          style={{
            objectFit: 'cover',
            height: '100%',
            width: '100%',
            ...videoStyles,
          }}
        >
          {children}
        </video>

        {iconVisible ? (
          muted ? (
            // @ts-ignore
            <Square {...volumeContainerStyles}>
              <Icon {...volumeIconStyles} as={IoVolumeMuteOutline} />
            </Square>
          ) : (
            // @ts-ignore
            <Square {...volumeContainerStyles}>
              <Icon {...volumeIconStyles} as={IoVolumeHighOutline} />
            </Square>
          )
        ) : null}
      </>
    );
  }
);
