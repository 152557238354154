import { Button, Text, VStack } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { getItem, setItem } from '../../';

type Type = {
  localStorageItemLabel: string;
  message: string;
  forceToHide?: boolean;
};

export const SwipeMessage = ({
  localStorageItemLabel = 'swipe-message',
  message,
  forceToHide = false,
}: Type) => {
  const [shouldShowMessage, setShouldShowMessage] = useState(false);
  const onOkayClick = useCallback(() => {
    setShouldShowMessage(false);
    setItem(localStorageItemLabel, { hidden: true });
  }, [localStorageItemLabel]);

  useEffect(() => {
    const item = getItem(localStorageItemLabel);
    if (!item) {
      setShouldShowMessage(true);
    }
  }, [localStorageItemLabel]);

  useEffect(() => {
    if (forceToHide) {
      onOkayClick();
    }
  }, [forceToHide, onOkayClick]);

  return shouldShowMessage ? (
    <VStack
      borderRadius="md"
      bg="onyx.0"
      pos="absolute"
      top="50px"
      px={4}
      py={6}
      zIndex={1}
      width={{ base: '235px', md: '360px' }}
      justifyContent="center"
      spacing={4}
      left={{ base: 'calc(50% - 117.5px)', md: 'calc(50% - 180px)' }}
    >
      <Text fontSize="sm" align="center">
        {message}
      </Text>
      <Button onClick={onOkayClick} borderRadius="full" variant="outline">
        Got it
      </Button>
    </VStack>
  ) : null;
};
