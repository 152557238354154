import {
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  Icon,
  Text,
} from '@chakra-ui/react';
import { useDatePicker } from '@rehookify/datepicker';
import {
  IoChevronBack,
  IoChevronDown,
  IoChevronForward,
  IoClose,
} from 'react-icons/io5';
import { useState, FC, MouseEvent } from 'react';

import { DatePickerHeaderButton } from './date-picker-header-button';
import { DatePickerHeader } from './date-picker-header';
import { DatePickerContainer } from './date-picker-container';
import { DatePickerCalendarGrid } from './date-picker-calendar-grid';
import { DatePickerHeaderText } from './date-picker-header-text';
import { DatePickerHeaderActions } from './date-picker-header-actions';

type DatePickerViews = 'calendar' | 'months' | 'years';
interface DatePickerProps {
  onClose(): void;
  onChange(value: string): void;
  selectedDate?: string;
}

export const DatePicker: FC<DatePickerProps> = ({
  onClose,
  onChange,
  selectedDate,
}) => {
  const [view, setView] = useState<DatePickerViews>('calendar');
  const {
    data: { months, years, weekDays, calendars },
    propGetters: {
      nextMonthButton,
      previousMonthButton,
      dayButton,
      monthButton,
      yearButton,
      nextYearsButton,
      previousYearsButton,
    },
  } = useDatePicker({
    dates: {
      selectedDates: selectedDate ? new Date(selectedDate) : undefined,
      maxDate: new Date(),
    },
  });

  const { month, year, days } = calendars[0];
  const setMonthView = () => setView('months');
  const setYearsView = () => setView('years');
  const setCalendarView = () => setView('calendar');

  const onDateClick = (_: MouseEvent<HTMLElement>, date: Date) => {
    onChange(date.toLocaleDateString('en-GB'));
    onClose();
  };

  if (view === 'months')
    return (
      <DatePickerContainer>
        <DatePickerHeader>
          <DatePickerHeaderText>{month}</DatePickerHeaderText>
          <DatePickerHeaderActions>
            <DatePickerHeaderButton onClick={setCalendarView}>
              <Icon as={IoClose} />
            </DatePickerHeaderButton>
          </DatePickerHeaderActions>
        </DatePickerHeader>
        <Grid templateColumns="repeat(3, 1fr)" rowGap="2" w="100%">
          {months.map((month) => (
            <Button
              variant="ghost"
              {...monthButton(month, {
                onClick: setCalendarView,
                key: month.name,
              })}
              fontWeight="normal"
              _disabled={{ opacity: 0.4 }}
            >
              {month.name}
            </Button>
          ))}
        </Grid>
      </DatePickerContainer>
    );

  if (view === 'years')
    return (
      <DatePickerContainer>
        <DatePickerHeader>
          <DatePickerHeaderText>{year}</DatePickerHeaderText>
          <DatePickerHeaderActions>
            <DatePickerHeaderButton {...previousYearsButton()}>
              <Icon as={IoChevronBack} />
            </DatePickerHeaderButton>
            <DatePickerHeaderButton {...nextYearsButton()}>
              <Icon as={IoChevronForward} />
            </DatePickerHeaderButton>
            <DatePickerHeaderButton onClick={setCalendarView}>
              <Icon as={IoClose} />
            </DatePickerHeaderButton>
          </DatePickerHeaderActions>
        </DatePickerHeader>
        <Grid templateColumns="repeat(3, 1fr)" rowGap="2" w="100%">
          {years.map((year) => (
            <Button
              variant="ghost"
              {...yearButton(year, {
                onClick: setCalendarView,
                key: year.value,
              })}
              fontWeight="normal"
              _disabled={{ opacity: 0.4 }}
            >
              {year.value}
            </Button>
          ))}
        </Grid>
      </DatePickerContainer>
    );

  return (
    <DatePickerContainer>
      <DatePickerHeader>
        <DatePickerHeaderText>
          <Button variant="unstyled" onClick={setMonthView} mr="4">
            <HStack spacing="1" alignItems="center">
              <Box>{month}</Box>
              <Icon as={IoChevronDown} />
            </HStack>
          </Button>
          &nbsp;
          <Button variant="unstyled" onClick={setYearsView}>
            <HStack spacing="1" alignItems="center">
              <Box>{year}</Box>
              <Icon as={IoChevronDown} />
            </HStack>
          </Button>
        </DatePickerHeaderText>
        <DatePickerHeaderActions>
          <DatePickerHeaderButton {...previousMonthButton()}>
            <Icon as={IoChevronBack} />
          </DatePickerHeaderButton>
          <DatePickerHeaderButton {...nextMonthButton()}>
            <Icon as={IoChevronForward} />
          </DatePickerHeaderButton>
          <DatePickerHeaderButton onClick={onClose}>
            <Icon as={IoClose} />
          </DatePickerHeaderButton>
        </DatePickerHeaderActions>
      </DatePickerHeader>
      <DatePickerCalendarGrid h="40px" alignItems="center">
        {weekDays.map((day) => (
          <GridItem key={`weekDay-${day}`}>
            <Text
              color="clay.0"
              textTransform="uppercase"
              fontSize="xs"
              fontWeight="medium"
            >
              {day}
            </Text>
          </GridItem>
        ))}
      </DatePickerCalendarGrid>
      <Grid
        templateColumns="repeat(7, 1fr)"
        justifyItems="center"
        w="100%"
        rowGap="1.5"
        columnGap="1"
      >
        {days.map((dpDay) => (
          <GridItem key={dpDay.date}>
            <Button
              {...dayButton(dpDay, { onClick: onDateClick, key: dpDay.date })}
              variant="link"
              width="40px"
              height="40px"
              color={
                dpDay.inCurrentMonth || dpDay.selected
                  ? 'white'
                  : 'rgba(235, 235, 245, 0.3)'
              }
              _hover={{ textDecoration: 'none' }}
              borderRadius="50%"
              bg={dpDay.selected ? 'sand.0' : 'transparent'}
              position="relative"
            >
              <Text fontSize="md" fontWeight="normal">
                {dpDay.day}
              </Text>
              {dpDay.isToday && (
                <Box
                  display="block"
                  w="8px"
                  h="8px"
                  bg="gray.300"
                  position="absolute"
                  bottom="-10px"
                  borderRadius="full"
                />
              )}
            </Button>
          </GridItem>
        ))}
      </Grid>
    </DatePickerContainer>
  );
};
