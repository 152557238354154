import { useToast } from '@chakra-ui/react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import { getMessageFromCode } from '../../common';

export const useResetPassword = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const onResetPassword = async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);
      navigate('/login');
      toast({
        description: 'Your password reset email has been sent',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
    } catch ({ code }) {
      toast({
        description: getMessageFromCode(code as string),
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
    }
  };

  return onResetPassword;
};
