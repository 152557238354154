import { Icon, IconProps } from '@chakra-ui/react';

export const GoogleIcon: React.FC<IconProps> = (props) => (
  <Icon fill="none" viewBox="0 0 26 26" display="block" {...props}>
    <path
      d="m7.06 15.43-.79 2.93-2.87.06A11.25 11.25 0 0 1 3.3 7.87l2.56.47L7 10.88a6.72 6.72 0 0 0 .06 4.55Z"
      fill="currentColor"
    />
    <path
      d="M24.45 10.96a11.31 11.31 0 0 1-4.02 10.92l-3.23-.16-.46-2.85a6.74 6.74 0 0 0 2.9-3.44H13.6v-4.47h10.85Z"
      fill="currentColor"
    />
    <path
      d="M20.43 21.88A11.3 11.3 0 0 1 3.4 18.42l3.66-3a6.72 6.72 0 0 0 9.68 3.45l3.69 3.01Z"
      fill="currentColor"
    />
    <path
      d="m20.56 4.37-3.66 3A6.72 6.72 0 0 0 7 10.89L3.3 7.87a11.3 11.3 0 0 1 17.25-3.5Z"
      fill="currentColor"
    />
  </Icon>
);
