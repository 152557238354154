import { ModalHeader, ModalBody, Button, Spacer } from '@chakra-ui/react';
import { FC } from 'react';
import { TrainingDetail } from '../../common/model';
import { TrainingDetailsAccordion } from '../components';

interface TrainingDetailsProps {
  onCloseClick(): void;
  trainingDetails: TrainingDetail[];
}

export const TrainingDetails: FC<TrainingDetailsProps> = ({
  onCloseClick,
  trainingDetails,
}) => (
  <>
    <ModalHeader>
      <Spacer />
      <Spacer />
      <Button
        variant="link"
        onClick={onCloseClick}
        justifySelf="end"
        color="clay.0"
        fontWeight="medium"
        fontSize="sm"
      >
        Close
      </Button>
    </ModalHeader>
    <ModalBody p="0" display="flex" justifyContent="center">
      <TrainingDetailsAccordion trainingDetails={trainingDetails} />
    </ModalBody>
  </>
);
