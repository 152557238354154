import { StateCreator } from 'zustand';
import { produce } from 'immer';

interface ProfileSliceData {
  isOpen: boolean;
}

export interface ProfileSlice {
  profile: ProfileSliceData;
  setProfileIsOpen(openClose: boolean): void;
  toggleProfileOpen(): void;
}

export const profileSlice: StateCreator<ProfileSlice, [], [], ProfileSlice> = (
  set
) => ({
  profile: { isOpen: false },
  setProfileIsOpen(openClose: boolean) {
    set(
      produce((state) => {
        state.profile.isOpen = openClose;
      })
    );
  },
  toggleProfileOpen() {
    set(
      produce((state) => {
        state.profile.isOpen = !state.profile.isOpen;
      })
    );
  },
});
