import { FC, useMemo } from 'react';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import dayjs, { Dayjs } from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { AvatarLinesFeed } from './avatar-lines-feed';
import { ScheduleItem, TFeedItemContainer } from '../../model/types';
import { convertDateToSingleFormat } from '../../../common/utils/convertDateToSingleFormat';
import { useClassTypeData } from '../../../home/hooks/useClassTypeData';
import { StyleProps } from '@chakra-ui/styled-system';
import { getTime } from '../../../train/utils';

dayjs.extend(isToday);
dayjs.extend(isYesterday);

const NoTrainings: FC<{ date: ReturnType<Dayjs['format']> }> = ({ date }) => (
  <Box pb={{ base: 8, md: 0 }}>You did not complete any classes on {date}</Box>
);

export const FeedItemContainer: FC<TFeedItemContainer> = ({
  coach,
  day = '',
  withoutBackLine,
  ...props
}) => (
  <HStack spacing="10px" alignItems="stretch" pb="50px">
    <AvatarLinesFeed coach={coach} withoutBackLine={withoutBackLine} />
    <VStack alignItems="flex-start" pt="2">
      <Text as="h3" fontSize="xs" color="white">
        {day}
      </Text>
      <FeedItem day={day} coach={coach} key={props.id} {...props} />
    </VStack>
  </HStack>
);

const FeedItem: FC<ScheduleItem> = ({
  type,
  duration,
  description,
  activeTime,
}) => {
  const { data: typeData } = useClassTypeData(type);

  return (
    <VStack alignItems="flex-start" spacing={2}>
      <Text casing="uppercase" color="sand.0" fontWeight={500}>
        {typeData && `${typeData.name.toUpperCase()} ${duration}`}
      </Text>
      <Text fontSize="xl" color="stone.300" fontWeight={500} noOfLines={1}>
        {description?.short}
      </Text>
      <Text fontSize="sm" color="stone.300">
        {/* TODO calories */}
        {getTime(activeTime)} min
      </Text>
    </VStack>
  );
};

type ScheduleFeedType = {
  data: ScheduleItem[];
  selectedDayValue: null | Date;
  currentCalendarDate: Dayjs;
} & StyleProps;

const ScheduleFeed = ({
  data,
  selectedDayValue,
  currentCalendarDate,
  ...props
}: ScheduleFeedType) => {
  const daysInMap = useMemo(() => {
    return selectedDayValue
      ? data.filter(
          (dayData) =>
            convertDateToSingleFormat(dayData.date) ===
            convertDateToSingleFormat(selectedDayValue)
        )
      : null;
  }, [data, selectedDayValue]);

  return (
    <VStack alignItems="flex-start" {...props}>
      {selectedDayValue ? (
        daysInMap && daysInMap.length ? (
          daysInMap.map((dayData: ScheduleItem, index: number) => (
            <FeedItemContainer
              key={dayData.id + dayData.endedAt.valueOf()}
              withoutBackLine={index === 0}
              {...dayData}
            />
          ))
        ) : (
          <NoTrainings date={dayjs(selectedDayValue).format('MMMM, D')} />
        )
      ) : data.length ? (
        data.map((dayData: ScheduleItem, index: number) => (
          <FeedItemContainer
            key={`${dayData.id}-${index}`}
            withoutBackLine={index === 0}
            {...dayData}
          />
        ))
      ) : (
        <NoTrainings date={dayjs(currentCalendarDate).format('MMMM')} />
      )}
    </VStack>
  );
};

export default ScheduleFeed;
