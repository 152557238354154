import {
  Flex,
  Hide,
  GridItem,
  Stack,
  Box,
  Icon,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { IoList } from 'react-icons/io5';

import { UserAvatar, ChakraDomLink, Logo } from '../../common';
import { ROUTES } from '../../router';
import { NAVIGATION_WIDTH } from '../constants';
import { useMainNavigation } from '../presenter/useMainNavigation';

import { TrainIcon } from './train-icon';
import { HomeIcon } from './home-icon';
import { InboxIcon } from './inbox-icon';
import { MainNavigationLink } from './main-navigation-link';
import { ScheduleIcon } from './schedule-icon';
import { TribeIcon } from './tribe-icon';
import { MainNavigationButton } from './main-navigation-button';

export const MainNavigation = () => {
  const {
    isCoach,
    match,
    avatarPosition,
    shouldMenuPreventOverlap,
    totalUnreadCount,
    onListDrawerOpen,
    onProgressDrawerOpen,
  } = useMainNavigation();

  const bp = useBreakpointValue({
    base: 'visible',
    xl: 'hidden',
  });

  return (
    <GridItem>
      <Flex
        position="fixed"
        w={{ base: '100%', md: `${NAVIGATION_WIDTH}px` }}
        h={{ base: `${NAVIGATION_WIDTH}px`, md: '100%' }}
        zIndex="sticky"
        backgroundColor="onyx.0"
        px={{ base: 0, md: 3 }}
        py={{ base: 0, md: 8 }}
        alignItems="center"
        justifyContent={{ base: 'center', md: 'space-between' }}
        direction={{ base: 'row', md: 'column' }}
        as="section"
        bottom="0"
      >
        <Hide below="md">
          <Box h="12">
            <Link to={ROUTES.ROOT}>
              <Logo w="100%" h="auto" color="white" />
            </Link>
          </Box>
        </Hide>
        <Stack
          direction={{ base: 'row', md: 'column' }}
          spacing={{ base: 8, sm: 12 }}
        >
          {isCoach ? (
            <>
              <Hide above="md">
                <MainNavigationButton onClick={onListDrawerOpen}>
                  <Icon as={IoList} fontSize="24px" />
                  Users
                  {!!totalUnreadCount && (
                    <Box
                      position="absolute"
                      right="0"
                      top="0"
                      zIndex={3}
                      w="4"
                      h="4"
                      fontSize="10px"
                      bgColor="sand.0"
                      color="white"
                      lineHeight="1rem"
                      borderRadius="50%"
                      textAlign="center"
                    >
                      {totalUnreadCount}
                    </Box>
                  )}
                </MainNavigationButton>
              </Hide>
              <MainNavigationLink to={ROUTES.INBOX}>
                <InboxIcon w="21px" h="auto" mb="1" />
                Chat
              </MainNavigationLink>
              {bp !== 'hidden' ? (
                <MainNavigationButton onClick={onProgressDrawerOpen}>
                  <ScheduleIcon w="22px" h="auto" mb="1" />
                  Schedule
                </MainNavigationButton>
              ) : null}
            </>
          ) : (
            <>
              <MainNavigationLink to={ROUTES.ROOT}>
                <HomeIcon w="20px" height="auto" mb="1" />
                Home
              </MainNavigationLink>
              <MainNavigationLink to={ROUTES.TRAIN}>
                <TrainIcon w="18px" h="auto" mb="1" />
                Train
              </MainNavigationLink>
              <MainNavigationLink to={ROUTES.INBOX}>
                <InboxIcon w="21px" h="auto" mb="1" />
                Inbox
                {!!totalUnreadCount && (
                  <Box
                    position="absolute"
                    right="6px"
                    top="-6px"
                    zIndex={3}
                    w="4"
                    h="4"
                    fontSize="10px"
                    bgColor="sand.0"
                    color="white"
                    lineHeight="1rem"
                    borderRadius="50%"
                    textAlign="center"
                  >
                    {totalUnreadCount}
                  </Box>
                )}
              </MainNavigationLink>
              <MainNavigationLink to={ROUTES.SCHEDULE}>
                <ScheduleIcon w="22px" h="auto" mb="1" />
                Schedule
              </MainNavigationLink>
              <MainNavigationLink to={ROUTES.TRIBE}>
                <TribeIcon w="24px" h="auto" mb={{ base: 0.5, md: 1 }} />
                Tribe
              </MainNavigationLink>
            </>
          )}
        </Stack>
        {!(match && avatarPosition === 'fixed') && (
          <ChakraDomLink
            to={ROUTES.PROFILE}
            border="2px solid"
            borderColor="sand.0"
            borderRadius="50%"
            w="48px"
            h="48px"
            position={{ base: 'fixed', md: 'static' }}
            top={{ base: shouldMenuPreventOverlap ? '100px' : '32px', md: 0 }}
            right={{ base: '12px', md: 0 }}
            zIndex="11"
          >
            <UserAvatar />
          </ChakraDomLink>
        )}
      </Flex>
    </GridItem>
  );
};
