import { Button } from '@chakra-ui/react';
import { FC } from 'react';
import { FilterIcon } from './filters-icon';

interface FilterButtonProps {
  hasFilters?: boolean;
  onClick(): void;
}

export const FilterButton: FC<FilterButtonProps> = ({
  hasFilters,
  onClick,
}) => (
  <Button
    variant="unstyled"
    position="absolute"
    zIndex="docked"
    top="52px"
    right={{ base: '16px', sm: '40px', md: '42px' }}
    borderRadius="50%"
    w="40px"
    h="40px"
    bg={hasFilters ? 'onyx.0' : 'stone.0'}
    color="white"
    fontSize="2xl"
    display="flex"
    justifyContent="center"
    alignItems="center"
    onClick={onClick}
  >
    <FilterIcon />
  </Button>
);
