import { ROUTES } from '../../router';
import { DescreteSlider, StepWrapper } from '../components';
import { useOnboardingStep } from '../presenter';

export const Recover = () => {
  const { descreteSliderProps, goToNextStep, name, setOnboardingData } =
    useOnboardingStep(`${ROUTES.ONBOARDING}/${ROUTES.ONBOARDING_TRIBE}`, 5);

  const onNextClick = () => {
    setOnboardingData({
      recover: descreteSliderProps.sliderValue,
    });
    goToNextStep();
  };

  return (
    <StepWrapper
      title="Recover"
      text={`${name}, how often do you integrate recovery into your routine?`}
      buttonText="Next"
      onClick={onNextClick}
      step={3}
    >
      <DescreteSlider
        {...descreteSliderProps}
        min={0}
        max={10}
        startTitle="Almost Never"
        endTitle="Everyday"
      />
    </StepWrapper>
  );
};
