export const SWITCH_CONFIG = {
  variants: {
    hoa: {
      track: {
        bg: 'stone.0',
        w: '36px',
        h: '20px',

        _checked: {
          bg: 'sand.0',
        },
      },
      thumb: {
        w: '20px',
        h: '20px',

        _checked: {
          transform: 'translateX(16px)',
        },
      },
    },
  },
};
