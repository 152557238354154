export const ROOT = '/';

// common things
export const ID_ROUTE = ':id';

// AUTH ROUTES
export const LOGIN = '/login';
export const FORGOT_PASSWORD = '/forgot-password';
export const CREATE_ACCOUNT = '/create-account';

// ONBOARDING
export const ONBOARDING = '/onboarding';
export const ONBOARDING_NAME = 'name';
export const ONBOARDING_TRAIN = 'train';
export const ONBOARDING_FUEL = 'fuel';
export const ONBOARDING_RECOVER = 'recover';
export const ONBOARDING_TRIBE = 'tribe';
export const ONBOARDING_MENTAL_FITNESS = 'mental-fitness';
export const ONBOARDING_DESK_SITTING = 'desk-sitting';

export const ONBOARDING_ROUTES = [
  ONBOARDING,
  `${ONBOARDING}/${ONBOARDING_NAME}`,
  `${ONBOARDING}/${ONBOARDING_TRAIN}`,
  `${ONBOARDING}/${ONBOARDING_FUEL}`,
  `${ONBOARDING}/${ONBOARDING_RECOVER}`,
  `${ONBOARDING}/${ONBOARDING_TRIBE}`,
  `${ONBOARDING}/${ONBOARDING_MENTAL_FITNESS}`,
];

// PAYMENT ROUTES
export const PAYMENT = '/payment';

export const ROUTES_WITHOUT_MAIN_NAVIGATION = [...ONBOARDING_ROUTES, PAYMENT];

// Private routes
export const SCHEDULE = '/schedule';
export const INBOX = '/inbox';
export const TRIBE = '/tribe';

// Profile routes
export const PROFILE = '/profile';
export const EDIT_PROFILE = 'edit';
export const PROFILE_SETTINGS = 'settings';

// COACHES
export const COACHES = '/coaches';
export const EXACT_COACHES = ID_ROUTE;

// TRAINING
export const TRAIN = '/train';
export const EXACT_TRAINING = ID_ROUTE;
export const POST_TRAINING_FEEDBACK = 'feedback';
export const POST_TRAINING_STATISTICS = 'statistics';
export const POST_TRAINING_SHARE = 'share';

export const BOOK_SESSION = '/book-session';

export const COACHES_CHAT = '/coaches-chat';

export const COACHES_ROUTES = [
  COACHES_CHAT,
  PROFILE,
  `${PROFILE}/${EDIT_PROFILE}`,
  `${PROFILE}/${PROFILE_SETTINGS}`,
];

// Exceptional routes
export const BOOK_LIVE_SESSION_NOW = '/book-live-session-now';
