import { UserHealth } from '../common/model/store/user-slice';

export const FOOTS = [3, 4, 5, 6, 7, 8];
export const INCHES = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

export const DATE_OF_BIRTH_PLACEHOLDER = 'DD/MM/YYYY';
export const DEFAULT_HEALTH: UserHealth = {
  height: '',
  weight: 0,
  dateOfBirth: undefined,
};
