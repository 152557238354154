import { Button, Box, Text, VStack, Spinner } from '@chakra-ui/react';
import { CardElement } from '@stripe/react-stripe-js';

import { useCard } from '../presenter';

const CARD_OPTIONS = {
  style: {
    base: {
      iconColor: '#fff',
      color: '#fff',
      fontWeight: '500',
      fontFamily: "'Libre Franklin', sans-serif",
      fontSize: '16px',
      fontSmoothing: 'antialiased',
    },
  },
};

export const Card = () => {
  const { onPayClick, status, error } = useCard();

  return (
    <VStack spacing="12" alignItems="flex-start">
      <VStack w="100%" spacing="6" alignItems="flex-start">
        <Text fontSize="xl" color="onyx.400">
          Please enter your card details
        </Text>
        <Box w="100%">
          <CardElement options={CARD_OPTIONS} />
        </Box>
        {error && (
          <Text
            fontSize="sm"
            fontWeight="normal"
            color="red.500"
            w="100%"
            mt="4"
          >
            {error}
          </Text>
        )}
        <Button
          variant="hoa-primary"
          onClick={onPayClick}
          alignSelf="self-end"
          w="100%"
          disabled={status === 'loading'}
        >
          Pay
          {status === 'loading' && <Spinner ml="4" />}
        </Button>
      </VStack>
    </VStack>
  );
};
