import { StateCreator } from 'zustand';
import { produce } from 'immer';
import { getItem, setItem } from '../../utils';
import { ONBOARDING_STORAGE_KEY } from '../../../onboarding/constants';

export interface OnboardingData {
  train: number;
  fuel: number;
  recover: number;
  tribe: number;
  mentalFitness: number;
}

export interface OnboardingSliceData extends OnboardingData {
  name: string;
}

export const ONBOARDING_INITIAL_STATE: OnboardingSliceData = {
  name: '',
  train: 0,
  fuel: 0,
  recover: 0,
  tribe: 0,
  mentalFitness: 0,
};

export interface OnboardingSlice {
  onboarding: OnboardingSliceData;
  forceOnboarding: boolean;
  setOnboardingData(data: Partial<OnboardingSliceData>): void;
  setForceOnboarding(fore: boolean): void;
}

export const onboardingSlice: StateCreator<
  OnboardingSlice,
  [],
  [],
  OnboardingSlice
> = (set, get) => ({
  onboarding: getItem(ONBOARDING_STORAGE_KEY, ONBOARDING_INITIAL_STATE),
  forceOnboarding: false,
  setOnboardingData(data: Partial<OnboardingData>) {
    set(
      produce((state) => {
        state.onboarding = { ...state.onboarding, ...data };
      })
    );

    // ⚠️ SIDE EFFECT store onboarding to localStorage
    setItem(ONBOARDING_STORAGE_KEY, get().onboarding);
  },
  setForceOnboarding(force) {
    set(
      produce((state) => {
        state.forceOnboarding = force;
      })
    );
  },
});
