import { Center, Image } from '@chakra-ui/react';
import { THUMB_DOWN, THUMB_UP } from '../../common';
import { hoverOpacityTransition } from '../../common';
import { SystemProps } from '@chakra-ui/styled-system';

type ThumbsBoxProps = {
  value: number;
  onRatingChange: (score: number) => void;
} & SystemProps;

const thumbStyles = {
  cursor: 'pointer',
  ...hoverOpacityTransition,
};

const active = 'invert(0.5) sepia(1) saturate(5)';
const green = 'hue-rotate(75deg)';
const red = 'hue-rotate(315deg)';

const isGood = (val: number) =>
  val ? `${active} ${green}` : `${active} ${red}`;

export const ThumbsBox = ({
  onRatingChange,
  value,
  ...props
}: ThumbsBoxProps) => {
  return (
    <Center {...props}>
      <Image
        src={THUMB_UP}
        onClick={() => onRatingChange(1)}
        mr={8}
        filter={value === 1 ? isGood(value) : undefined}
        {...thumbStyles}
      />
      <Image
        src={THUMB_DOWN}
        onClick={() => onRatingChange(0)}
        filter={value === 0 ? isGood(value) : undefined}
        {...thumbStyles}
      />
    </Center>
  );
};
