import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

import { useStore } from '../../common/model';
import { auth } from '../../firebase';
import { ROUTES } from '../../router';

export const useProfilePage = () => {
  const { name } = useStore((state) => state.userInfo)!;
  const { client } = useStore((state) => state.chat);
  const setChatData = useStore((state) => state.setChatData);
  const navigate = useNavigate();

  const onEditClick = () => navigate(ROUTES.EDIT_PROFILE);

  const onSignOutClick = async () => {
    client?.disconnectUser();
    setChatData({ client: null, channel: null });
    await signOut(auth);
    navigate(ROUTES.ROOT);
  };

  return {
    name,
    onSignOutClick,
    onEditClick,
  };
};
