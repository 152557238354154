import { COACHES } from '../../router/routes';
import { Avatar, HStack, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Coach as TCoach } from '../../common/types/coach';

export const SimpleCoach = ({ coach }: { coach: TCoach }) => {
  const n = useNavigate();
  return (
    <HStack
      cursor="pointer"
      onClick={() => n(`${COACHES}/${coach.id}`)}
      spacing="2"
    >
      <Avatar
        src={coach.avatarUrl}
        name={`${coach.firstName} ${coach.lastName}`}
        showBorder
        borderColor="clay.0"
        h="40px"
        w="40px"
      />
      <Text
        color="white"
        fontSize="sm"
      >{`${coach.firstName} ${coach.lastName}`}</Text>
    </HStack>
  );
};
