import {
  ClassesCoachesSwitcher,
  SwiperStabilizer,
  SwiperVideoFeed,
} from '../../common';
import { FilterButton } from '../components/filter-button';
import { FilterModal } from '../../filter-modal';
import { useTrainingList } from '../presenter/use-training-list';

export const TrainingList = () => {
  const { setIsOpen, isOpen, training } = useTrainingList();

  return (
    <SwiperStabilizer>
      <FilterButton onClick={setIsOpen.on} />
      <ClassesCoachesSwitcher active="classes" />
      {training.length === 0 ? null : <SwiperVideoFeed data={training} />}
      <FilterModal isOpen={isOpen} onClose={setIsOpen.off} />
    </SwiperStabilizer>
  );
};
