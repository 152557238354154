import { useMemo, useState } from 'react';
import { DatesMap, ScheduleItem } from '../model/types';
import dayjs, { Dayjs } from 'dayjs';
import { useCoaches } from '../../common/model';
import { useWeeksData } from '../../common/model/weeks/use-weeks';
import { useClassHistoryData } from '../../train/hooks/use-class-history';
import { convertDateToSingleFormat } from '../../common/utils/convertDateToSingleFormat';
import { ClassHistory } from '../../common/types/class-history';
import { Coach } from '../../common/types/coach';
import { isDayCompleted } from '../../common/utils/isDayCompleted';

const normalizationFunction = (
  coaches: Coach[],
  trainingOrHistory: ClassHistory
) => {
  const newTraining = {
    ...trainingOrHistory.class,
  } as ScheduleItem;
  const dayInDayJs = dayjs(trainingOrHistory.endedAt.seconds * 1000);

  // prepare day
  newTraining.day = dayInDayJs.isToday()
    ? 'Today'
    : dayInDayJs.isYesterday()
    ? 'Yesterday'
    : dayInDayJs.format('dddd DD MMM');

  // prepare coach
  // @ts-ignore // TODO
  newTraining.coach = coaches?.find(
    (coach) => coach.id === trainingOrHistory.class.coach
  );

  // prepare date
  newTraining.date = dayInDayJs.format('YYYY-MM-DD');

  // prepare day result COMPLETED / UNCOMPLETED
  newTraining.dayResult = isDayCompleted(trainingOrHistory)
    ? 'COMPLETED'
    : 'UNCOMPLETED';

  // for better comparison
  newTraining.endedAt = dayInDayJs;

  // for showing active time
  newTraining.activeTime = trainingOrHistory.activeTime;

  return newTraining;
};

export const useNormalizedScheduleData = (
  currentCalendarDate: Dayjs,
  userId?: string
): ScheduleItem[] => {
  const { data: weeks, loading, error } = useWeeksData(userId);
  const {
    data: coaches,
    loading: coachesLoading,
    error: coachesError,
  } = useCoaches();
  const {
    data: classHistory,
    loading: classHistoryLoading,
    error: classHistoryError,
  } = useClassHistoryData();

  if (
    loading ||
    error ||
    coachesLoading ||
    coachesError ||
    classHistoryLoading ||
    classHistoryError
  ) {
    return [];
  }

  if (!weeks || !coaches || !classHistory) {
    return [];
  }

  const normalizedClassesHistory = classHistory.map((training) =>
    normalizationFunction(coaches, training)
  );

  return normalizedClassesHistory
    .filter((training) => currentCalendarDate.isSame(training.date, 'month'))
    .sort((trainingA, trainingB) => {
      // Today and Yesterday have the most priority, so they must be on the very top
      const compareValueA =
        trainingA.day.toLowerCase() === 'today'
          ? Number.MAX_SAFE_INTEGER
          : trainingA.day.toLowerCase() === 'yesterday'
          ? Number.MAX_SAFE_INTEGER - 1
          : trainingA.endedAt.valueOf();
      const compareValueB =
        trainingB.day.toLowerCase() === 'today'
          ? Number.MAX_SAFE_INTEGER
          : trainingB.day.toLowerCase() === 'yesterday'
          ? Number.MAX_SAFE_INTEGER - 1
          : trainingB.endedAt.valueOf();
      return compareValueB - compareValueA;
    });
};

export const useScheduleData = (userId?: string) => {
  const [selectedDayValue, setSelectedDay] = useState<null | Date>(null);
  const [currentCalendarDate, setCurrentCalendarDate] = useState<Dayjs>(
    dayjs()
  );

  const schedule = useNormalizedScheduleData(currentCalendarDate, userId);

  const datesToMap = useMemo(
    () =>
      schedule.reduce((aggr: DatesMap, next: ScheduleItem) => {
        aggr[convertDateToSingleFormat(next.date)] =
          aggr[convertDateToSingleFormat(next.date)] || [];
        aggr[convertDateToSingleFormat(next.date)].push(next);
        return aggr;
      }, {} as DatesMap),
    [schedule]
  );

  return {
    schedule,
    selectedDayValue,
    setSelectedDay,
    datesToMap,
    currentCalendarDate,
    setCurrentCalendarDate,
  };
};
