import { useBoolean } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetUserInfoToFirestore, useStore } from '../../common/model';
import { ROUTES } from '../../router';

export const useShowBookFirstSession = () => {
  const [isOpen, setIsOpen] = useBoolean(false);
  const { popups, subscription, id, name } = useStore(
    (state) => state.userInfo
  )!;
  const navigate = useNavigate();
  const { updateUserInfoInFirestore } = useSetUserInfoToFirestore();

  const onClose = async () => {
    setIsOpen.off();
    try {
      await updateUserInfoInFirestore(id as string, {
        popups: { bookFirstSession: false },
      });
    } catch (e: any) {
      console.error('ERROR: ', e);
    }
  };

  const onScheduleClick = () => {
    navigate(ROUTES.BOOK_SESSION);
    onClose();
  };

  useEffect(() => {
    if (
      subscription?.type === 'personalTraining' &&
      (!popups || popups.bookFirstSession === undefined)
    ) {
      setIsOpen.on();
    }
  }, [popups, subscription, setIsOpen]);

  return { isOpen, onClose, name, onScheduleClick };
};
