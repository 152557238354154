import {
  Box,
  Divider,
  HStack,
  Icon,
  Spacer,
  Text,
  useBreakpoint,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { CollapsableText } from '../collapsable-text';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';
import { ReactNode, useCallback } from 'react';

export type SimpleTrailerFooterProps = {
  title: ReactNode;
  cta: ReactNode;
  description: {
    short: ReactNode;
    long: ReactNode;
  };
};

export const SimpleTrailerFooter = ({
  title,
  cta,
  description,
}: SimpleTrailerFooterProps) => {
  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen: false,
  });

  const onTextClick = useCallback(() => {
    onToggle();
  }, [onToggle]);

  const breakpoint = useBreakpoint();

  return (
    <VStack
      as="footer"
      position="absolute"
      p={{ base: 4, md: 8 }}
      bottom="0"
      left="0"
      bg="linear-gradient(180deg, transparent, rgba(0,0,0,100))"
      w="100%"
      alignItems="flex-start"
      justifyContent={{ md: 'space-between' }}
      flexDirection={{ md: 'row' }}
      zIndex={1}
    >
      <VStack
        alignItems="flex-start"
        w={{ base: '100%', md: '540px', lg: '665px' }}
        m={{ xl: isOpen ? '0 0 0 50px' : '50px 0 -30px 50px' }}
      >
        <VStack
          cursor="pointer"
          w="100%"
          onClick={onTextClick}
          alignItems="flex-start"
          mb={{ base: cta ? 20 : 0, md: 0 }}
        >
          <HStack alignItems="center" justifyContent="space-between" w="100%">
            <Text fontWeight={500} align="left">
              {title}
            </Text>
            <Spacer />
            <Icon as={isOpen ? IoChevronUp : IoChevronDown} color="white" />
          </HStack>

          <Divider />

          <CollapsableText
            collapsedText={description?.short}
            expandedText={description?.long}
            limit={breakpoint === 'base' ? 6 : 2}
            textAlign="left"
            fontSize="xs"
            color="#E3DFDC" // TODO clay.400
            isOpen={isOpen}
            minH="auto"
          />
        </VStack>
      </VStack>

      <Box
        position={{ base: 'absolute', md: 'relative' }}
        bottom={{ base: 6, md: 0 }}
        right={{ base: 'auto', md: 0 }}
        marginLeft={{ md: '4 !important' }}
        w={{ base: 'calc(100% - 32px)', md: '255px' }}
        alignSelf="flex-end"
      >
        {cta}
      </Box>
    </VStack>
  );
};
