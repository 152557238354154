import { Block } from './block';
import { Button, Divider, VStack } from '@chakra-ui/react';

type Type = {
  isOverlayVisible: boolean;
  zone: null | { name: string; value: string };
  bpm: null | { name: string; value: string };
  cal: null | { name: string; value: string };
  time: null | { name: string; value: string };
  onEndClick: () => void;
};

export const VideoStatisticsOverlay = ({
  isOverlayVisible,
  zone,
  bpm,
  cal,
  time,
  onEndClick,
}: Type) => {
  return (
    <VStack
      position="absolute"
      bottom="50%"
      m="auto"
      transform="translateY(50%)"
      alignItems="flex-start"
      py={4}
      px={{ base: 2, md: 6 }}
      borderRadius="lg"
      minW={{ base: '130px', md: '190px' }}
      left={4}
      visibility={isOverlayVisible ? 'visible' : 'hidden'}
      bg="rgba(255, 255, 255, 0.1)"
      boxShadow="0 0 40px rgba(0, 0, 0, 0.1)"
      backdropFilter="blur(20px)"
      zIndex={1}
    >
      {!zone ? null : <Block obj={zone} />}
      {!bpm ? null : <Block obj={bpm} propsName={{ fontSize: 'xs' }} />}
      {!cal ? null : <Block obj={cal} propsName={{ fontSize: 'xs' }} />}
      {!time ? null : <Block obj={time} withDivider={false} />}
      <Divider mt={{ md: '2 !important' }} />
      <Button
        p={0}
        mt={{ base: '4 !important', md: '5 !important' }}
        variant="hoa-transparent"
        fontSize="md"
        fontWeight="500"
        color="onyx.0"
        alignSelf={{ md: 'center' }}
        onClick={onEndClick}
        cursor="pointer"
      >
        End Class
      </Button>
    </VStack>
  );
};
