import {
  useState,
  createContext,
  useContext,
  PropsWithChildren,
  FC,
} from 'react';
import { StripeData, SubscriptionView } from '../types';

const SubscriptionContext = createContext<any>(null);
export const useSubscriptionContext = () => useContext(SubscriptionContext);

export const SubscriptionProvider: FC<PropsWithChildren> = ({ children }) => {
  const [stripeData, setStripeData] = useState<StripeData>({});
  const [view, setView] = useState<SubscriptionView>('plan');

  return (
    <SubscriptionContext.Provider
      value={{ view, setView, stripeData, setStripeData }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};
