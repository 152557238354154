import { useBreakpointValue } from '@chakra-ui/react';

export const useIsDesktop = () => {
  const value = useBreakpointValue(
    { base: 'mobile', md: 'desktop' },
    { ssr: false }
  );

  return value === 'desktop';
};
