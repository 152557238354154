import { Center, Text } from '@chakra-ui/react';
import { DAYS_SHORT } from '../../constants';

type DayType = {
  day: typeof DAYS_SHORT[number];
  isFuture?: boolean;
  isCompleted?: boolean;
  isToday?: boolean;
};

export const DayCircle = ({ day, isFuture, isCompleted, isToday }: DayType) => {
  const bg = isToday
    ? 'sand.0'
    : isFuture
    ? 'card'
    : isCompleted
    ? 'clay.0'
    : 'onyx.0';
  const borderColor = isToday
    ? { borderColor: 'transparent' }
    : { borderColor: 'clay.0' };
  const color =
    isCompleted || isFuture || isToday
      ? { color: 'white' }
      : { color: 'clay.0' };
  const dynamicProps = { bg, ...borderColor, ...color };
  return (
    <Center
      borderRadius="full"
      w="40px"
      h="40px"
      border="solid 2px"
      {...dynamicProps}
    >
      <Text fontSize="xs" fontWeight={500}>
        {day}
      </Text>
    </Center>
  );
};
