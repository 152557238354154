import { StyleFunctionProps } from '@chakra-ui/react';

export const INPUT_CONFIG = {
  sizes: {
    md: {
      field: {
        h: '48px',
      },
    },
  },
  variants: {
    'hoa-filled': ({ isInvalid }: StyleFunctionProps) => ({
      field: {
        background: 'stone.50',
        borderRadius: '8px',
        border: '1px solid',
        borderColor: isInvalid ? 'red.600' : 'stone.100',
        color: isInvalid ? 'red.600' : 'onyx.0',

        '&::placeholder': {
          color: 'onyx.400',
        },

        _focus: {
          borderColor: isInvalid ? 'red.600' : 'sand.300',

          _hover: {
            borderColor: isInvalid ? 'red.600' : 'sand.300',
          },
        },

        _hover: {
          borderColor: isInvalid ? 'red.600' : 'sand.200',
        },
      },
    }),
  },
};
