import { Heading } from '@chakra-ui/react';

const formatUserName = (name: string) => name.split(' ')[0];

interface ProfileHeadingProps {
  name: string;
}

export const ProfileHeading: React.FC<ProfileHeadingProps> = ({ name }) => (
  <Heading fontSize="2xl" fontWeight="normal" mb="20" color="clay.100">
    {formatUserName(name)}'s profile
  </Heading>
);
