import { collection, getDocs, onSnapshot } from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { ClassHistory } from '../../common/types/class-history';
import { useStore } from '../../common/model';
import { firestore } from '../../firebase';
import { CollectionReference } from '@firebase/firestore-types';

export type ClassHistoryLoadingData = {
  data: null | ClassHistory[];
  loading: boolean;
  error: null | string;
};

export const ClassHistory_INITIAL_STATE = {
  data: null,
  loading: false,
  error: null,
};

async function asyncQuery(
  ref: CollectionReference,
  setter: (d: ClassHistoryLoadingData) => void
) {
  // @ts-ignore // TODO
  const docSnap = await getDocs(ref);

  if (!docSnap) {
    setter({
      data: null,
      loading: false,
      error: 'No data for week!',
    });
  }
}

export const useClassHistoryData = () => {
  const [classHistory, setClassHistory] = useState<ClassHistoryLoadingData>(
    ClassHistory_INITIAL_STATE
  );
  const user = useStore((state) => state.user);
  const classHistoryRef = useMemo(
    () => collection(firestore, 'users', user!.uid, 'classHistory'),
    [user]
  );

  useEffect(() => {
    // @ts-ignore // TODO
    asyncQuery(classHistoryRef, setClassHistory);
  }, [classHistoryRef]);

  // Initial set of data and subscription for future data updates.
  // initial happens after `asyncQuery` is completed
  useEffect(() => {
    onSnapshot(classHistoryRef, {
      async next(snapshot) {
        if (snapshot) {
          const docs = snapshot.docs;
          const data = docs.map((doc) => doc.data());

          setClassHistory({
            data: data as ClassHistory[],
            loading: false,
            error: null,
          });
        }
      },
      error(error) {
        console.error(error);
      },
    });
  }, [setClassHistory, classHistoryRef]);

  return classHistory;
};
