import { useCallback } from 'react';
import { useStore } from '../../common/model';

export const useCoachesDrawer = () => {
  const { isListDrawerOpen, isProgressDrawerOpen } = useStore(
    (state) => state.chat
  );
  const setChatData = useStore((state) => state.setChatData);

  const onListDrawerClose = useCallback(
    () => setChatData({ isListDrawerOpen: false }),
    [setChatData]
  );
  const onListDrawerOpen = useCallback(
    () => setChatData({ isListDrawerOpen: true }),
    [setChatData]
  );
  const onProgressDrawerClose = useCallback(
    () => setChatData({ isProgressDrawerOpen: false }),
    [setChatData]
  );
  const onProgressDrawerOpen = useCallback(
    () => setChatData({ isProgressDrawerOpen: true }),
    [setChatData]
  );

  return {
    isListDrawerOpen,
    onListDrawerClose,
    onListDrawerOpen,
    isProgressDrawerOpen,
    onProgressDrawerClose,
    onProgressDrawerOpen,
  };
};
