import { Icon, IconProps } from '@chakra-ui/react';
import { FC } from 'react';

export const FilterIcon: FC<IconProps> = (props) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    display="block"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.8 5.25a.75.75 0 1 0 0 1.5h18.15a.75.75 0 0 0 0-1.5H2.8Zm2.14 4a.75.75 0 1 0 0 1.5H18.8a.75.75 0 0 0 0-1.5H4.94ZM8.46 18c0-.41.33-.75.75-.75h5.33a.75.75 0 0 1 0 1.5H9.21a.75.75 0 0 1-.75-.75Zm-1.39-4.75a.75.75 0 0 0 0 1.5h9.6a.75.75 0 0 0 0-1.5h-9.6Z"
      clipRule="evenodd"
    />
  </Icon>
);
