import { Icon } from '@chakra-ui/react';
import { IoSettingsOutline } from 'react-icons/io5';
import { ChakraDomLink } from '../../common';
import { ROUTES } from '../../router';

export const SettingsButton = () => (
  <ChakraDomLink to={ROUTES.PROFILE_SETTINGS} fontSize="2xl">
    <Icon as={IoSettingsOutline} display="block" />
  </ChakraDomLink>
);
