import { Heading, Image, Text } from '@chakra-ui/react';
import { FC } from 'react';

interface InfoCarouselSlideProps {
  image: string;
  title: string;
  description: string;
}

export const InfoCarouselSlide: FC<InfoCarouselSlideProps> = ({
  image,
  title,
  description,
}) => {
  return (
    <>
      <Image src={image} borderRadius="16" overflow="hidden" mb="6" />
      <Heading as="h6" fontSize="xl" fontWeight="normal" mb="4">
        {title}
      </Heading>
      <Text fontSize="sm" lineHeight="1.5">
        {description}
      </Text>
    </>
  );
};
