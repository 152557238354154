import { Button, Grid, Icon } from '@chakra-ui/react';
import { IoChevronBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { Logo } from '../../common';

export const OnboardingHeader = () => {
  const navigate = useNavigate();

  const onBackClick = () => navigate(-1);
  return (
    <Grid
      templateColumns="32px 1fr 32px"
      alignItems="center"
      pt="4vh"
      px={{ base: 4, md: 12 }}
      position="relative"
      top="0"
      left="0"
      zIndex="docked"
      w="100%"
    >
      <Button
        variant="unstyled"
        onClick={onBackClick}
        w="8"
        h="8"
        justifySelf="start"
      >
        <Icon as={IoChevronBack} fontSize="2rem" />
      </Button>
      <Logo
        color="white"
        w={{ base: '7rem', md: '10rem' }}
        h="auto"
        justifySelf="center"
      />
    </Grid>
  );
};
