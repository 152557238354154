import { Grid, GridItem } from '@chakra-ui/react';
import { Outlet, useLocation } from 'react-router-dom';

import { ROUTES_WITHOUT_MAIN_NAVIGATION } from '../../router/routes';
import { NAVIGATION_WIDTH } from '../constants';
import { MainNavigation } from '../components/main-navigation';

export const InternalLayout = () => {
  const { pathname } = useLocation();
  const hasMainNavigation = !ROUTES_WITHOUT_MAIN_NAVIGATION.includes(pathname);

  const templateColumns = hasMainNavigation
    ? `${NAVIGATION_WIDTH}px 1fr`
    : '1fr';

  return (
    <Grid
      templateColumns={{ base: '1fr', md: templateColumns }}
      templateRows={{
        base: hasMainNavigation ? `1fr ${NAVIGATION_WIDTH}px` : '1fr',
        md: '1fr',
      }}
      height="100%"
    >
      {hasMainNavigation && <MainNavigation />}
      <GridItem order={{ base: '-1', md: '2' }} minH="100%">
        <Outlet />
      </GridItem>
    </Grid>
  );
};
