import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Heading,
  Text,
} from '@chakra-ui/react';
import { FAQ_CONTENT } from '../constants';

export const FAQ = () => (
  <>
    <Heading textAlign="center" fontSize="xl" fontWeight="medium" pt="4">
      FAQs
    </Heading>
    <Accordion allowMultiple w="100%">
      {FAQ_CONTENT.map(({ question, answer }, idx) => (
        <AccordionItem
          borderTop="0"
          borderBottomWidth="1px"
          borderColor="stone.0"
          key={`faq-question-${idx}`}
        >
          <AccordionButton py="4" px="0">
            <Text as="span" flex="1" textAlign="left">
              {question}
            </Text>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4} px="0">
            {answer}
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  </>
);
