import { useCallback, useState } from 'react';
import { Swiper as SwiperClass } from 'swiper/types';
import { AnySwiperEntity, SwiperVideoFeedItem } from './swiper-video-feed-item';
import { Empty, ChakraSwiper, SwipeMessage } from '../';
import { SwiperProps } from 'swiper/react';

type SwipeFeedType = {
  data: AnySwiperEntity[];
  withSwipeMessage?: {
    message: string;
    localStorageItemLabel: string;
  };
  swiperOptions?: SwiperProps;
};

// TODO Make it in Chakra components
export const SwiperVideoFeed = ({
  data,
  withSwipeMessage,
  swiperOptions,
}: SwipeFeedType) => {
  const [muted, toggleMute] = useState(true);
  const [forceToHideMessage, setForceHideMessage] = useState(false);
  const [activeIndex, setActiveIndex] = useState(
    swiperOptions?.initialSlide || 0
  );

  const onSlideChange = useCallback(
    (Swiper: SwiperClass) => {
      if (activeIndex !== Swiper.activeIndex) {
        setActiveIndex(Swiper.activeIndex);
        setForceHideMessage(true);
      }
    },
    [activeIndex]
  );

  const onClickHandler = useCallback(
    (video: HTMLVideoElement) => {
      toggleMute(!muted);
    },
    [muted]
  );

  if (!data) {
    return <Empty />;
  }

  return (
    <>
      <ChakraSwiper
        onSlideChange={onSlideChange}
        simulateTouch={true}
        {...swiperOptions}
      >
        {data.map((slideItem, index) => (
          <SwiperVideoFeedItem
            slideItem={slideItem}
            shouldStop={activeIndex !== index}
            muted={muted}
            onClick={onClickHandler}
            key={slideItem.id}
            children={slideItem.children}
          />
        ))}
      </ChakraSwiper>
      {withSwipeMessage ? (
        <SwipeMessage
          localStorageItemLabel={withSwipeMessage.localStorageItemLabel}
          message={withSwipeMessage.message}
          forceToHide={forceToHideMessage}
        />
      ) : null}
    </>
  );
};
