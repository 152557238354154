import { useToast } from '@chakra-ui/react';
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useStore, AuthFlow } from '../../common/model';
import { auth } from '../../firebase';

export const useAuthWithSocial = (flow: AuthFlow) => {
  const setAuthFlow = useStore((state) => state.setAuthFlow);
  const navigate = useNavigate();
  const toast = useToast();

  const signInWithSocial = async (provider: any) => {
    try {
      await signInWithPopup(auth, provider);
      setAuthFlow(flow);
      navigate('/', { replace: true });
    } catch ({ code }: any) {
      toast({
        description: code as string,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
    }
  };

  const signInWithGoogle = () => signInWithSocial(new GoogleAuthProvider());

  const signInWithFacebook = () => signInWithSocial(new FacebookAuthProvider());

  const signInWithApple = () =>
    signInWithSocial(new OAuthProvider('apple.com'));

  return {
    signInWithGoogle,
    signInWithFacebook,
    signInWithApple,
  };
};
