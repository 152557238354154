import { createBrowserRouter, Navigate } from 'react-router-dom';
import { ForgotPassword, Login, CreateAccount } from '../../auth';
import { OnboardingContainer, StartScreen } from '../../onboarding';
import * as ROUTES from '../routes';

import { onboardingRoutes } from './onboarding-routes';
import { commonRoutes } from './common-routes';

export const publicRouter = createBrowserRouter([
  {
    path: ROUTES.ROOT,
    element: <StartScreen />,
  },
  ...commonRoutes,
  onboardingRoutes(false),
  {
    path: ROUTES.CREATE_ACCOUNT,
    element: <OnboardingContainer />,
    children: [
      {
        index: true,
        element: <CreateAccount />,
      },
    ],
  },
  {
    path: ROUTES.LOGIN,
    element: <OnboardingContainer />,
    children: [
      {
        index: true,
        element: <Login />,
      },
    ],
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    element: <OnboardingContainer />,
    children: [
      {
        index: true,
        element: <ForgotPassword />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={ROUTES.ROOT} replace={true} />,
  },
]);
