import { Box, SliderMark } from '@chakra-ui/react';
import { FC } from 'react';

interface SliderMarkThemedProp {
  val: number;
}

export const SliderMarkThemed: FC<SliderMarkThemedProp> = ({ val }) => (
  <SliderMark
    display="flex"
    value={val}
    position="absolute"
    height="10px"
    borderColor="card"
    w="10px"
    top="15px"
    ml="-5px"
  >
    <Box w="4px" height="100%" bg="card" />
    <Box w="2px" height="100%" bg="stone.0" />
    <Box w="4px" height="100%" bg="card" />
  </SliderMark>
);
