import { Grid, GridProps } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';

export const DatePickerCalendarGrid: FC<PropsWithChildren<GridProps>> = (
  props
) => (
  <Grid
    templateColumns="repeat(7, 1fr)"
    justifyItems="center"
    w="100%"
    {...props}
  />
);
