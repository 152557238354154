import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

export interface ClientContextValue {
  clientId: string;
  setClientId(id: string): void;
}

const ClientContext = createContext<ClientContextValue>(
  {} as ClientContextValue
);

export const useClientContext = () => useContext(ClientContext);

export const ClientProvider: FC<PropsWithChildren> = ({ children }) => {
  const [clientId, setClientId] = useState<string>('');
  return (
    <ClientContext.Provider value={{ clientId, setClientId }}>
      {children}
    </ClientContext.Provider>
  );
};
