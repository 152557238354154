import { onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useStore } from '../../common/model';
import { auth } from '../../firebase';

export const useAuthCheck = () => {
  const user = useStore((state) => state.user);
  const setUser = useStore((state) => state.setUser);
  const setRoles = useStore((state) => state.setRoles);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState<string>('pending');

  // @TODO we should show error to user
  const setErrorFn = (error: any) => {
    setError(error);
    setStatus('rejected');
  };

  useEffect(() => {
    const listener = onAuthStateChanged(
      auth,
      async (user) => {
        const result = await user?.getIdTokenResult();

        setUser(user);
        setRoles(result?.claims.roles || []);
        setStatus('resolved');
      },
      setErrorFn
    );

    return () => {
      listener();
    };
  }, [setUser, setRoles]);

  return { user, error, isPending: status === 'pending' };
};
