import { Icon, IconProps } from '@chakra-ui/react';
import { FC } from 'react';

export const CoachesIcon: FC<IconProps> = (props) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 25"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 14.25a5.25 5.25 0 1 0 0-10.5 5.25 5.25 0 0 0 0 10.5Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M7.48 6.33A8.71 8.71 0 0 0 17.2 8.3M8.25 17.25V18a3.75 3.75 0 0 0 7.5 0v-.75"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M21.28 23.25a9.75 9.75 0 0 0-18.56 0"
    />
  </Icon>
);
