import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../common/model';

export const useOnboardingStep = (nextRoute: string, startValue: number) => {
  const name = useStore((state) => state.onboarding.name);
  const setOnboardingData = useStore((state) => state.setOnboardingData);

  const navigate = useNavigate();
  const [sliderValue, setSliderValue] = useState<number>(startValue);

  const onChange = (val: number) => setSliderValue(val);

  const goToNextStep = () => navigate(nextRoute);

  return {
    descreteSliderProps: {
      sliderValue,
      onChange,
    },
    goToNextStep,
    name,
    setOnboardingData,
  };
};
