import * as ROUTES from '../routes';
import { BookLiveSessionNow } from '../../book-live-session-now';

/* Routes which available for private and public */
export const commonRoutes = [
  {
    path: ROUTES.BOOK_LIVE_SESSION_NOW,
    children: [
      {
        index: true,
        element: <BookLiveSessionNow />,
      },
    ],
  },
];
