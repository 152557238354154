import {
  Box,
  Button,
  HStack,
  Image,
  Input,
  InputGroup,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useCallback, useMemo, useReducer, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from '../../common/model';
import {
  POST_TRAINING_FEEDBACK,
  POST_TRAINING_STATISTICS,
} from '../../router/routes';
import { RatingBox, PopupContainer } from '../components';
import { reportClass } from '../../firebase/functions/report-class';
import { OgMetaTags } from '../../common/components/meta';
import { useTrainingOgMetaData } from '../hooks/use-training-og-meta-data';
import { useTraining } from '../hooks';
import { Error, LoaderCenter, useIsDesktop } from '../../common';

export type State = {
  like: number;
  overall: number;
  coach: number;
  music: number;
  stream: number;
  difficulty: number;
  comments: string;
};

type SetAction = {
  type: 'set';
  payload: {
    name: string;
    value: number | string;
  };
};

type SetAllAction = {
  type: 'all';
  payload: State;
};

function reducer(state: State, action: SetAction | SetAllAction) {
  switch (action.type) {
    case 'set': {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    }

    case 'all': {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}

export const PostTrainingFeedback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigateTo = useMemo(() => {
    return location.pathname.replace(
      POST_TRAINING_FEEDBACK,
      POST_TRAINING_STATISTICS
    );
  }, [location]);
  const [formState, dispatch] = useReducer(reducer, {
    like: 4,
    overall: 4,
    coach: 4,
    music: 4,
    stream: 4,
    difficulty: 3,
    comments: '',
  });
  const [sendingFeedback, setSendingFeedback] = useState(false);
  const setFeedback = useStore((state) => state.setFeedback);
  const feedback = useStore((state) => state.class.feedback);
  const { data: training, error, loading } = useTraining();
  const trainingOgMetaData = useTrainingOgMetaData(training);

  const setStateValue = useCallback(
    (name: string) => (value: number | string) => {
      dispatch({
        type: 'set',
        payload: {
          name,
          value,
        },
      });
    },
    []
  );
  const onSubmit = useCallback(async () => {
    if (feedback) {
      const feedbackWithSurvey = {
        ...feedback,
        survey: { ...formState },
        comments: formState.comments,
      };
      // @ts-ignore TODO
      delete feedbackWithSurvey.survey.comments;
      setFeedback(feedbackWithSurvey);
      setSendingFeedback(true);
      // TODO also save to LS in order to retrieve it from storage if last time
      //  was an error and save it back
      try {
        await reportClass(feedbackWithSurvey);
      } catch (e) {
        console.error('Navigating next because of', e);
      }
      setSendingFeedback(false);
    }

    navigate(navigateTo);
  }, [feedback, setFeedback, navigate, navigateTo, formState]);

  const onSkipClick = useCallback(async () => {
    if (feedback) {
      const feedbackWithoutComments = {
        ...feedback,
      };
      // @ts-ignore TODO
      delete feedbackWithoutComments.comments;
      setFeedback(feedbackWithoutComments);
      setSendingFeedback(true);
      try {
        await reportClass(feedbackWithoutComments);
      } catch (e) {
        console.error('Navigating next because of', e);
      }
      setSendingFeedback(false);
    }

    navigate(navigateTo);
  }, [feedback, navigate, navigateTo, setFeedback]);

  const isDesktop = useIsDesktop();

  // TODO Load previous survey and set it
  // useEffect(() => {
  //   if (postTrainingFeedbacks?.length) {
  //     dispatch({
  //       type: 'all',
  //       payload: postTrainingFeedbacks[0],
  //     });
  //   }
  // }, [postTrainingFeedbacks]);

  if (error) {
    return <Error />;
  }

  if (loading) {
    return <LoaderCenter />;
  }

  return (
    <Box
      w="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      pos="absolute"
      left={0}
      top={0}
      zIndex="overlay"
    >
      <OgMetaTags image={trainingOgMetaData} />
      {training?.thumbnail.landscape || training?.thumbnail.landscape ? (
        <Image
          src={
            isDesktop
              ? training?.thumbnail.landscape
              : training?.thumbnail.portrait
          }
          pos="fixed"
          w="100%"
          h="100%"
          fit="cover"
          filter="brightness(0.25)"
        />
      ) : null}
      <PopupContainer
        bottom={{ base: 'auto' }}
        opacity={sendingFeedback ? 0.5 : 1}
        pointerEvents={sendingFeedback ? 'none' : 'auto'}
      >
        <HStack justifyContent="flex-end" w="100%" margin={{ base: 4 }}>
          <Button
            variant="hoa-transparent"
            color="beige.0"
            onClick={onSkipClick}
          >
            Skip
          </Button>
        </HStack>
        <VStack
          w="100%"
          spacing={6}
          padding={{ base: 4 }}
          maxW={{ base: '325px' }}
        >
          <Text fontSize="xl" fontWeight={500} color="white">
            Tell us how we did
          </Text>
          <RatingBox
            type="thumbs"
            onRatingChange={setStateValue('like')}
            value={formState.like}
          >
            Did you like this class?
          </RatingBox>
          <RatingBox
            onRatingChange={setStateValue('overall')}
            value={formState.overall}
          >
            Overall
          </RatingBox>
          <RatingBox
            onRatingChange={setStateValue('coach')}
            value={formState.coach}
          >
            Coach
          </RatingBox>
          <RatingBox
            onRatingChange={setStateValue('music')}
            value={formState.music}
          >
            Music
          </RatingBox>
          <RatingBox
            onRatingChange={setStateValue('stream')}
            value={formState.stream}
          >
            Stream
          </RatingBox>
          <RatingBox
            onRatingChange={setStateValue('difficulty')}
            value={formState.difficulty}
          >
            Difficulty
          </RatingBox>
          <InputGroup>
            <Input
              borderColor="clay.0"
              type="text"
              onChange={(e: any) => setStateValue('comments')(e.target.value)}
              size="lg"
              placeholder="Add a comment"
            />
            {/* TODO audio */}
            {/*<InputRightElement
            pointerEvents="none"
            children={<Icon as={IoMicOutline} color="clay.0" />}
          />*/}
          </InputGroup>
          <Button
            width="100%"
            variant="hoa-primary"
            borderRadius="24px"
            onClick={onSubmit}
          >
            Submit
          </Button>
        </VStack>
      </PopupContainer>
    </Box>
  );
};
