import { Box, BoxProps, Divider, Heading, Text } from '@chakra-ui/react';
import { FC } from 'react';

interface SubscriptionItemProps extends BoxProps {
  subscriptionType: string;
  price: number;
  description: string;
}

export const SubscriptionItem: FC<SubscriptionItemProps> = ({
  subscriptionType,
  price,
  description,
  ...props
}) => {
  return (
    <Box
      w="50%"
      border="2px solid"
      p="4"
      borderRadius="8px"
      textAlign="center"
      cursor="pointer"
      tabIndex={0}
      role="button"
      {...props}
    >
      <Text fontSize="sm" fontWeight="semibold">
        {subscriptionType}
      </Text>
      <Heading as="h2" fontSize="2xl" fontWeight="normal">
        ${price}
      </Heading>
      <Text fontSize="10px" fontWeight="medium">
        / Month
      </Text>
      <Divider w="100%" my="2" borderColor="stone.0" />
      <Text fontSize="10px">{description}</Text>
    </Box>
  );
};
