export const STATIC_CDN = 'https://static.hoa.plus/';

// IMAGES
export const ONBOARDING_IMG_URL = `${STATIC_CDN}images/brandon-off-center.jpg`;
export const LOGO_URL = `${STATIC_CDN}images/logo.svg`;
export const THUMB_UP = `${STATIC_CDN}images/thumbs-up.svg`;
export const THUMB_DOWN = `${STATIC_CDN}images/thumbs-down.svg`;
export const DEVICES_IMG_URL = `${STATIC_CDN}images/devices.png`;
export const CAROUSEL_1 = `${STATIC_CDN}images/carousel1.jpg`;
export const CAROUSEL_2 = `${STATIC_CDN}images/carousel2_square.jpg`;
export const CAROUSEL_3 = `${STATIC_CDN}images/carousel3.jpg`;
export const CAROUSEL_4 = `${STATIC_CDN}images/carousel4.jpg`;
export const OG_IMAGE_DEFAULT = `${STATIC_CDN}images/hoa-preview.jpg`;

// VIDEOS
export const START_SCREEN_VIDEO_H264 = `${STATIC_CDN}videos/web-start-screen.h264.mp4`;
export const START_SCREEN_VIDEO_H265 = `${STATIC_CDN}videos/web-start-screen.h265.mp4`;

// LINKS
export const PRIVACY_POLICY = 'https://houseofathlete.com/privacy-policy';
export const TERMS_OF_USE = 'https://houseofathlete.com/terms-of-use';
