import { httpsCallable } from 'firebase/functions';
import { useEffect, useState } from 'react';
import { useStore } from '../../common/model';
import { functions } from '../../firebase';

interface PortalLinkData {
  data: {
    url: string;
  };
}

const createStripePortalLink = httpsCallable(
  functions,
  'createStripePortalLink'
);

export const useProfileSettings = () => {
  const { subscription, id } = useStore((state) => state.userInfo)!;
  const [portalLink, setPortalLink] = useState<string>('');

  useEffect(() => {
    if (subscription?.platform === 'web' && !portalLink) {
      const getLink = async () => {
        const { data } = (await createStripePortalLink({
          returnUrl: window.location.href,
        })) as PortalLinkData;

        setPortalLink(data?.url || '');
      };

      getLink();
    }
  }, [subscription?.platform, portalLink]);

  return { portalLink, id };
};
