import { Avatar, Box, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { Coach } from '../../../common/types/coach';

type AvatarLinesFeedProps = {
  coach?: Coach;
  withoutBackLine?: boolean;
};

const SPACE = '20px';

export const AvatarLinesFeed: FC<AvatarLinesFeedProps> = ({
  coach,
  withoutBackLine = false,
}) => (
  <VStack mt="12px" justifyContent="stretch">
    {Boolean(withoutBackLine) === false ? (
      <Box h={SPACE} w="2px" bg="sand.0" mt={`-${SPACE} !important`} />
    ) : null}
    <Avatar
      w={50}
      h={50}
      showBorder={true}
      name={`${coach?.firstName} ${coach?.lastName}`}
      src={coach?.avatarUrl}
      borderColor="sand.0"
      p="4px"
      mt="0 !important"
    />
    <Box
      minH="90px"
      h={'100%'}
      w="2px"
      bg="sand.0"
      mt="0 !important"
      mb="-100px !important"
    />
  </VStack>
);
