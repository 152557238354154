import { Box, Divider } from '@chakra-ui/react';
import { StyleProps } from '@chakra-ui/styled-system';
import { Name } from './name';
import { Value } from './value';

type TBlock = {
  obj: {
    name: string;
    value: string;
  };
  propsBox?: StyleProps;
  propsName?: StyleProps;
  propsValue?: StyleProps;
  withDivider?: boolean;
};

export const Block = ({
  obj,
  propsBox,
  propsName,
  propsValue,
  withDivider = true,
}: TBlock) => (
  <Box {...propsBox}>
    {withDivider ? <Divider mb={4} /> : null}
    <Name {...propsName}>{obj.name}</Name>
    <Value {...propsValue}>{obj.value}</Value>
  </Box>
);
