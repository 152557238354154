import { defineStyleConfig } from '@chakra-ui/react';

export const CHAKRA_SWIPER_SLIDE_CONFIG = defineStyleConfig({
  baseStyle: {
    textAlign: 'center',
    bg: 'black',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  // The default variant value
  defaultProps: {
    variant: 'default',
  },
});
