import { FC, PropsWithChildren } from 'react';
import { TextProps } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';

export const Value: FC<PropsWithChildren<TextProps>> = ({
  children,
  ...props
}) => (
  <Text fontSize="2xl" color="onyx.0" {...props}>
    {children}
  </Text>
);
