import { ROUTES } from '../../router';
import { DescreteSlider, StepWrapper } from '../components';
import { useOnboardingStep } from '../presenter';

export const Tribe = () => {
  const { descreteSliderProps, goToNextStep, name, setOnboardingData } =
    useOnboardingStep(
      `${ROUTES.ONBOARDING}/${ROUTES.ONBOARDING_MENTAL_FITNESS}`,
      5
    );

  const onNextClick = () => {
    setOnboardingData({
      tribe: descreteSliderProps.sliderValue,
    });
    goToNextStep();
  };

  return (
    <StepWrapper
      title="Tribe"
      text={`${name}, do you prefer to train alone or with a community?`}
      buttonText="Next"
      onClick={onNextClick}
      step={4}
    >
      <DescreteSlider
        {...descreteSliderProps}
        min={0}
        max={10}
        startTitle="Alone"
        endTitle="As part of a Tribe"
      />
    </StepWrapper>
  );
};
