import { Button, Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { TrainingDetail } from '../../common/model';
import { TrainingDetailsAccordion } from '../../filter-modal/components';

interface StartTrainingDetailsProps {
  onSetStartView(): void;
  trainingDetails: TrainingDetail[];
}

export const StartTrainingDetails: FC<StartTrainingDetailsProps> = ({
  onSetStartView,
  trainingDetails,
}) => {
  return (
    <>
      <Flex w="100%" justifyContent="flex-end">
        <Button
          variant="unstyled"
          color="clay.0"
          fontWeight="medium"
          onClick={onSetStartView}
          h="auto"
        >
          Close
        </Button>
      </Flex>
      <TrainingDetailsAccordion trainingDetails={trainingDetails} />
    </>
  );
};
