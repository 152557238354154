import { As, chakra } from '@chakra-ui/react';

export const MainNavigationItem = (component: As<any>) =>
  chakra(component, {
    baseStyle: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: { base: 'flex-end', md: 'center' },
      alignItems: 'center',
      position: 'relative',

      fontWeight: 'light',
      fontSize: 'xs',
      color: 'stone.400',
      transition: 'color 250ms linear 0ms',

      '&.active': {
        fontWeight: 'medium',
        color: 'sand.0',
      },

      _hover: {
        '&:not(.active)': {
          color: 'stone.100',
        },
      },
    },
  });
