import { SimpleCoach } from './simple-coach';
import { useCoachData } from '../hooks/useCoachData';

export const CoachToLoad = ({ coach }: { coach: string }) => {
  const { data, loading, error } = useCoachData(coach);

  if (loading || error) {
    return null;
  }

  if (error) {
    console.error('error', error);
  }

  return !data ? null : <SimpleCoach coach={data} />;
};
