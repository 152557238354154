import { VStack, Text, Image } from '@chakra-ui/react';
import { Badge } from '../../../common/types/badge';

const Achievement = ({ image, name, completed }: Badge) => {
  return (
    <VStack
      w="90px"
      opacity={`${!completed ? 0.3 : 1}`}
      title={name}
      spacing={4}
    >
      <Image src={image} minH="120px" alt={name} fit="contain" />
      <Text
        fontSize="xs"
        fontWeight={500}
        color="onyx.100"
        opacity={`${completed ? 0.4 : 1}`}
        align="center"
      >
        {name}
      </Text>
    </VStack>
  );
};

export default Achievement;
