export const SELECT_CONFIG = {
  variants: {
    'hoa-select': {
      field: {
        borderRadius: '8px',
        cursor: 'pointer',
        color: 'onyx.0',
        fontSize: 'md',
      },
      icon: {
        right: 12,
        color: 'onyx.400',
      },
    },
  },
};
