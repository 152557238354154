import { Box, VStack, Text, Link } from '@chakra-ui/react';
import { createUserWithEmailAndPassword } from 'firebase/auth';

import {
  useAuthWithEmailAndPassword,
  useSendVerificationEmail,
} from '../presenter';
import {
  EmailPasswordForm,
  EmailPasswordFormData,
  SocialAuthBlock,
} from '../components';
import { REGISTER_FLOW } from '../constants';
import { PRIVACY_POLICY, TERMS_OF_USE } from '../../common';

export const CreateAccount = () => {
  const sendVerificationEmail = useSendVerificationEmail();

  const onSignUp = useAuthWithEmailAndPassword(
    createUserWithEmailAndPassword,
    REGISTER_FLOW,
    sendVerificationEmail
  );
  const onSubmit = ({ email, password }: EmailPasswordFormData) =>
    onSignUp(email, password);

  return (
    <VStack spacing="12">
      <EmailPasswordForm
        heading="Create your account"
        submitButtonText="Create Account"
        onSubmit={onSubmit}
      />
      <SocialAuthBlock title="Sign up with" flow={REGISTER_FLOW} />
      <Box w="100%" textAlign="center">
        <Text color="stone.400" fontSize="sm">
          By sigining up, you agree to our <br />
          <Link isExternal textDecoration="underline" href={TERMS_OF_USE}>
            Terms & Conditions
          </Link>{' '}
          and{' '}
          <Link isExternal textDecoration="underline" href={PRIVACY_POLICY}>
            Privacy Policy
          </Link>
        </Text>
      </Box>
    </VStack>
  );
};
