import { useState, useMemo } from 'react';

import { useStore } from '../../common/model';

export const useStreamChat = () => {
  const [retry, setRetry] = useState<number>(0);
  const { client, channel, noChatAvailable } = useStore((state) => state.chat);
  const setChatData = useStore((state) => state.setChatData);

  if (noChatAvailable && retry < 2) {
    setChatData({ noChatAvailable: false });
    setRetry((v) => v + 1);
  }

  const value = useMemo(
    () => ({ client, channel, noChatAvailable }),
    [client, channel, noChatAvailable]
  );

  return value;
};
