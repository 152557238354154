import { httpsCallable } from 'firebase/functions';
import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect } from 'react';

import { REGISTER_FLOW } from '../../auth';
import {
  useStore,
  UserInfo,
  useSetUserInfoToFirestore,
} from '../../common/model';
import { firestore, functions } from '../../firebase';
import { ONBOARDING_STORAGE_KEY } from '../../onboarding/constants';
import { removeItem } from '../../common/utils';

const checkUserCreated = httpsCallable(functions, 'checkUserCreated');

export const useUserInfo = () => {
  const flow = useStore((state) => state.auth.flow);
  const user = useStore((state) => state.user);
  const { name, ...onboarding } = useStore((state) => state.onboarding);
  const setAuthFlow = useStore((state) => state.setAuthFlow);
  const setUserInfo = useStore((state) => state.setUserInfo);
  const { updateUserInfoInFirestore } = useSetUserInfoToFirestore();

  useEffect(() => {
    if (user && flow === REGISTER_FLOW) {
      checkUserCreated()
        .then(() =>
          updateUserInfoInFirestore(user.uid, {
            name,
            onboarding,
            id: user.uid,
          })
        )
        .then(() => removeItem(ONBOARDING_STORAGE_KEY))
        .then(() => setAuthFlow(undefined))
        .catch(console.error);
    }
  }, [flow, user, updateUserInfoInFirestore, onboarding, name, setAuthFlow]);

  useEffect(() => {
    if (user) {
      const docRef = doc(firestore, 'users', user.uid as string);
      onSnapshot(docRef, {
        next(snapshot) {
          if (snapshot.exists()) {
            setUserInfo(snapshot.data() as UserInfo);
          } else {
            setUserInfo({ name: '' });
          }
        },
        error(error) {
          console.error(error);
        },
      });
    }
  }, [user, setUserInfo]);
};
