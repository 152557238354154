import { httpsCallable } from 'firebase/functions';
import { useState } from 'react';

import { useStore } from '../../common/model';
import { functions } from '../../firebase';
import { useSubscriptionContext } from '../model/subscription-provider';
import { StripeData, SubscriptionType } from '../types';

const initStripe = httpsCallable<{ clientSecret: string; customerId: string }>(
  functions,
  'initStripe'
);

export const usePlan = () => {
  const { setView, setStripeData } = useSubscriptionContext();
  const { name } = useStore((state) => state.userInfo)!;
  const [subscription, setSubscription] = useState<SubscriptionType | null>(
    null
  );
  const [status, setStatus] = useState<'idle' | 'loading' | 'finished'>('idle');

  const onSelectPlan = async () => {
    try {
      setStatus('loading');
      const { data } = await initStripe();
      setStatus('finished');
      setStripeData({ ...(data as StripeData), type: subscription?.type });
      setView('card');
    } catch (e: any) {
      console.log('SHOW ERROR: ', e);
    }
  };

  return { name, onSelectPlan, subscription, setSubscription, status };
};
