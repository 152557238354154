import { VStack, Input, Text, chakra } from '@chakra-ui/react';
import { StepWrapper } from '../components/step-wrapper';
import { useOnboardingName } from '../presenter/use-onboarding-name';

export const Name = () => {
  const { register, errors, onSubmit } = useOnboardingName();

  return (
    <chakra.form onSubmit={onSubmit}>
      <StepWrapper
        title="Hello!"
        text="What’s your first name?"
        buttonText="Next"
        buttonType="submit"
      >
        <VStack w="100%" spacing="3" alignItems="flex-start">
          <Input
            isInvalid={!!errors.name}
            variant="hoa-filled"
            placeholder="Enter your first name"
            size="md"
            {...register('name')}
          />
          {!!errors.name && (
            <Text fontSize="xs" color="red.600">
              {errors.name.message}
            </Text>
          )}
        </VStack>
      </StepWrapper>
    </chakra.form>
  );
};
