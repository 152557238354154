import {
  ClassesCoachesSwitcher,
  SimpleTrailerFooter,
  SwiperStabilizer,
  SwiperVideoFeed,
} from '../../common';
import { useCoaches } from '../../common/model';
import { Loader, Error } from '../../common';
import { HStack, Image, Text } from '@chakra-ui/react';
import * as ReactDOMServer from 'react-dom/server';

import './coaches-pagination.css';
import { useMemo } from 'react';

export const CoachList = () => {
  const { data, loading, error } = useCoaches();

  const dataNormalized = useMemo(() => {
    if (!data) return [];

    return data.map((coach) => ({
      ...coach,
      thumbnail: {
        portrait: coach.image.portrait,
        landscape: coach.image.landscape,
      },
      trailer: {
        portrait: coach.video.portrait,
        landscape: coach.video.landscape,
      },
      children: (
        <SimpleTrailerFooter
          title={
            <HStack>
              <Text fontWeight={500}>
                {coach.firstName} {coach.lastName}
              </Text>
              <Text> | </Text>
              <Text fontSize="sm">{coach.nickname}</Text>
            </HStack>
          }
          cta={null}
          description={{
            short: (
              <Text as="span">
                "{coach.description?.short}"
                <br />
                {coach.description?.long}
              </Text>
            ),
            long: (
              <Text as="span">
                "{coach.description?.short}"
                <br />
                {coach.description?.long}
              </Text>
            ),
          }}
        />
      ),
    }));
  }, [data]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <SwiperStabilizer>
      <ClassesCoachesSwitcher active="coaches" />
      {!data || !data.length ? null : (
        <SwiperVideoFeed
          swiperOptions={{
            pagination: {
              clickable: true,
              verticalClass: 'pagination-coaches',
              renderBullet: (index, className) => {
                if (!data) return null;
                return ReactDOMServer.renderToStaticMarkup(
                  <button className={`${className}`} key={index}>
                    <Image src={data[index].avatarUrl} />
                  </button>
                );
              },
            },
          }}
          data={dataNormalized}
        />
      )}
    </SwiperStabilizer>
  );
};
