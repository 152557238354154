import { VStack } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { StyleProps } from '@chakra-ui/styled-system';

export const PopupContainer = ({
  children,
  ...props
}: {
  children: ReactElement | ReactElement[];
} & StyleProps) => (
  <VStack
    borderTopLeftRadius={{ base: 16 }}
    borderTopRightRadius={{ base: 16 }}
    borderRadius={{ md: 24 }}
    bg="card"
    mt={{ base: 4, md: 12, lg: 16 }}
    padding={{ base: 4 }}
    w="100%"
    maxW="510px"
    position="absolute"
    zIndex="popover"
    bottom={{ base: 0, md: 'auto' }}
    {...props}
  >
    {children}
  </VStack>
);
