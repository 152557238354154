import { StreamChat } from 'stream-chat';
import { useEffect } from 'react';

import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebase';
import { useStore } from '../../common/model';
import { isCoach } from '../../common';
import { useClientContext } from '../model/ClientProvider';

const apiKey = process.env.REACT_APP_GET_STREAM_API_KEY as string;
const getStreamUserToken = httpsCallable(functions, 'getStreamUserToken');

export const useCoachesChat = () => {
  const { id: userId } = useStore((state) => state.userInfo) || {};
  const { client } = useStore((state) => state.chat);
  const setChatData = useStore((state) => state.setChatData);
  const roles = useStore((state) => state.roles);
  const { setClientId } = useClientContext();

  useEffect(() => {
    async function initChat() {
      const chatClient = StreamChat.getInstance(apiKey);

      const { data: token } = await getStreamUserToken();

      await chatClient.connectUser(
        {
          id: userId!,
        },
        token as string
      );

      setChatData({ client: chatClient });

      chatClient.on('message.read', ({ total_unread_count = 0 }) => {
        setChatData({ totalUnreadCount: total_unread_count });
      });

      chatClient.on('message.new', ({ total_unread_count = 0 }) => {
        setChatData({ totalUnreadCount: total_unread_count });
      });

      // We need to get initial active chat user id to pull its progress information
      const { unsubscribe } = chatClient.on(
        'channels.queried',
        ({ queriedChannels }) => {
          const clientId =
            queriedChannels?.channels[0].channel.id.split('-')[1];
          setClientId(clientId as string);

          unsubscribe();
        }
      );

      window.addEventListener('beforeunload', () =>
        chatClient?.disconnectUser()
      );
    }

    initChat();
    // We need to eslint-disable here because we want to init chat once per reload
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { client, userId, isCoach: isCoach(roles) };
};
