import { StateCreator } from 'zustand';
import { produce } from 'immer';

export interface Filters {
  coach: string;
  duration: number[];
  type: string[];
}

export interface FiltersSlice {
  filters: Filters;
  setCoachesFilter(coach: string): void;
  setDurationFilter(duration: number[]): void;
  setTypeFilter(type: string[]): void;
  resetFilters(): void;
}

const INITIAL_FILTERS: Filters = {
  coach: '',
  duration: [],
  type: [],
};

export const filtersSlice: StateCreator<FiltersSlice, [], [], FiltersSlice> = (
  set
) => ({
  filters: INITIAL_FILTERS,
  setCoachesFilter(coach) {
    set(
      produce((state) => {
        state.filters.coach = coach;
      })
    );
  },
  setDurationFilter(duration = []) {
    set(
      produce((state) => {
        state.filters.duration = duration;
      })
    );
  },
  setTypeFilter(type = []) {
    set(
      produce((state) => {
        state.filters.type = type;
      })
    );
  },
  resetFilters() {
    set(
      produce((state) => {
        state.filters = INITIAL_FILTERS;
      })
    );
  },
});
