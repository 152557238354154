import { Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { NAVIGATION_WIDTH } from '../../../internal/constants';

import { ROUTES } from '../../../router';
import { ClassesCoachesSwitcherLink } from './classes-coaches-switcher-link';

interface ClassesCoachesSwitcherProps {
  active: 'classes' | 'coaches';
}

export const ClassesCoachesSwitcher: FC<ClassesCoachesSwitcherProps> = ({
  active,
}) => {
  return (
    <Flex
      h="40px"
      w="184px"
      bg="stone.0"
      borderRadius="20px"
      position="absolute"
      top="52px"
      left={{
        base: '50%',
        md: `calc(${NAVIGATION_WIDTH}px + (100% - ${NAVIGATION_WIDTH}px)/ 2)`,
      }}
      transform="translateX(-50%)"
      padding="2px"
      alignItems="center"
      zIndex="docked"
    >
      <ClassesCoachesSwitcherLink
        to={ROUTES.TRAIN}
        active={active === 'classes'}
      >
        Classes
      </ClassesCoachesSwitcherLink>
      <ClassesCoachesSwitcherLink
        to={ROUTES.COACHES}
        active={active === 'coaches'}
      >
        Coaches
      </ClassesCoachesSwitcherLink>
    </Flex>
  );
};
