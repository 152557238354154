import {
  Drawer,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  Box,
  Flex,
  useBreakpointValue,
} from '@chakra-ui/react';
import { FC, PropsWithChildren, Fragment } from 'react';
import { Calendar } from '../../schedule/components/calendar/calendar';
import ScheduleFeed from '../../schedule/components/schedule-feed/schedule-feed';
import { useScheduleData } from '../../schedule/presenter';
import { useClientContext } from '../model/ClientProvider';
import { useCoachesDrawer } from '../presenter';

const DrawerWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { isProgressDrawerOpen, onProgressDrawerClose } = useCoachesDrawer();
  const size = useBreakpointValue({
    base: 'full',
    md: 'lg',
  });
  return (
    <Drawer
      isOpen={isProgressDrawerOpen}
      placement="right"
      onClose={onProgressDrawerClose}
      size={size}
    >
      <DrawerContent bg="onyx.0">
        <DrawerHeader>
          <DrawerCloseButton />
        </DrawerHeader>
        {children}
      </DrawerContent>
    </Drawer>
  );
};

export const ClientProgress = () => {
  const { clientId } = useClientContext();
  const {
    schedule,
    datesToMap,
    selectedDayValue,
    setSelectedDay,
    currentCalendarDate,
    setCurrentCalendarDate,
  } = useScheduleData(clientId);
  const value = useBreakpointValue({
    base: 'full',
    md: 'lg',
    xl: null,
  });

  if (!clientId) return null;

  const Wrapper = value === null ? Fragment : DrawerWrapper;

  return (
    <Wrapper>
      <Flex
        direction="column"
        w={{ base: '100%', xl: '33%' }}
        borderLeft="1px solid"
        borderColor="stone.0"
        h="100%"
        alignItems="center"
        overflowY="scroll"
        px="6"
      >
        <Box mb="8">
          <Calendar
            datesToMap={datesToMap}
            selectedDayValue={selectedDayValue}
            setSelectedDay={setSelectedDay}
            currentCalendarDate={currentCalendarDate}
            setCurrentCalendarDate={setCurrentCalendarDate}
          />
        </Box>
        <ScheduleFeed
          data={schedule}
          selectedDayValue={selectedDayValue}
          currentCalendarDate={currentCalendarDate}
          minW={{ base: 'auto', lg: 304 }}
        />
      </Flex>
    </Wrapper>
  );
};
