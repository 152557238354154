import { Icon, IconProps } from '@chakra-ui/react';

export const HomeIcon: React.FC<IconProps> = (props) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.45.83a4.06 4.06 0 0 1 4.93 0l6.17 4.79a3.2 3.2 0 0 1 1.3 2.52v7.97a4.2 4.2 0 0 1-4.26 4.14H4.26A4.2 4.2 0 0 1 0 16.11V8.15c.01-1 .5-1.93 1.3-2.52L7.45.83Zm1.22 1.59-6.15 4.8H2.5c-.32.24-.5.59-.5.95v7.94a2.2 2.2 0 0 0 2.26 2.14H15.6c1.29 0 2.26-1 2.26-2.14V8.16c0-.36-.18-.7-.5-.94l-.01-.02-6.18-4.78a2.06 2.06 0 0 0-2.49 0Z"
      fill="currentColor"
    />
  </Icon>
);
