import { chakra } from '@chakra-ui/react';
import { NAVIGATION_WIDTH } from '../../internal/constants';
import { BRAND_COLORS } from '../../theme/utils';
import { CHAT_WIDTH } from '../constants';

interface ChatThemeProps {
  mode: 'coach' | 'user';
}

export const ChatTheme = chakra<'div', ChatThemeProps>('div', {
  shouldForwardProp(prop) {
    return prop !== 'mode';
  },
  baseStyle: ({ mode }: any) => ({
    w: { base: '100%', md: `calc(100vw - ${NAVIGATION_WIDTH}px)` },
    display: 'flex',
    alignItems: 'center',
    h: '100%',
    flexDirection: mode === 'coach' ? 'row' : 'column',

    '& .str-chat': {
      // Make chat background dark
      '--str-chat__background-color': 'transparent',
      '--str-chat__secondary-background-color': 'transparent',

      // Messages colors
      '--str-chat__own-message-bubble-background-color': BRAND_COLORS.clay['0'],
      '--str-chat__message-bubble-color': 'white',
      '--str-chat__message-bubble-background-color': BRAND_COLORS.onyx['700'],
      '--str-chat__message-error-message-color': BRAND_COLORS.card,

      // Avatar
      '--str-chat__avatar-background-color': BRAND_COLORS.sand['0'],
      '--str-chat__avatar-color': BRAND_COLORS.onyx['0'],

      // Message reactions
      '--str-chat__message-reaction-background-color': 'transparent',
      '--str-chat__own-message-reaction-background-color': 'transparent',
      '--str-chat__own-message-reaction-color': 'white',
      '--str-chat__message-reaction-color': 'white',
      '--str-chat__message-reactions-options-background-color':
        BRAND_COLORS.onyx['400'],
      '--str-chat__message-reactions-option-background-color':
        BRAND_COLORS.onyx['400'],
      '--str-chat__message-reactions-option-hover-background-color':
        BRAND_COLORS.onyx['100'],
      '--str-chat__message-reactions-option-selected-background-color':
        BRAND_COLORS.onyx['100'],
      '--str-chat__tooltip-background-color': BRAND_COLORS.onyx['500'],

      // Message options
      '--str-chat__message-options-color': 'white',
      '--str-chat__message-options-hover-background-color':
        BRAND_COLORS.onyx['400'],
      '--str-chat__message-actions-box-background-color':
        BRAND_COLORS.onyx['400'],
      '--str-chat__message-link-color': BRAND_COLORS.onyx['0'],

      // Message input area
      '--str-chat__message-textarea-color': 'white',
      '--str-chat__message-input-tools-color': BRAND_COLORS.clay['0'],

      // Channel list
      '--str-chat__channel-preview-active-background-color': 'transparent',
      '--str-chat__channel-preview-hover-background-color': 'transparent',
      '--str-chat__channel-search-result-hover-background-color': 'transparent',
      '--str-chat__channel-preview-color': 'white',
      '--str-chat__channel-search-result-color': 'white',
      '--str-chat__channel-preview-unread-badge-background-color':
        BRAND_COLORS.sand['0'],
    },

    // Channel list
    '& .str-chat-channel-list': {
      width: { base: '100%', md: '50%', xl: '33%' },
      display: 'block',
      borderRightColor: 'stone.0',

      '.str-chat__message-sender-avatar, .str-chat__avatar-image': {
        w: '24px !important',
        h: '24px !important',
        lineHeight: '24px !important',
        flexBasis: '24px !important',
        fontSize: '14px !important',
      },

      '.str-chat__channel-list-messenger': {
        pb: '60px',
      },
    },

    '& .str-chat__channel-preview, & .str-chat__channel-search-result': {
      px: '8 !important',
      py: '2 !important',
      columnGap: '4 !important',

      '.str-chat__channel-preview-unread-badge': {
        w: '20px',
        h: '20px',
        borderRadius: '50%',
        justifyContent: 'center',
        p: 0,
      },
    },

    // Channel list search

    '& .str-chat__channel-search-input--wrapper': {
      h: '40px',
      borderColor: 'onyx.700',
    },

    '& .str-chat__channel-search-input--wrapper-active': {
      borderColor: 'onyx.700',

      '& .str-chat__channel-search-input--icon path, & .str-chat__channel-search-input--clear-button path':
        {
          fill: 'clay.0',
        },
    },

    '.str-chat__channel-search-input--icon path, ': {
      fill: 'clay.0',
    },

    '& .str-chat__channel-search-bar-button--exit-search': {
      px: '10px',
    },

    '& .str-chat__channel-search-input': {
      color: 'white',

      '&::placeholder': {
        color: 'onyx.500',
      },
    },

    '& .str-chat__channel-search-results-header, & .str-chat__channel-search-container-searching':
      {
        px: 8,
        py: 2,
        borderBottom: 0,
      },

    // Define chat width
    '& .str-chat-channel': {
      maxWidth:
        mode === 'user' ? CHAT_WIDTH : { base: '100%', md: '50%', xl: '33%' },
      width: '100%',
    },

    // Remove line in date separator and mke it centered
    '& .str-chat__date-separator': {
      justifyContent: 'center',
      py: '1rem',

      '&-line': {
        display: 'none',
      },
    },

    // Disable reply-in-thread and message-actions
    '& .str-chat__message-reply-in-thread-button, & .str-chat__message-actions-box-button, & .str-chat__channel-preview-messenger--last-message':
      {
        display: 'none !important',
      },

    // Reactions over chat messages
    '& .str-chat__message-reaction': {
      py: '2px !important',
    },

    // Reactions options
    '& .str-chat__message-reactions-options': {
      padding: '4px !important',

      '& .str-chat__message-reactions-option': {
        w: 8,
        h: 8,
        transition: 'background-color 250ms linear 0ms',
      },
    },

    '& .str-chat__message-bubble .str-chat__message-text p': {
      lineHeight: 1.4,
    },

    '& .str-chat__message-bubble .str-chat__message-text a': {
      textDecoration: 'underline',
    },

    // Message input area
    '& .str-chat__message-textarea-container': {
      borderRadius: 20,
      border: '1px solid',
      borderColor: 'onyx.700',
      bg: '#101418',
    },

    '& .str-chat__message-textarea': {
      fontSize: 'sm !important',

      '&::placeholder': {
        color: 'onyx.500',
      },
    },

    '& .str-chat__message-textarea-emoji-picker': {
      height: '22px !important',
    },

    '& .str-chat__file-input-container': {
      h: '40px !important',
    },
  }),
});
