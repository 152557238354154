import { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';

const MAX_DOTS = 3;

export const DotsAnimation = () => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    function setDotsInInterval() {
      setDots(dots.length < MAX_DOTS ? dots + '.' : '');
    }
    const timer = setTimeout(setDotsInInterval, 1000);
    return () => clearTimeout(timer);
  }, [dots]);

  return (
    <Box display="inline-block" w="1ch">
      {dots}
    </Box>
  );
};
