import { collection, getDocs, onSnapshot } from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { ClassSession } from '../../common/types/class';
import { firestore } from '../../firebase';
import { CollectionReference } from '@firebase/firestore-types';

export type ClassesLoadingData = {
  data: null | ClassSession[];
  loading: boolean;
  error: null | string;
};

export const Classes_INITIAL_STATE = {
  data: null,
  loading: false,
  error: null,
};

async function asyncQuery(
  ref: CollectionReference,
  setter: (d: ClassesLoadingData) => void
) {
  // @ts-ignore // TODO
  const docSnap = await getDocs(ref);

  if (!docSnap) {
    setter({
      data: null,
      loading: false,
      error: 'No data for week!',
    });
  }
}

export const useClassesData = () => {
  const [classes, setClasses] = useState<ClassesLoadingData>(
    Classes_INITIAL_STATE
  );
  // const user = useStore((state) => state.user); // TODO Filter by user available classes
  const classesRef = useMemo(() => collection(firestore, 'classes'), []);

  useEffect(() => {
    // @ts-ignore // TODO
    asyncQuery(classesRef, setClasses);
  }, [classesRef]);

  // Initial set of data and subscription for future data updates.
  // initial happens after `asyncQuery` is completed
  useEffect(() => {
    onSnapshot(classesRef, {
      async next(snapshot) {
        if (snapshot) {
          const docs = snapshot.docs;
          const data = docs.map((doc) => doc.data());

          setClasses({
            data: data as ClassSession[],
            loading: false,
            error: null,
          });
        }
      },
      error(error) {
        console.error(error);
      },
    });
  }, [setClasses, classesRef]);

  return classes;
};
