export const getItem = (key: string, defaultValue?: unknown) => {
  try {
    const value = localStorage.getItem(key);

    return value ? JSON.parse(value) : defaultValue;
  } catch (error) {
    console.error('SEND ERROR: ', error);
    return defaultValue;
  }
};

export const setItem = (key: string, value: Record<string, any>) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error('SEND ERROR: ', error);
  }
};

export const removeItem = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error('SEND ERROR: ', error);
  }
};
