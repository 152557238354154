import { Button } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';

interface CoachButtonProps {
  onClick(): void;
  active?: boolean;
}

export const CoachButton: FC<PropsWithChildren<CoachButtonProps>> = ({
  children,
  onClick,
  active,
}) => (
  <Button
    variant="unstyled"
    position="relative"
    w="100%"
    h="206px"
    onClick={onClick}
    borderRadius="8px"
    overflow="hidden"
    boxShadow={active ? '0 0 0 2px #1C1C1E, 0 0 0 4px #A78256' : 'none'}
  >
    {children}
  </Button>
);
