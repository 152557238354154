import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { httpsCallable } from 'firebase/functions';
import { useState } from 'react';
import { functions } from '../../firebase';
import { useSubscriptionContext } from '../model/subscription-provider';

const createStripeSubscription = httpsCallable(
  functions,
  'createStripeSubscription'
);

export const useCard = () => {
  const { stripeData } = useSubscriptionContext();
  const [status, setStatus] = useState<'idle' | 'loading' | 'finished'>('idle');
  const [error, setError] = useState<string>('');
  const stripe = useStripe();
  const elements = useElements();

  const onPayClick = async () => {
    if (!stripe || !elements) return;

    try {
      setStatus('loading');
      setError('');
      const result = await stripe.confirmCardSetup(stripeData!.clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement)!,
        },
      });

      if (result.error) {
        setError(result.error.message as string);
      }

      if (result.setupIntent) {
        await createStripeSubscription({
          paymentMethodId: result.setupIntent?.payment_method,
          subscriptionType: stripeData!.type,
        });
      }
      setStatus('finished');
    } catch (error: any) {
      console.log('ERROR HERE', error);
    }
  };

  return { onPayClick, status, error };
};
