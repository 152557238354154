import { useToast } from '@chakra-ui/react';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { ChangeEvent } from 'react';

import { useSetUserInfoToFirestore, useStore } from '../../common/model';
import { noop } from '../../common/utils/noop';
import { storage } from '../../firebase';

export const useUpdateAvatar = () => {
  const { id } = useStore((state) => state.userInfo)!;
  const { updateUserInfoInFirestore } = useSetUserInfoToFirestore();
  const toast = useToast();

  const onAvatarChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (!target.files?.[0]) return;
    const image = target.files[0];

    const ext = image.type.split('/')[1];

    const imageRef = ref(storage, `avatars/${id}.${ext}`);

    const uploadTask = uploadBytesResumable(imageRef, image, {
      contentType: image.type,
    });

    uploadTask.on(
      'state_changed',
      noop, // we could get snapshot and loading process
      (error) => {
        console.error(error);
        toast({
          description:
            'Ups, avatar change error. Please try again or reload page',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom',
        });
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          updateUserInfoInFirestore(id as string, { avatar: downloadURL }).then(
            () =>
              toast({
                description: 'Avatar has been updated',
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: 'bottom',
              })
          );
        });
      }
    );
  };

  return { onAvatarChange };
};
