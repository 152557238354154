import {
  Box,
  Button,
  Heading,
  ModalBody,
  ModalHeader,
  Text,
  Image,
} from '@chakra-ui/react';
import { FC } from 'react';

import { useCoaches, useTrainingDetails } from '../../common/model';
import {
  CoachButton,
  CoachesGrid,
  FilterButton,
  FilterButtonsGrid,
  FilterContentBlock,
  FilterContentBlockHeader,
} from '../components';
import { useFilters } from '../presenter/use-filters';
import { TRAINING_TIMES } from '../constants';
import { FilterModalView } from '../types';

interface FilterContentProps {
  onClose(): void;
  setView(view: FilterModalView): void;
}

export const FilterContent: FC<FilterContentProps> = ({ onClose, setView }) => {
  const { data: coaches } = useCoaches();
  const trainingDetails = useTrainingDetails();
  const { filters, toggleCoaches, toggleDuration, toggleType, resetFilters } =
    useFilters();

  const onLearnMoreClick = () => setView('training-details');

  return (
    <>
      <ModalHeader>
        <Button
          variant="link"
          justifySelf="start"
          color="clay.0"
          fontWeight="medium"
          fontSize="sm"
          onClick={resetFilters}
        >
          Clear
        </Button>
        <Heading justifySelf="center" as="h3" size="sm" fontWeight="medium">
          Classes
        </Heading>
        <Button
          variant="link"
          onClick={onClose}
          justifySelf="end"
          color="clay.0"
          fontWeight="medium"
          fontSize="sm"
        >
          Close
        </Button>
      </ModalHeader>
      <ModalBody p="0" display="flex" justifyContent="center">
        <Box maxWidth="326px">
          <FilterContentBlock>
            <FilterContentBlockHeader title="Classes">
              <Button
                variant="link"
                onClick={onLearnMoreClick}
                color="clay.0"
                fontSize="sm"
                fontWeight="medium"
              >
                Learn more
              </Button>
            </FilterContentBlockHeader>
            <FilterButtonsGrid>
              {trainingDetails.map(({ name, id }) => (
                <FilterButton
                  onClick={() => toggleType(id)}
                  active={filters.type.includes(id)}
                  key={name}
                >
                  <Text
                    fontSize="xs"
                    fontWeight="medium"
                    textTransform="uppercase"
                    wordBreak="break-word"
                  >
                    {name.split(' ').map((str) => (
                      <span key={str}>
                        {str}
                        <br />
                      </span>
                    ))}
                  </Text>
                </FilterButton>
              ))}
            </FilterButtonsGrid>
          </FilterContentBlock>
          <FilterContentBlock>
            <FilterContentBlockHeader title="Duration" />
            <FilterButtonsGrid>
              {TRAINING_TIMES.map((time, idx) => (
                <FilterButton
                  onClick={() => toggleDuration(time)}
                  key={`${time}-${idx}`}
                  active={filters.duration.includes(time)}
                >
                  <Text fontSize="sm" fontWeight="medium" lineHeight="1">
                    {time}
                  </Text>
                  <Text fontSize="10px" fontWeight="medium">
                    mins
                  </Text>
                </FilterButton>
              ))}
            </FilterButtonsGrid>
          </FilterContentBlock>
          <FilterContentBlock>
            <FilterContentBlockHeader title="Coaches" />
            <CoachesGrid>
              {coaches &&
                coaches.map(({ firstName, imageUrl, id }) => (
                  <CoachButton
                    onClick={() => toggleCoaches(id)}
                    active={filters.coach === id}
                    key={firstName}
                  >
                    <Image
                      src={imageUrl}
                      alt={firstName}
                      objectFit="cover"
                      w="100%"
                      h="100%"
                    />
                    <Text
                      fontWeight="medium"
                      position="absolute"
                      bottom="12px"
                      left="4"
                      zIndex="docked"
                    >
                      {firstName}
                    </Text>
                  </CoachButton>
                ))}
            </CoachesGrid>
          </FilterContentBlock>
        </Box>
      </ModalBody>
    </>
  );
};
