import { useStore } from '../../common/model';

const toggleFilter = <T>(filters: T[], value: T) => {
  if (filters.length === 0) return [value];
  if (filters.length === 1 && filters[0] === value) return [];

  const idx = filters.indexOf(value);

  return idx === -1
    ? filters.concat(value)
    : filters.slice(0, idx).concat(filters.slice(idx + 1));
};

export const useFilters = () => {
  const filters = useStore((state) => state.filters);
  const setCoachesFilter = useStore((state) => state.setCoachesFilter);
  const setDurationFilter = useStore((state) => state.setDurationFilter);
  const setTypeFilter = useStore((state) => state.setTypeFilter);
  const resetFilters = useStore((state) => state.resetFilters);

  const toggleCoaches = (coach: string) => {
    const coachFilter = filters.coach && filters.coach === coach ? '' : coach;

    setCoachesFilter(coachFilter);
  };

  const toggleDuration = (duration: number) =>
    setDurationFilter(toggleFilter(filters.duration, duration));

  const toggleType = (type: string) =>
    setTypeFilter(toggleFilter(filters.type, type));

  return { filters, toggleCoaches, toggleDuration, toggleType, resetFilters };
};
