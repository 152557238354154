import { memo } from 'react';
import { Avatar } from '@chakra-ui/react';
import { useStore } from '../../model';

export const UserAvatar = memo(() => {
  const { avatar, name } = useStore((state) => state.userInfo)!;

  return (
    <Avatar
      bg="onyx.0"
      color="white"
      w="100%"
      h="100%"
      src={avatar}
      name={name}
      fontWeight="semibold"
    />
  );
});
