import { Icon, IconProps } from '@chakra-ui/react';

export const AppleIcon: React.FC<IconProps> = (props) => (
  <Icon fill="none" viewBox="0 0 26 26" display="block" {...props}>
    <g clipPath="url(#a)">
      <path
        d="M8.3 25.03a5.48 5.48 0 0 1-1.28-1.19c-.4-.48-.77-.98-1.1-1.5a14.67 14.67 0 0 1-1.85-3.77 14.13 14.13 0 0 1-.77-4.5c0-1.63.35-3.05 1.04-4.24a5.97 5.97 0 0 1 2.18-2.26c.88-.55 1.9-.85 2.94-.87a5 5 0 0 1 1.17.15c.3.09.66.22 1.1.39.58.22.89.35 1 .38.32.12.6.18.82.18.17 0 .4-.06.67-.14.15-.05.43-.14.84-.32l.96-.36a5.18 5.18 0 0 1 2.28-.32c.7.04 1.37.19 2.01.43a5.42 5.42 0 0 1 2.55 2.03 6.34 6.34 0 0 0-2.03 2.13 5.38 5.38 0 0 0 2.66 7.63 13.61 13.61 0 0 1-1.7 3.54c-.46.65-.8 1.13-1.07 1.46-.42.49-.82.87-1.23 1.13-.44.3-.97.45-1.5.45-.36.02-.73-.03-1.07-.13-.3-.1-.6-.2-.9-.33a4.87 4.87 0 0 0-4.29-.02c-.42.18-.7.3-.87.35-.33.1-.68.16-1.03.18-.54 0-1.04-.15-1.53-.47Zm7.11-19.12c-.7.35-1.38.5-2.04.45-.1-.67 0-1.36.28-2.11a5.64 5.64 0 0 1 2.72-3.04 4.76 4.76 0 0 1 1.96-.57c.08.7 0 1.4-.26 2.14a6.2 6.2 0 0 1-1.04 1.82 5.3 5.3 0 0 1-1.64 1.3h.02Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill="#fff"
          transform="translate(.98 .64)"
          d="M0 0h24.86v24.86H0z"
        />
      </clipPath>
    </defs>
  </Icon>
);
