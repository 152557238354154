import { ChakraProvider } from '@chakra-ui/react';
import { theme } from './theme';

interface ThemeProviderProps {
  children: React.ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => (
  <ChakraProvider theme={theme}>{children}</ChakraProvider>
);
