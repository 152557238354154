import * as Components from './calendar-styled-components';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useMemo, FC } from 'react';
import { Grid, GridItem, Heading, Icon, Text } from '@chakra-ui/react';
import { CalendarDayCellProps } from './calendar-styled-components';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';

// Start from Monday
dayjs.Ls.en.weekStart = 1;

const arrowStyles = {
  cursor: 'pointer',
  color: 'sand.0',
  opacity: 1,
  w: 7,
  h: 12,
  transition: 'opacity 250ms linear 0ms',

  _hover: {
    opacity: 0.63,
  },
};

type DayProps = {
  selectedDate: Dayjs;
  day: Date;
  currentDay: Date;
  getDayData: (day: Date) => Omit<CalendarDayCellProps, 'variant'>;
};

export const Day: FC<DayProps> = ({
  selectedDate,
  day,
  currentDay,
  getDayData,
}) => {
  const variant =
    selectedDate.clone().toDate().getMonth() !== day.getMonth()
      ? 'nextMonth'
      : dayjs(currentDay).isSame(day, 'date')
      ? 'today'
      : dayjs(currentDay).diff(day, 'days') <= 0
      ? 'future'
      : 'default';

  return variant === 'nextMonth' ? null : (
    <Components.CalendarDayCell variant={variant} {...getDayData(day)}>
      {day.getDate()}
    </Components.CalendarDayCell>
  );
};

// TODO any
export const CalendarCore: FC<any> = ({
  getDayData = () => ({}),
  currentCalendarDate,
  setCurrentCalendarDate,
  setSelectedDay,
}) => {
  const currentDay = useMemo(() => dayjs().toDate(), []);

  const firstDayOfTheMonth = useMemo(
    () => currentCalendarDate.clone().startOf('month'),
    [currentCalendarDate]
  );

  const firstDayOfFirstWeekOfMonth = useMemo(
    () => dayjs(firstDayOfTheMonth).startOf('week'),
    [firstDayOfTheMonth]
  );

  const generateFirstDayOfEachWeek = useCallback((day: Dayjs): Dayjs[] => {
    const dates: Dayjs[] = [day];
    for (let i = 1; i < 6; i++) {
      const date = day.clone().add(i, 'week');
      dates.push(date);
    }
    return dates;
  }, []);

  const generateWeek = useCallback((day: Dayjs): Date[] => {
    const dates: Date[] = [];
    for (let i = 0; i < 7; i++) {
      const date = day.clone().add(i, 'day').toDate();
      dates.push(date);
    }
    return dates;
  }, []);

  const generateWeeksOfTheMonth = useMemo((): Date[][] => {
    const firstDayOfEachWeek = generateFirstDayOfEachWeek(
      firstDayOfFirstWeekOfMonth
    );
    return firstDayOfEachWeek.map((date) => generateWeek(date));
  }, [generateFirstDayOfEachWeek, firstDayOfFirstWeekOfMonth, generateWeek]);

  const firstDayOfWeekOfCurrentMonth = useMemo(
    () => generateWeeksOfTheMonth[0].find((d) => d.getDate() === 1),
    [generateWeeksOfTheMonth]
  );

  const onlyWeeksOfCurrentMonth = useMemo(() => {
    return generateWeeksOfTheMonth.filter((week) => {
      const isWeekFullyNextMonth = week.every((wItem) =>
        dayjs(wItem).isSame(
          dayjs(firstDayOfWeekOfCurrentMonth).add(1, 'month'),
          'month'
        )
      );
      return isWeekFullyNextMonth ? false : true;
    });
  }, [firstDayOfWeekOfCurrentMonth, generateWeeksOfTheMonth]);

  const onPreviousMonthClick = () => {
    setSelectedDay(null);
    return setCurrentCalendarDate((date: Dayjs) => date.subtract(1, 'month'));
  };
  const onNextMonthClick = () => {
    setSelectedDay(null);
    return setCurrentCalendarDate((date: Dayjs) => date.add(1, 'month'));
  };

  return (
    <Grid
      templateColumns="repeat(7, 40px)"
      gridTemplateRows={`44px 40px repeat(${onlyWeeksOfCurrentMonth.length}, 40px)`}
      gap="11.67px"
    >
      <GridItem
        colSpan={5}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Heading as="h2" variant="calendar-h2">
          {currentCalendarDate.clone().format('MMMM YYYY')}
        </Heading>
      </GridItem>
      <GridItem display="flex" justifyContent="center" alignItems="center">
        <Icon
          as={IoChevronBack}
          {...arrowStyles}
          onClick={onPreviousMonthClick}
        />
      </GridItem>
      <GridItem display="flex" justifyContent="center" alignItems="center">
        <Icon
          as={IoChevronForward}
          {...arrowStyles}
          onClick={onNextMonthClick}
        />
      </GridItem>
      {generateWeeksOfTheMonth[0].map((day, index) => (
        <GridItem key={`week-day-${index}`}>
          <Text
            color="clay.0"
            textTransform="uppercase"
            fontSize="xs"
            fontWeight="500"
            align="center"
            lineHeight="10"
          >
            {dayjs(day).format('ddd')}
          </Text>
        </GridItem>
      ))}
      {onlyWeeksOfCurrentMonth.map((week, weekIndex) =>
        week.map((day) => (
          <GridItem key={`week-${weekIndex}-${day.toString()}`}>
            <Day
              selectedDate={currentCalendarDate}
              currentDay={currentDay}
              day={day}
              getDayData={getDayData}
            />
          </GridItem>
        ))
      )}
    </Grid>
  );
};
