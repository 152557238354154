import { Box, Portal, useStyleConfig } from '@chakra-ui/react';
import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import { toInlineStyles } from '../utils';

type WithChakraCssProps = {
  style: { [key: string]: any };
  name?: string;
};

/*
 * When you apply styles to chakra component, these styles become a css class, which will be added
 * to the body ONLY if this component is used. SO! When a component rendered outside the chakra like
 * in the Swiper.js for instance, Chakra will not detect usage of css class, so the class will not
 * be added to body, so will not be applied.
 *
 * This HOC creates shadow Box, which applies desired css class and passes it back when it is
 * available to the children as a `className` prop.
 *
 * setInterval is used in order to detect when ref obtains link to the div element
 * */
export const WithChakraClassName: FC<PropsWithChildren<WithChakraCssProps>> = ({
  children,
  style,
  name,
}) => {
  const ref = useRef<HTMLDivElement>();
  const [className, setClassName] = useState('');

  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a
    // typescript error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        // @ts-ignore
        className,
      });
    }
    return child;
  });

  useEffect(() => {
    const int = setInterval(() => {
      if (!className && ref.current?.className) {
        setClassName(ref.current?.className);
        clearInterval(int);
      }
    }, 300);

    return () => clearInterval(int);
  }, [className]);

  if (!WithChakraClassName.displayName && name) {
    WithChakraClassName.displayName = name;
  }

  return (
    <>
      {childrenWithProps}

      <Portal>
        <Box position="absolute" display="none !important;">
          <Box
            // @ts-ignore
            ref={ref}
            __css={style}
          />
        </Box>
      </Portal>
    </>
  );
};

export const WithChakraClassNameWrapper: FC<
  PropsWithChildren<{ styleConfigName: string; variant?: string }>
> = ({ styleConfigName, variant, children, ...props }) => {
  const chakraStyle = useStyleConfig(styleConfigName, {
    variant,
  });
  const style = toInlineStyles(chakraStyle);

  return (
    <WithChakraClassName style={style} {...props}>
      {children}
    </WithChakraClassName>
  );
};
