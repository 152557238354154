import {
  Drawer,
  DrawerContent,
  DrawerCloseButton,
  Hide,
  DrawerHeader,
} from '@chakra-ui/react';
import { Navigate } from 'react-router-dom';
import {
  LoadingIndicator,
  Chat as StreamChat,
  Channel,
  MessageList,
  MessageInput,
  Window,
  ChannelList,
  InfiniteScroll,
} from 'stream-chat-react';
import 'stream-chat-react/dist/css/v2/index.css';
import { ROUTES } from '../../router';

import {
  ChatListPreview,
  ClientProgress,
  ExitSearchIcon,
  SendButton,
} from '../components';
import { ClientProvider } from '../model/ClientProvider';
import { useCoachesChat, useCoachesDrawer } from '../presenter';
import { ChatTheme } from './chat-theme';

const Chat = () => {
  const { client, userId, isCoach } = useCoachesChat();
  const { isListDrawerOpen, onListDrawerClose } = useCoachesDrawer();

  if (!isCoach) return <Navigate to={ROUTES.INBOX} replace />;
  if (!client) return <LoadingIndicator />;

  return (
    <ChatTheme mode="coach">
      <StreamChat client={client}>
        <Hide above="md">
          <Drawer
            isOpen={isListDrawerOpen}
            placement="left"
            onClose={onListDrawerClose}
            size="full"
          >
            <DrawerContent bg="onyx.0">
              <DrawerHeader>
                <DrawerCloseButton />
              </DrawerHeader>
              <ChatTheme mode="coach">
                <ChannelList
                  setActiveChannelOnMount={false}
                  filters={{ members: { $in: [userId!] } }}
                  sort={{ last_message_at: -1 }}
                  showChannelSearch
                  additionalChannelSearchProps={{
                    ExitSearchIcon: ExitSearchIcon,
                  }}
                  Paginator={InfiniteScroll}
                  Preview={ChatListPreview}
                />
              </ChatTheme>
            </DrawerContent>
          </Drawer>
        </Hide>
        <Hide below="md">
          <ChannelList
            filters={{ members: { $in: [userId!] } }}
            sort={{ last_message_at: -1 }}
            showChannelSearch
            additionalChannelSearchProps={{
              ExitSearchIcon: ExitSearchIcon,
            }}
            Paginator={InfiniteScroll}
            Preview={ChatListPreview}
          />
        </Hide>
        <Channel SendButton={SendButton}>
          <Window>
            <MessageList closeReactionSelectorOnClick />
            <MessageInput />
          </Window>
        </Channel>
      </StreamChat>
      <ClientProgress />
    </ChatTheme>
  );
};

export const CoachesChat = () => (
  <ClientProvider>
    <Chat />
  </ClientProvider>
);
