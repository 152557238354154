import { Icon } from '@chakra-ui/react';
import { IoChevronBack } from 'react-icons/io5';
import { ChakraDomLink } from '../../common';
import { ROUTES } from '../../router';

export const BackButton = () => (
  <ChakraDomLink to={ROUTES.PROFILE} fontSize="2xl" display="block">
    <Icon as={IoChevronBack} display="block" />
  </ChakraDomLink>
);
