import { Box, BoxProps } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';

export const SettingsBlock: FC<PropsWithChildren<BoxProps>> = (props) => (
  <Box
    pb="8"
    mb="8"
    borderBottom="1px solid"
    borderColor="stone.0"
    w="100%"
    {...props}
  />
);
