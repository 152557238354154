import { Text } from '@chakra-ui/react';
import { ReactNode, useMemo } from 'react';
import { Property } from 'csstype';
import { StyleProps } from '@chakra-ui/styled-system';

type Type = {
  collapsedText: ReactNode;
  expandedText: ReactNode;
  limit?: number;
  isOpen: boolean;
} & StyleProps;

const defaultProps = {
  minH: { base: '110px', md: '40px' },
};

export const CollapsableText = ({
  collapsedText = '',
  expandedText = '',
  limit = 6,
  isOpen,
  ...props
}: Type) => {
  const limitOptions = useMemo(() => {
    if (limit) {
      return {
        overflow: 'hidden',
        display: '-webkit-box',
        style: isOpen
          ? undefined
          : {
              WebkitLineClamp: limit,
              WebkitBoxOrient: 'vertical' as Property.BoxOrient,
              WebkitTextFillColor: 'transparent',
            },
      };
    }

    return {};
  }, [isOpen, limit]);

  const dynamicProps = useMemo(
    () =>
      isOpen
        ? {}
        : {
            // I like it, but idk
            // mixBlendMode: 'color-dodge' as Property.MixBlendMode,
            background: 'linear-gradient(#eee, transparent)',
            backgroundClip: 'text',
          },
    [isOpen]
  );

  return (
    <Text {...defaultProps} {...limitOptions} {...dynamicProps} {...props}>
      {isOpen ? expandedText : collapsedText}
    </Text>
  );
};
