import { useDiscover } from '../';
import { Button, Image } from '@chakra-ui/react';
import {
  Error,
  Loader,
  SimpleTrailerFooter,
  SwiperStabilizer,
  SwiperVideoFeed,
  useIsDesktop,
} from '../../common';
import { useMemo } from 'react';
import { Property } from 'csstype';

export const Discover = () => {
  const { data, loading, error } = useDiscover();
  const isDesktop = useIsDesktop();

  const dataEnhancedWithTrailerFooter = useMemo(() => {
    if (!data) return [];

    return data.map((discover) => {
      const NO_LANDSCAPE =
        Boolean(discover.video.landscape && discover.video.landscape.h264) ===
        false;
      return {
        ...discover,
        thumbnail: {
          portrait: discover.thumbnail.portrait,
          landscape: NO_LANDSCAPE
            ? discover.thumbnail.portrait
            : discover.thumbnail.landscape,
        },
        trailer: {
          portrait: discover.video.portrait,
          landscape: NO_LANDSCAPE
            ? discover.video.portrait
            : discover.video.landscape,
        },
        videoStyles: NO_LANDSCAPE
          ? isDesktop
            ? {
                objectFit: 'contain' as Property.ObjectFit,
                position: 'relative' as Property.Position,
                zIndex: 1,
              }
            : undefined
          : undefined,
        children: (
          <>
            {NO_LANDSCAPE && isDesktop ? (
              <Image
                src={discover.thumbnail.portrait}
                fit="cover"
                opacity="0.2"
                filter="blur(20px)"
                pos="absolute"
                left="0"
                top="0"
                width="100%"
                zIndex={0}
              />
            ) : null}
            <SimpleTrailerFooter
              title={discover.name}
              description={{
                short: discover.description,
                long: discover.description,
              }}
              cta={
                <Button
                  variant="hoa-primary"
                  as="a"
                  href={discover.button.action}
                  target="_blank"
                  w="100%"
                >
                  {discover.button.text}
                </Button>
              }
            />
          </>
        ),
      };
    });
  }, [data, isDesktop]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <SwiperStabilizer>
      {!dataEnhancedWithTrailerFooter.length ? null : (
        <SwiperVideoFeed data={dataEnhancedWithTrailerFooter} />
      )}
    </SwiperStabilizer>
  );
};
