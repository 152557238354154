import { StyleFunctionProps } from '@chakra-ui/react';

export const BUTTON_CONFIG = {
  sizes: {
    md: {
      h: '48px',
      px: '32px',
    },
    lg: {
      h: '52px',
      px: '32px',
    },
  },
  variants: {
    'hoa-primary': {
      backgroundColor: 'clay.0',
      fontSize: 'md',
      fontWeight: 'normal',
      borderRadius: '24px',
      color: 'white',

      _hover: {
        backgroundColor: 'clay.600',
      },
    },
    'hoa-primary-white': {
      backgroundColor: 'transparent',
      fontSize: 'md',
      fontWeight: '500',
      borderRadius: '20px',
      color: 'white',
      border: '2px solid white',
      height: '37px',
      px: 6,

      _hover: {
        backgroundColor: 'stone.50',
        color: 'black',
      },
    },
    'hoa-icon': ({ size }: StyleFunctionProps) => ({
      backgroundColor: 'transparent',
      px: 0,
      w: size,
      h: size,
      color: 'stone.600',
      fontSize: '2xl',

      _hover: {
        color: 'stone.900',
      },
    }),
    outline: {
      borderColor: 'stone.100',
    },
    'hoa-transparent': {
      background: 'none',
      border: 'none',
      height: 'auto',
      padding: 1,
      fontSize: 'md',
    },
  },
};
