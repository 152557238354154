import { Box, VStack, Flex } from '@chakra-ui/react';
import { RefObject, useRef, useState } from 'react';
import {
  START_SCREEN_VIDEO_H264,
  START_SCREEN_VIDEO_H265,
  BackgroundVideo,
  Logo,
  isH265Supported,
} from '../../common';

import { StartTrainingDetails } from './start-training-details';
import { StartInfo } from './start-info';
import { useTrainingDetails } from '../../common/model';

export const StartScreen = () => {
  const containerRef = useRef() as RefObject<HTMLDivElement>;
  const [view, setView] = useState<'start' | 'training-details'>('start');
  const onSetStartView = () => setView('start');
  const onSetTrainingDetails = () => {
    setView('training-details');
    containerRef!.current!.scrollTop = 0;
  };
  const trainingDetails = useTrainingDetails();

  return (
    <Flex
      as="section"
      h="100%"
      w="100%"
      justifyContent={{ base: 'center' }}
      position="relative"
      overflowY="auto"
      ref={containerRef}
    >
      <BackgroundVideo
        url={
          isH265Supported ? START_SCREEN_VIDEO_H265 : START_SCREEN_VIDEO_H264
        }
      />
      <Logo
        color="white"
        w="7rem"
        h="auto"
        position="fixed"
        top="12"
        left="50%"
        transform="translateX(-50%)"
        zIndex="3"
      />
      <Box
        mt={{ base: '12rem', md: '18rem' }}
        w="100%"
        maxW="32rem"
        zIndex="docked"
      >
        <VStack
          spacing="12"
          p="3rem 1rem"
          bgColor="card"
          borderRadius="20px 20px 0 0"
        >
          {view === 'start' ? (
            <StartInfo onSetTrainingDetails={onSetTrainingDetails} />
          ) : (
            <StartTrainingDetails
              onSetStartView={onSetStartView}
              trainingDetails={trainingDetails}
            />
          )}
        </VStack>
      </Box>
    </Flex>
  );
};
