import { Box, Spinner } from '@chakra-ui/react';
import { useLocation, Navigate } from 'react-router-dom';
import { REGISTER_FLOW } from '../../auth';
import { isCoach } from '../../common';
import { useStore } from '../../common/model';

import * as ROUTES from '../../router/routes';
import { useConnectChat } from '../presenter/useConnectChat';
import { DummyPage, InternalLayout } from '../view';

export const PrivateRoutesNavigator = () => {
  const userInfo = useStore((state) => state.userInfo);
  const flow = useStore((state) => state.auth.flow);
  const roles = useStore((state) => state.roles);

  const { pathname } = useLocation();

  const isCoachesRoutes = ROUTES.COACHES_ROUTES.includes(pathname);

  const isOnboardingRoutes = ROUTES.ONBOARDING_ROUTES.includes(pathname);
  const isPaymentRoute = pathname === ROUTES.PAYMENT;

  useConnectChat();

  if (!userInfo || flow === REGISTER_FLOW) {
    return (
      <Box w="100%" h="100%">
        <DummyPage>
          <Box
            fontSize="xl"
            color="white"
            display="flex"
            alignItems="center"
            mt="4"
          >
            Loading <Spinner ml="4" />
          </Box>
        </DummyPage>
      </Box>
    );
  }

  if (isCoach(roles) && !isCoachesRoutes) {
    return <Navigate to={ROUTES.COACHES_CHAT} replace />;
  }

  const { onboarding, subscription } = userInfo;

  // If the User not completed onboarding force to do it
  if (!isCoach(roles) && !onboarding && !isOnboardingRoutes) {
    return <Navigate to={ROUTES.ONBOARDING} replace />;
  }

  // If the User completed onboarding but not paid force to do it
  if (
    !isCoach(roles) &&
    onboarding &&
    subscription!.status !== 'active' &&
    !isPaymentRoute
  ) {
    return <Navigate to={ROUTES.PAYMENT} replace />;
  }

  // If user completed onboarding and paid -> redirect him to main
  if (
    !isCoach(roles) &&
    onboarding &&
    subscription!.status === 'active' &&
    (isOnboardingRoutes || isPaymentRoute)
  ) {
    return <Navigate to={ROUTES.ROOT} replace />;
  }

  return <InternalLayout />;
};
