import { Channel, DefaultGenerics, StreamChat } from 'stream-chat';
import { produce } from 'immer';
import { StateCreator } from 'zustand';

interface ChatSliceData {
  channel: Channel<DefaultGenerics> | null;
  client: StreamChat<DefaultGenerics> | null;
  noChatAvailable: boolean;
  isListDrawerOpen: boolean;
  isProgressDrawerOpen: boolean;
  totalUnreadCount: number;
}

export interface ChatSlice {
  chat: ChatSliceData;
  setChatData(channel: Partial<ChatSliceData>): void;
}

export const chatSlice: StateCreator<ChatSlice, [], [], ChatSlice> = (set) => ({
  chat: {
    channel: null,
    client: null,
    noChatAvailable: false,
    isListDrawerOpen: false,
    isProgressDrawerOpen: false,
    totalUnreadCount: 0,
  },
  setChatData(data) {
    set(
      produce((state) => {
        state.chat = { ...state.chat, ...data };
      })
    );
  },
});
