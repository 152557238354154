import { Icon, IconProps } from '@chakra-ui/react';
import { FC } from 'react';

export const EquipmentIcon: FC<IconProps> = (props) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 25"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8.25 11.5h7.5M6.75 17.5a1.5 1.5 0 0 1-1.5-1.5V7a1.5 1.5 0 0 1 3 0v9a1.5 1.5 0 0 1-1.5 1.5ZM3.75 8.5a1.5 1.5 0 0 0-1.5 1.5v3a1.5 1.5 0 0 0 3 0v-3a1.5 1.5 0 0 0-1.5-1.5ZM17.25 17.5a1.5 1.5 0 0 0 1.5-1.5V7a1.5 1.5 0 1 0-3 0v9a1.5 1.5 0 0 0 1.5 1.5ZM20.25 8.5a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 1 1-3 0v-3a1.5 1.5 0 0 1 1.5-1.5Z"
    />
  </Icon>
);
