import { getPaginationItems, isDayCompletedType } from './get-pagination-items';

import './pagination-styles.css';
import {
  BULLET_ACTIVE_CLASS,
  BULLET_CLASS,
  VERTICAL_CLASS,
} from '../../constants/pagination';
import { PaginationOptions } from 'swiper/types/modules/pagination';
import { SingleRow } from '../../view/home-page';

export const getPaginationParams = (
  data: SingleRow,
  isDayCompleted: isDayCompletedType
) => ({
  type: 'custom' as PaginationOptions['type'],
  clickable: false,
  verticalClass: VERTICAL_CLASS,
  bulletClass: BULLET_CLASS,
  bulletActiveClass: BULLET_ACTIVE_CLASS,
  renderCustom: getPaginationItems(data, isDayCompleted),
});
