import { createBrowserRouter } from 'react-router-dom';

import { Chat, CoachesChat } from '../../chat';
import { Schedule } from '../../schedule';
import * as ROUTES from '../routes';
import { Payment, SubscriptionProvider } from '../../subscription';
import { PrivateRoutesNavigator } from '../../internal';
import { onboardingRoutes } from './onboarding-routes';
import { ClassesCoachesContainer, GlobalError } from '../../common';
import { Coach, CoachList } from '../../coach';
import {
  Training,
  TrainingList,
  PostTrainingStatistics,
  PostTrainingFeedback,
  PostTrainingShare,
} from '../../train';
import {
  EditProfilePage,
  ProfilePage,
  ProfileRoot,
  ProfileSettings,
} from '../../profile';
import { HomePage } from '../../home/view/home-page';
import { OnboardingContainer } from '../../onboarding';
import { BookSession } from '../../book-session';
import { Discover } from '../../tribe';
import { commonRoutes } from './common-routes';

// Onboarding and Payment checks and redirects are inside PrivateRoutesNavigator
export const privateRouter = createBrowserRouter([
  {
    path: ROUTES.ROOT,
    element: <PrivateRoutesNavigator />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      ...commonRoutes,
      onboardingRoutes(true),
      {
        path: ROUTES.PAYMENT,
        element: (
          <SubscriptionProvider>
            <OnboardingContainer />
          </SubscriptionProvider>
        ),
        children: [
          {
            index: true,
            element: <Payment />,
          },
        ],
      },
      {
        path: ROUTES.SCHEDULE,
        element: <Schedule />,
      },
      {
        path: ROUTES.INBOX,
        element: <Chat />,
      },
      {
        path: ROUTES.TRIBE,
        element: <Discover />,
      },
      {
        path: ROUTES.PROFILE,
        element: <ProfileRoot />,
        children: [
          {
            index: true,
            element: <ProfilePage />,
          },
          {
            path: ROUTES.EDIT_PROFILE,
            element: <EditProfilePage />,
          },
          {
            path: ROUTES.PROFILE_SETTINGS,
            element: <ProfileSettings />,
          },
        ],
      },
      {
        path: ROUTES.COACHES,
        element: <ClassesCoachesContainer />,
        children: [
          {
            index: true,
            element: <CoachList />,
          },
          {
            path: ROUTES.EXACT_COACHES,
            element: <Coach />,
          },
        ],
      },
      {
        path: ROUTES.TRAIN,
        element: <ClassesCoachesContainer />,
        children: [
          {
            index: true,
            element: <TrainingList />,
          },
          {
            path: ROUTES.EXACT_TRAINING,
            element: <Training />,
          },
          {
            path: `${ROUTES.EXACT_TRAINING}/${ROUTES.POST_TRAINING_FEEDBACK}`,
            element: <PostTrainingFeedback />,
          },
          {
            path: `${ROUTES.EXACT_TRAINING}/${ROUTES.POST_TRAINING_STATISTICS}`,
            element: <PostTrainingStatistics />,
          },
          {
            path: `${ROUTES.EXACT_TRAINING}/${ROUTES.POST_TRAINING_SHARE}`,
            element: <PostTrainingShare />,
          },
        ],
      },
      {
        path: ROUTES.BOOK_SESSION,
        element: <BookSession />,
      },
      {
        path: ROUTES.COACHES_CHAT,
        element: <CoachesChat />,
      },
    ],
    errorElement: <GlobalError />,
  },
]);
