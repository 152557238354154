// import { theme } from '../../theme/model/theme';

export const toInlineStyles = (stylesObject: { [key: string]: any }) => {
  // decompile shorts
  const { w, h, ...otherStyles } = stylesObject;

  return {
    ...(h !== undefined ? { height: stylesObject.h } : {}),
    ...(w !== undefined ? { width: stylesObject.w } : {}),
    ...otherStyles,
  };
};
