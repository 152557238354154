import { Button, Avatar, Flex, Box } from '@chakra-ui/react';
import { FC } from 'react';
import { ChannelPreviewUIComponentProps } from 'stream-chat-react';
import { useClientContext } from '../model/ClientProvider';
import { useCoachesDrawer } from '../presenter/use-coaches-drawer';

export const ChatListPreview: FC<ChannelPreviewUIComponentProps> = ({
  channel,
  displayImage,
  displayTitle,
  setActiveChannel,
  unread,
  watchers,
}) => {
  const { onListDrawerClose } = useCoachesDrawer();
  const { setClientId } = useClientContext();

  const onSelect = () => {
    if (setActiveChannel) {
      setClientId(channel?.id?.split('-')[1] as string);
      setActiveChannel(channel, watchers);
    }
    onListDrawerClose();
  };

  return (
    <Button
      variant="unstyled"
      onClick={onSelect}
      display="flex"
      color="white"
      w="100%"
      mb="2"
      px="1.5rem !important"
      fontWeight="light"
      h="auto"
      py="0.5rem !important"
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex alignItems="center">
        <Avatar
          bg="sand.0"
          color="onyx.0"
          w="1.5rem"
          h="1.5rem"
          src={displayImage}
          name={displayTitle}
          fontSize="1rem"
          size="sm"
          mr="4"
        />
        {displayTitle}
      </Flex>
      {!!unread && (
        <Box
          w="1.25rem"
          h="1.25rem"
          borderRadius="50%"
          bgColor="sand.0"
          fontSize="xs"
          lineHeight="1.25rem"
        >
          {unread}
        </Box>
      )}
    </Button>
  );
};
