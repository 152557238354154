import {
  Flex,
  Input,
  chakra,
  VStack,
  Button,
  InputGroup,
  InputRightElement,
  Text,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  useDisclosure,
  GridItem,
  Select,
} from '@chakra-ui/react';
import { IoCalendarOutline } from 'react-icons/io5';
import { DatePicker } from '../../common';
import {
  ProfileAvatar,
  ProfileHeading,
  ProfilePageContainer,
  ProfilePageHeader,
  BackButton,
} from '../components';
import { FOOTS, INCHES } from '../constants';
import { useEditProfile, useUpdateAvatar } from '../presenter';

export const EditProfilePage = () => {
  const {
    userInfoName,
    register,
    onSaveClick,
    submitButtonIsDisabled,
    handleKeyDown,
    errors,
    setValue,
    dateOfBirth,
  } = useEditProfile();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { onAvatarChange } = useUpdateAvatar();

  const setDateOfBirth = (value: string) =>
    setValue('dateOfBirth', value, { shouldDirty: true });

  return (
    <>
      <GridItem>
        <ProfilePageHeader title="Edit Profile" LeftButton={<BackButton />} />
      </GridItem>
      <GridItem>
        <ProfilePageContainer>
          <Flex flexDir="column" alignItems="center" w="100%">
            <chakra.label cursor="pointer">
              <ProfileAvatar mode="edit" />
              <input
                type="file"
                hidden
                accept="image/png, image/gif, image/jpeg, image/jpg"
                onChange={onAvatarChange}
              />
            </chakra.label>
            <ProfileHeading name={userInfoName as string} />
            <VStack w="100%" spacing="3" alignItems="flex-start" mb="4">
              <Input
                isInvalid={!!errors.name}
                variant="hoa-filled"
                placeholder="Enter your name"
                size="md"
                {...register('name')}
              />
              {!!errors.name && (
                <Text fontSize="xs" color="red.600">
                  {errors.name.message}
                </Text>
              )}
            </VStack>
            <Flex w="100%" mb="4" alignItems="flex-start">
              <VStack spacing="3" alignItems="flex-start" w="100%">
                <InputGroup>
                  <Select
                    placeholder=" "
                    size="lg"
                    variant="hoa-select"
                    {...register('foots')}
                  >
                    {FOOTS.map((height) => (
                      <option value={height} key={`${height}-foot`}>
                        {height}
                      </option>
                    ))}
                  </Select>
                  <InputRightElement color="onyx.400">ft</InputRightElement>
                </InputGroup>
                {!!errors.foots && (
                  <Text fontSize="xs" color="red.600">
                    {errors.foots.message}
                  </Text>
                )}
              </VStack>
              <InputGroup ml="16px">
                <Select
                  placeholder=" "
                  size="lg"
                  variant="hoa-select"
                  {...register('inches')}
                >
                  {INCHES.map((height) => (
                    <option value={height} key={`${height}-inches`}>
                      {height}
                    </option>
                  ))}
                </Select>
                <InputRightElement color="onyx.400">in</InputRightElement>
              </InputGroup>
            </Flex>
            <InputGroup mb="4">
              <Input
                variant="hoa-filled"
                width="100%"
                placeholder="Enter your weight"
                type="number"
                {...register('weight')}
                onKeyDown={handleKeyDown}
              />
              <InputRightElement color="onyx.400">lbs</InputRightElement>
            </InputGroup>
            <InputGroup mb="4">
              <Popover onOpen={onOpen} onClose={onClose} isOpen={isOpen}>
                <PopoverTrigger>
                  <Button variant="unstyled" w="100%">
                    <Input
                      variant="hoa-filled"
                      width="100%"
                      disabled
                      placeholder="DD/MM/YYYY"
                      {...register('dateOfBirth')}
                      onKeyDown={handleKeyDown}
                      _disabled={{
                        opacity: 1,
                        cursor: 'pointer',
                      }}
                    />
                    <InputRightElement color="onyx.400">
                      <Icon as={IoCalendarOutline} />
                    </InputRightElement>
                  </Button>
                </PopoverTrigger>
                <PopoverContent bg="card" w="382px" p="4">
                  <PopoverArrow bg="card" />
                  <DatePicker
                    onClose={onClose}
                    onChange={setDateOfBirth}
                    selectedDate={dateOfBirth}
                  />
                </PopoverContent>
              </Popover>
            </InputGroup>
          </Flex>
          <VStack spacing="6" w="100%">
            <Button
              variant="hoa-primary"
              onClick={onSaveClick}
              w="100%"
              disabled={submitButtonIsDisabled}
            >
              Save
            </Button>
          </VStack>
        </ProfilePageContainer>
      </GridItem>
    </>
  );
};
