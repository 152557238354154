import { Button, VStack, Text, Heading, Box } from '@chakra-ui/react';
import { noop } from '../../common/utils/noop';
import { NUMBER_OF_STEPS } from '../constants';

interface StepWrapperProps {
  title: string;
  text: string;
  buttonText: string;
  buttonType?: 'button' | 'submit';
  children: React.ReactNode;
  onClick?(): void;
  step?: number;
}

export const StepWrapper: React.FC<StepWrapperProps> = ({
  title,
  text,
  buttonText,
  buttonType = 'button',
  children,
  onClick = noop,
  step,
}) => (
  <VStack spacing="6" alignItems="center">
    <Box textAlign="center">
      <Heading mb="4" fontSize="2xl" fontWeight="semibold">
        {title}
      </Heading>
      <Text fontSize="xl" color="onyx.400">
        {text}
      </Text>
    </Box>
    {children}
    <Button
      variant="hoa-primary"
      type={buttonType}
      alignSelf="flex-end"
      onClick={onClick}
      w="100%"
    >
      {buttonText}
    </Button>
    {step && (
      <Box textAlign="center">
        <Text fontSize="sm" color="stone.400">
          Question
        </Text>
        <Text fontSize="sm">
          {step}&nbsp;/&nbsp;{NUMBER_OF_STEPS}
        </Text>
      </Box>
    )}
  </VStack>
);
