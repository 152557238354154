import { ClassHistory } from '../types/class-history';
import { getPercentOfElapsedTime } from '../../train/utils';
import { WHEN_COMPLETE } from '../constants';

export const isDayCompleted = (classHistoryRecord: ClassHistory) => {
  const totalClassDurationInSeconds =
    parseInt(classHistoryRecord.class.duration) * 60;
  return (
    getPercentOfElapsedTime(
      classHistoryRecord.activeTime,
      totalClassDurationInSeconds
    ) >= WHEN_COMPLETE
  );
};
