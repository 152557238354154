import { Icon, IconProps } from '@chakra-ui/react';
import { FC } from 'react';

export const BookSessionIcon: FC<IconProps> = (props) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <g stroke="currentColor" strokeWidth="2.2" clipPath="url(#a)">
      <path d="M6.67 9.83a.5.5 0 1 1 0-1M6.67 9.83a.5.5 0 1 0 0-1M12 9.83a.5.5 0 0 1 0-1M12 9.83a.5.5 0 0 0 0-1M6.67 14.5a.5.5 0 0 1 0-1M6.67 14.5a.5.5 0 0 0 0-1M6.67 19.17a.5.5 0 0 1 0-1M6.67 19.17a.5.5 0 0 0 0-1M12 14.5a.5.5 0 0 1 0-1M12 14.5a.5.5 0 0 0 0-1M17.33 9.83a.5.5 0 0 1 0-1M17.33 9.83a.5.5 0 0 0 0-1" />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11 23H3a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h18a2 2 0 0 1 2 2v6M7 5V1M17 5V1"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M23 31a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M26.53 23H23v-3.53"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </Icon>
);
