import { CAROUSEL_1, CAROUSEL_2, CAROUSEL_3, CAROUSEL_4 } from '../common';

export const ONBOARDING_STORAGE_KEY = 'onboarding_data';
export const NUMBER_OF_STEPS = 5;

export const FAQ_CONTENT = [
  {
    question: 'How much does an HOA+ membership cost?',
    answer:
      'Your HOA+ subscription will renew monthly. For $39.99/month you will have access to daily classes in the app, as well as access to a personal coach to keep you on track with your goals. For $149.99/month you can enjoy all-access which includes daily classes, exclusive content, access to a personal coach, as well as live 1:1 personal training every week.',
  },
  {
    question: 'How does the 7-day free trial work?',
    answer:
      'We understand that a monthly subscription is a commitment, but we believe you will fall in love with HOA+ and our prescriptive fitness methodology. If not, you can cancel your subscription within 7 days of initial purchase. Contact customer support for a full refund.',
  },
  {
    question: 'What types of daily classes will be offered?',
    answer:
      'Every day and every workout has a purpose. Our prescriptive fitness philosophy will serve the holistic athlete with modalities for each muscle group and include styles like Yoga, HIIT, and Foundational Strength & Conditioning. Our workouts also include Recovery, Mental Fitness and Nutrition. We believe that being an athlete isn’t just about your physical ability or competitive performance – but also about being consistent and committed to a routine to help optimize performance in every aspect.',
  },
  {
    question: 'How does HOA+ coaching work?',
    answer:
      'We’ve assembled a legendary lineup of coaches who you will fall in love with! Our HOA+ coaches will serve your daily workouts with a dose of inspiration and raw vulnerability. You will get the realest version of our coaches, to help unlock the realest version of yourself. In addition, you will have access to your own personal coach who you can chat with anytime. Should you want to take your training to the next level, you can opt in for 1:1 personal training live from the app.',
  },
];

export const INFO_SLIDES = [
  {
    image: CAROUSEL_1,
    title: 'Daily Classes',
    description: 'Prescriptive workouts for anybody, anytime, anywhere.',
  },
  {
    image: CAROUSEL_2,
    title: 'Expert Coaching',
    description:
      'Our roster of rockstar coaches will guide your fitness experience in class or 1:1.',
  },
  {
    image: CAROUSEL_3,
    title: 'Personalized Communication',
    description: 'Chat with your coach and your tribe from anywhere. ',
  },
  {
    image: CAROUSEL_4,
    title: 'Tribe',
    description: 'Invite your friends to sign up and train with your Tribe.',
  },
];
