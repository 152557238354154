import { Flex, Heading } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

interface ProfilePageHeaderProps {
  LeftButton?: ReactNode;
  RightButton?: ReactNode;
  title?: string;
}

export const ProfilePageHeader: FC<ProfilePageHeaderProps> = ({
  LeftButton = null,
  RightButton = null,
  title = '',
}) => (
  <Flex width="100%" p="12" justifyContent="space-between" alignItems="center">
    <Flex w="16">{LeftButton}</Flex>
    <Heading fontSize="2xl" fontWeight="normal">
      {title}
    </Heading>
    <Flex w="16" justifyContent="flex-end">
      {RightButton}
    </Flex>
  </Flex>
);
