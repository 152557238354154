import { Button, Center } from '@chakra-ui/react';

export const GlobalError = () => {
  return (
    <Center h="100%">
      <Button as="a" href="/">
        Sorry, but it seems we also got tired after this amount of reps...
      </Button>
    </Center>
  );
};
