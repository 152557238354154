import { StateCreator } from 'zustand';
import { produce } from 'immer';
import { ClassSession } from '../../types/class';

export interface Feedback {
  id: string;
  classId: string;
  timezone: string;
  startedAt: number;
  endedAt: number;
  activeTime: number; //seconds
  survey?: {
    like: number;
    overall: number;
    coach: number;
    music: number;
    stream: number;
    difficulty: number;
  };
  comments?: string;
}

export interface ClassSliceData {
  currentClassData: null | ClassSession;
  feedback: null | Feedback;
}

export interface ClassSlice {
  class: ClassSliceData;
  setClass(clas: ClassSession): void;
  setFeedback(feedback: Feedback): void;
}

const INITIAL_DATA = {
  currentClassData: null,
  feedback: null,
};

export const classSlice: StateCreator<ClassSlice, [], [], ClassSlice> = (
  set
) => ({
  class: INITIAL_DATA,
  setClass(clas: ClassSession) {
    set(
      produce((state) => {
        state.class.currentClassData = clas;
      })
    );
  },
  setFeedback(feedback: Feedback) {
    set(
      produce((state) => {
        state.class.feedback = feedback;
      })
    );
  },
});
