import { Icon, IconProps } from '@chakra-ui/react';

export const Logo: React.FC<IconProps> = (props) => (
  <Icon fill="none" viewBox="0 0 359 110" display="block" {...props}>
    <path
      d="M136.32 110c-23.97 0-43.47-20.67-43.47-46.08v-1.34h15.2v1.34c0 16.53 12.68 29.97 28.27 29.97 15.6 0 28.28-13.44 28.28-29.97s-12.68-29.98-28.28-29.98h-1.26v-16.1h1.26c23.97 0 43.47 20.67 43.47 46.08 0 25.4-19.5 46.08-43.47 46.08Z"
      fill="currentColor"
    />
    <path
      d="M218.62 110c-23.97 0-43.47-20.67-43.47-46.08 0-25.41 19.5-46.08 43.47-46.08 16.63 0 31.53 9.95 38.83 25.35V23.21h15.2v84.55h-15.2v-42.5H246.9v-1.34c0-16.53-12.68-29.98-28.28-29.98-15.59 0-28.27 13.45-28.27 29.98s12.68 29.97 28.27 29.97h1.27V110h-1.27ZM82.3 107.76V63.92c0-16.53-12.68-29.98-28.28-29.98-15.59 0-28.27 13.45-28.27 29.98v1.34H15.19v42.5H0V0h15.2v43.2c7.3-15.41 22.2-25.36 38.82-25.36 11.44 0 22.25 4.69 30.43 13.2A47.11 47.11 0 0 1 97.5 63.92v43.84H82.3ZM310.26 72.22h-20.5V58.75h20.5c4.25 0 7.7-3.63 7.7-8.08V29.52h12.84v21.15c0 11.88-9.21 21.55-20.54 21.55Z"
      fill="currentColor"
    />
    <path
      d="M330.8 101.45h-12.84V80.3c0-11.88 9.22-21.55 20.55-21.55H359v13.47h-20.5c-4.24 0-7.7 3.62-7.7 8.08v21.15Z"
      fill="currentColor"
    />
  </Icon>
);
