// import { Box } from '@chakra-ui/react';
import {
  LoadingIndicator,
  Chat as StreamChat,
  Channel,
  MessageList,
  MessageInput,
  Window,
} from 'stream-chat-react';
import { useStreamChat } from '../presenter/use-stream-chat';

import 'stream-chat-react/dist/css/v2/index.css';
import { SendButton } from '../components';
// import { CHAT_WIDTH } from '../constants';
import { ChatTheme } from './chat-theme';
import { DummyPage } from '../../internal';
import { Center } from '@chakra-ui/react';

// Return this Box to Chat Theme and make logic for current coach
/*
<Box
  paddingTop="8"
  w="100%"
  paddingBottom="4"
  maxW={CHAT_WIDTH}
  px="24px"
>
  <CoachAvatar />
</Box>
*/

export const Chat = () => {
  const { client, channel, noChatAvailable } = useStreamChat();

  if (noChatAvailable && (!client || !channel))
    return <DummyPage>No chat available. Please contact support</DummyPage>;

  if (!client || !channel)
    return (
      <Center h="100%">
        <LoadingIndicator />
      </Center>
    );

  return (
    <ChatTheme mode="user">
      <StreamChat client={client}>
        <Channel channel={channel} SendButton={SendButton}>
          <Window>
            <MessageList closeReactionSelectorOnClick />
            <MessageInput />
          </Window>
        </Channel>
      </StreamChat>
    </ChatTheme>
  );
};
