import { SubscriptionType } from './types';

export const COACHING: SubscriptionType = {
  type: 'coaching',
  price: 39.99,
};

export const PERSONAL_TRAINING: SubscriptionType = {
  type: 'personalTraining',
  price: 149.99,
};
