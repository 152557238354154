import { Box } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';
import { NAVIGATION_WIDTH } from '../../../internal/constants';

export const SwiperStabilizer: FC<PropsWithChildren> = ({ children }) => (
  <Box
    top="0"
    bottom={{ base: `${NAVIGATION_WIDTH}px`, md: 0 }}
    right="0"
    left={{ base: 0, md: `${NAVIGATION_WIDTH}px` }}
    position="absolute"
  >
    {children}
  </Box>
);
