import { doc, DocumentReference, getDoc, onSnapshot } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { useEffect, useMemo, useState } from 'react';
import { Coach } from '../../common/types/coach';

export type CoachLoadingData = {
  data: null | Coach;
  loading: boolean;
  error: null | string;
};

export const COACH_INITIAL_STATE = {
  data: null,
  loading: false,
  error: null,
};

async function asyncQuery(
  ref: DocumentReference,
  setter: (d: CoachLoadingData) => void
) {
  const docSnap = await getDoc(ref);

  if (!docSnap.exists()) {
    setter({
      data: null,
      loading: false,
      error: 'No data for week!',
    });
  }
}

export const useCoachData = (coachId?: string) => {
  const [coach, setCoach] = useState<CoachLoadingData>(COACH_INITIAL_STATE);
  const coachRef = useMemo(
    () => (coachId ? doc(firestore, 'coaches', coachId) : null),
    [coachId]
  );

  useEffect(() => {
    if (!coachRef) return;
    asyncQuery(coachRef, setCoach);
  }, [coachRef]);

  // Initial set of data and subscription for future data updates.
  // initial happens after `asyncQuery` is completed
  useEffect(() => {
    if (!coachRef) return;
    onSnapshot(coachRef, {
      next(snapshot) {
        if (snapshot.exists()) {
          setCoach({
            data: snapshot.data() as Coach,
            loading: false,
            error: null,
          });
        }
      },
      error(error) {
        console.error(error);
      },
    });
  }, [setCoach, coachRef]);

  return coach;
};
