import { FC, PropsWithChildren } from 'react';
import { TextProps } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';

export const Name: FC<PropsWithChildren<TextProps>> = ({
  children,
  ...props
}) => (
  <Text
    fontSize={{ base: 'xs', md: 'md' }}
    color="stone.0"
    fontWeight="500"
    mb="-10px"
    casing="uppercase"
    {...props}
  >
    {children}
  </Text>
);
