import { defineStyleConfig } from '@chakra-ui/react';

export const CHAKRA_SWIPER_CONFIG = defineStyleConfig({
  baseStyle: {
    w: '100%',
    h: '100%',
  },
  // The default variant value
  defaultProps: {
    variant: 'default',
  },
});
