import { Flex, Text } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';

interface FilterContentBlockHeaderProp {
  title: string;
}

export const FilterContentBlockHeader: FC<
  PropsWithChildren<FilterContentBlockHeaderProp>
> = ({ children, title }) => {
  return (
    <Flex as="header" alignItems="center" justifyContent="space-between" mb="4">
      <Text fontWeight="medium">{title}</Text>
      {children}
    </Flex>
  );
};
