import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { FC, useState } from 'react';
import { useTrainingDetails } from '../../common/model';
import { FilterModalView } from '../types';
import { FilterContent } from './filter-content';
import { TrainingDetails } from './training-details';

interface FilterModalProps {
  isOpen: boolean;
  onClose(): void;
}

export const FilterModal: FC<FilterModalProps> = ({ isOpen, onClose }) => {
  const [view, setView] = useState<FilterModalView>('filters');

  const onChangeView = (view: FilterModalView) => setView(view);
  const onCloseClick = () => setView('filters');
  const trainingDetails = useTrainingDetails();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      motionPreset="slideInBottom"
      scrollBehavior="inside"
      size="lg"
      variant="hoa-bottom-modal"
    >
      <ModalOverlay />
      <ModalContent>
        {view === 'filters' && (
          <FilterContent onClose={onClose} setView={onChangeView} />
        )}
        {view === 'training-details' && (
          <TrainingDetails
            onCloseClick={onCloseClick}
            trainingDetails={trainingDetails}
          />
        )}
      </ModalContent>
    </Modal>
  );
};
