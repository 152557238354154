import { useMemo } from 'react';
import { ClassSession } from '../../common/types/class';

export const useTrainingOgMetaData = (training?: ClassSession) => {
  const ogMetaData = useMemo(() => {
    if (!training) return null;
    const NO_LANDSCAPE = Boolean(training.thumbnail?.landscape) === false;
    return {
      url: NO_LANDSCAPE
        ? training.thumbnail?.portrait
        : training.thumbnail?.landscape,
      type: 'image/jpeg',
      width: NO_LANDSCAPE ? 1080 : 1920,
      height: NO_LANDSCAPE ? 1920 : 1080,
    };
  }, [training]);

  return ogMetaData;
};
