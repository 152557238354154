import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Divider,
  HStack,
  Icon,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import { IoClose, IoShareOutline } from 'react-icons/io5';

import { ProfileAvatar } from '../../profile/components';
import { useStore } from '../../common/model';
import {
  POST_TRAINING_SHARE,
  POST_TRAINING_STATISTICS,
} from '../../router/routes';
import { useWeekData } from '../../home/hooks/useWeekData';
import { PopupContainer } from '../components';
import { getTime } from '../utils';
import { useClassTypeData } from '../../home/hooks/useClassTypeData';
import {
  DAYS,
  LOGO_URL,
  useIsDesktop,
  DayCircle,
  Error,
  LoaderCenter,
} from '../../common';
import { getDayIndexAsMondayFirst } from '../../common/utils/getDayIndexAsMondayFirst';
import { useTraining } from '../hooks';
import { useTrainingOgMetaData } from '../hooks/use-training-og-meta-data';
import { OgMetaTags } from '../../common/components/meta';

const RESULT_TEXTS = {
  // if no match
  default: ' you trained hard today! Great session.',

  FUEL: ' you trained hard today! Great session.',
  BASE: ' you trained hard today! Great session.',
  BURN: ' you trained hard today! Great session.',
  'MENTAL FITNESS': ' you trained hard today! Great session.',
  FLOW: ' mindfulness is key. Way to prioritize your mental fitness!',
  SCULPT: ', you HIIT it hard today. Keep it up!',
  COOL: ', nice recovery. Way to reset, regenerate and recover!',
  BREATHE: ', nice recovery. Way to reset, regenerate and recover!',
} as {
  [key: string]: string;
};

const getResultText = (
  // type: 'BURN' | 'BASE' | 'FUEL' | 'FLOW' | 'SCULPT' | 'COOL' | 'BREATHE'
  type: string
) => RESULT_TEXTS[type] || RESULT_TEXTS.default;

// TODO Load data using use-class-history.ts and params.id (const params = useParams();)
export const PostTrainingStatistics = () => {
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();
  const location = useLocation();
  const navigateToShare = useMemo(() => {
    return location.pathname.replace(
      POST_TRAINING_STATISTICS,
      POST_TRAINING_SHARE
    );
  }, [location]);
  const { data: training, error, loading } = useTraining();
  const trainingOgMetaData = useTrainingOgMetaData(training);
  const feedback = useStore((state) => state.class.feedback);
  const userInfo = useStore((state) => state.userInfo);
  const user = useStore((state) => state.user);
  const { data: classType } = useClassTypeData(training ? training.type : '');
  const { data: week } = useWeekData();

  const name = userInfo?.name || user?.displayName;
  const time = getTime(feedback?.activeTime || 0);
  const type = !classType ? null : classType.name.toUpperCase();
  const duration = !training ? null : training.duration;

  if (error) {
    return <Error />;
  }

  if (loading) {
    return <LoaderCenter />;
  }

  // TODO Achievements
  return (
    <Box
      w="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      pos="absolute"
      zIndex="overlay"
      top={0}
      left={0}
    >
      <OgMetaTags image={trainingOgMetaData} />
      {!training ||
      !training.thumbnail?.landscape ||
      !training.thumbnail?.portrait ? null : (
        <Image
          src={
            isDesktop
              ? training.thumbnail?.landscape
              : training.thumbnail?.portrait
          }
          pos="fixed"
          w="100%"
          h="100%"
          fit="cover"
          left={0}
          filter="brightness(0.25)"
        />
      )}
      <VStack pos="relative" w="100%">
        <Image src={LOGO_URL} w="80px" h="25px" mt={5} mb={4} />
        <HStack
          w="100%"
          justifyContent="space-between"
          alignItems="center"
          px={5}
        >
          <Icon
            as={IoClose}
            boxSize={{ base: '16px', md: '22px' }}
            focusable
            cursor="pointer"
            onClick={() => navigate('/')}
          />
          <Text
            flex="1"
            fontSize={{ base: 'xl', md: 'calc(2xl -2px)' }}
            fontWeight={500}
            align="center"
          >
            {type} {duration}
          </Text>
          <Icon
            as={IoShareOutline}
            boxSize={{ base: '16px', md: '22px' }}
            focusable
            cursor="pointer"
            onClick={() => navigate(navigateToShare)}
          />
        </HStack>
      </VStack>
      <PopupContainer
        p={10}
        pb={16}
        top={{ md: '50%' }}
        m={{ md: 'auto' }}
        transform={{ md: 'translateY(-50%)' }}
      >
        <VStack w="100%" maxW={{ base: '360px' }} spacing={0}>
          <ProfileAvatar w="64px" h="64px" my="0" />
          <Text
            mt="4 !important"
            fontSize="xl"
            fontWeight={500}
            color="stone.300"
            align="center"
          >
            {!type ? null : `${name}${getResultText(type.toUpperCase())}`}
          </Text>
          <HStack
            mt="10 !important"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            {!week
              ? null
              : DAYS.map((day) => {
                  // @ts-ignore
                  const isCompleted = week.completion[day];
                  const today = getDayIndexAsMondayFirst();
                  const iteratingDayIndex = DAYS.indexOf(day);
                  return (
                    <DayCircle
                      key={day}
                      day={day.charAt(0).toUpperCase()}
                      isCompleted={isCompleted}
                      isFuture={iteratingDayIndex > today}
                      isToday={today === iteratingDayIndex}
                    />
                  );
                })}
          </HStack>
          <Divider mt="12 !important" />
          <Box mt="8 !important">
            <Text fontSize="3xl" color="sand.0" align="center">
              {time}
            </Text>
            <Text fontSize="sm" color="clay.400" align="center">
              Total Time
            </Text>
          </Box>
        </VStack>
      </PopupContainer>
    </Box>
  );
};
