import {
  VStack,
  Text,
  Button,
  Heading,
  HStack,
  Divider,
  Spinner,
} from '@chakra-ui/react';
import { FC } from 'react';

import { SubscriptionItem } from '../components/subscription-item';
import { COACHING, PERSONAL_TRAINING } from '../constants';
import { usePlan } from '../presenter';

export const Plan: FC = () => {
  const { name, subscription, setSubscription, onSelectPlan, status } =
    usePlan();

  const isLoading = status === 'loading';

  const onCoachingClick = () => {
    if (!isLoading) setSubscription(COACHING);
  };
  const onPersonalTrainingClick = () => {
    if (!isLoading) setSubscription(PERSONAL_TRAINING);
  };

  return (
    <VStack spacing="6">
      <VStack spacing="4" w="100%" textAlign="center">
        <Heading mb="4" fontSize="2xl" fontWeight="semibold">
          Let's Go!
        </Heading>
        <Text
          textAlign="center"
          fontSize="xl"
          color="stone.400"
          fontWeight="medium"
        >
          {name}, it's time to transform your physical plus mental fitness!
        </Text>
        <Text textAlign="center" fontSize="sm" color="clay.100">
          Train with real coaches delivering real workouts that produce real
          results. Join our tribe.
        </Text>
      </VStack>
      <HStack spacing="3.5" w="100%">
        <Divider orientation="horizontal" borderColor="clay.0" w="35%" />
        <Text textAlign="center" fontSize="sm" w="100%">
          Select your subscription
        </Text>
        <Divider orientation="horizontal" borderColor="clay.0" w="35%" />
      </HStack>
      <HStack spacing="3.5" w="100%">
        <SubscriptionItem
          subscriptionType="Coaching"
          price={39.99}
          description="Includes daily classes and a personal coach to keep you on track with your goals."
          borderColor={
            subscription?.type === COACHING.type ? 'sand.0' : 'stone.400'
          }
          bg={subscription?.type === COACHING.type ? 'sand.0' : 'transparent'}
          onClick={onCoachingClick}
        />
        <SubscriptionItem
          subscriptionType="Personal Training"
          price={149.99}
          description="Includes daily classes, a personal coach and live 1:1 personal training every week."
          borderColor="sand.0"
          bg={
            subscription?.type === PERSONAL_TRAINING.type
              ? 'sand.0'
              : 'transparent'
          }
          onClick={onPersonalTrainingClick}
        />
      </HStack>
      <Button
        variant="hoa-primary"
        onClick={onSelectPlan}
        w="100%"
        disabled={!subscription || isLoading}
      >
        Start 7-day Free Trial
        {isLoading && <Spinner ml="4" />}
      </Button>
      {subscription && (
        <Text fontSize="sm" textAlign="center">
          Start today with your 1 week free trial, then only $
          {subscription.price} a month. Cancel anytime.
        </Text>
      )}
    </VStack>
  );
};
