import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Auth, UserCredential } from 'firebase/auth';
import { auth } from '../../firebase';
import { getMessageFromCode, isFunction } from '../../common';
import { useStore, AuthFlow } from '../../common/model';

type AuthProvider = (
  auth: Auth,
  email: string,
  password: string
) => Promise<UserCredential>;

type AuthCallback = (user: UserCredential) => void;

export const useAuthWithEmailAndPassword = (
  authProvider: AuthProvider,
  flow: AuthFlow,
  authCallback?: AuthCallback
) => {
  const setAuthFlow = useStore((state) => state.setAuthFlow);
  const toast = useToast();
  const navigate = useNavigate();
  const onAuth = async (email: string, password: string) => {
    try {
      const user = await authProvider(auth, email, password);
      if (authCallback && isFunction(authCallback)) {
        authCallback(user);
      }
      setAuthFlow(flow);
      navigate('/');
    } catch ({ code }: any) {
      // Error should be any in catch clause :(
      toast({
        description: getMessageFromCode(code as string),
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
    }
  };

  return onAuth;
};
