import { Box, Grid, Heading } from '@chakra-ui/react';

import Achievement from './achievement';
import { useBadgesData } from '../../../common/model/badges/use-badges';
import { Error, Loader } from '../../../common';

const Achievements = ({ ...props }) => {
  const { data, loading, error } = useBadgesData();

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Box pb={{ base: 0, md: 10 }} {...props}>
      <Heading as="h1" fontSize="md" fontWeight={500} noOfLines={1}>
        Your Achievements
      </Heading>
      <Grid
        mt={4}
        gridTemplateColumns="repeat(3, 1fr)"
        columnGap="4vmax"
        rowGap={6}
      >
        {data && data.map((ach) => <Achievement key={ach.id} {...ach} />)}
      </Grid>
    </Box>
  );
};

export default Achievements;
