import { useNavigate, useParams } from 'react-router-dom';
import { HStack, Icon, Image, Spacer, Text, VStack } from '@chakra-ui/react';

import { IoClose } from 'react-icons/io5';
import dayjs from 'dayjs';
import { getTime } from '../utils';
import { useCoachData } from '../../home/hooks/useCoachData';
import { useMemo } from 'react';
import { useClassTypeData } from '../../home/hooks/useClassTypeData';
import { useClassHistoryData } from '../hooks/use-class-history';
import { Error, LoaderCenter, LOGO_URL } from '../../common';
import { OgMetaTags } from '../../common/components/meta';
import { useTraining } from '../hooks';
import { useTrainingOgMetaData } from '../hooks/use-training-og-meta-data';
import { ShareButtons } from '../components/share-buttons';

export const PostTrainingShare = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { data: classHistoryData, error, loading } = useClassHistoryData();
  const thisSessionHistoryData = useMemo(() => {
    return classHistoryData
      ? classHistoryData.filter((chd) => chd.classId === params!.id)
      : [];
  }, [classHistoryData, params]);
  const lastSessionHistoryData = useMemo(() => {
    const sorted = thisSessionHistoryData.length
      ? thisSessionHistoryData.sort(
          (hisData, hisData2) =>
            hisData.endedAt.seconds - hisData2.endedAt.seconds
        )
      : null;
    return sorted && sorted[sorted.length - 1];
  }, [thisSessionHistoryData]);
  const { data: training } = useTraining();
  const trainingOgMetaData = useTrainingOgMetaData(training);
  const { data: classType } = useClassTypeData(training ? training.type : '');
  const { data: coach } = useCoachData(training?.coach);

  const time = getTime(
    !lastSessionHistoryData ? 0 : lastSessionHistoryData.activeTime
  );
  const type = !classType ? '' : classType.name;
  const duration = !training ? '' : training.duration;
  const date = !training ? '' : training.scheduledAt;

  const socialMediaTextToShare = useMemo(
    () =>
      [
        'I did a training',
        type,
        duration,
        date ? `on ${dayjs(date).format('dddd MMM DD')}` : '',
        coach ? `, with ${coach.firstName}` : null,
        `. Total time: ${time}`,
      ]
        .join(' ')
        .replace(/\+/g, '')
        .replace(/ ([,.])/g, '$1'),
    [type, duration, date, coach, time]
  );

  if (error) {
    return <Error />;
  }

  if (loading) {
    return <LoaderCenter />;
  }

  return (
    <VStack
      w="100%"
      height="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      pos="absolute"
      zIndex="overlay"
      top={0}
      left={0}
      bg="card"
      p={12}
    >
      <OgMetaTags image={trainingOgMetaData} />
      <HStack w="100%" justifyContent="space-between" alignItems="center">
        <Icon
          as={IoClose}
          boxSize={{ base: '16px', md: '22px' }}
          focusable
          cursor="pointer"
          onClick={() => navigate('/')}
        />
        <Text
          flex="1"
          fontSize={{ base: 'xl', md: 'calc(2xl -2px)' }}
          fontWeight={500}
          align="center"
        >
          Share Activity
        </Text>
        <Spacer flex={0} />
      </HStack>

      <VStack
        borderRadius="16px"
        width="335px"
        p={6}
        bg="sand.0"
        mt={{ md: 'auto !important' }}
      >
        <Image src={LOGO_URL} w="80px" h="25px" />
        <Text fontSize="3xl" mt="10 !important" fontWeight={600}>
          {time}
        </Text>
        <Text fontSize="sm" mt="0 !important" fontWeight={600} color="stone.0">
          Total Time
        </Text>
        <Text fontSize="sm" mt="6 !important" fontWeight={400} align="center">
          {type} {duration} {date ? dayjs(date).format('dddd MMM DD') : ''}
          {coach ? `, with ${coach.firstName}` : null}
        </Text>
      </VStack>

      <VStack
        width="335px"
        mt={{ md: '6 !important' }}
        mb={{ md: 'auto !important' }}
      >
        <Text color="gray.0">Share using</Text>
        <ShareButtons
          url={window.location.href.replace(/\/share/, '')}
          text={socialMediaTextToShare}
        />
      </VStack>
    </VStack>
  );
};
