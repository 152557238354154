import { useToast } from '@chakra-ui/react';
import {
  ActionCodeSettings,
  sendEmailVerification,
  UserCredential,
} from 'firebase/auth';
import { getMessageFromCode } from '../../common';

export const useSendVerificationEmail = () => {
  const toast = useToast();
  const sendVerificationEmail = async (
    { user }: UserCredential,
    options?: ActionCodeSettings | null
  ) => {
    try {
      await sendEmailVerification(user, options);
      toast({
        description: 'Verification email has been sent',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
    } catch ({ code }: any) {
      console.error(code);
      toast({
        description: getMessageFromCode(code as string),
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
    }
  };

  return sendVerificationEmail;
};
