import { useCallback, useMemo } from 'react';
import {
  DAYS,
  SwiperVideoFeed,
  Loader,
  SwiperStabilizer,
  ComingSoon,
} from '../../common';
import { Center } from '@chakra-ui/react';
import { useWeekData } from '../hooks/useWeekData';
import { getPaginationParams } from '../components/pagination/get-pagination-params';
import { getDayIndexAsMondayFirst } from '../../common/utils/getDayIndexAsMondayFirst';
import { ClassSession } from '../../common/types/class';
import Swiper from 'swiper';
import { TrailerFooter } from '../components/trailer-footer';
import { BookFirstSessionModal } from './book-first-session-modal';
import { Week } from '../../common/types/week';

export type SingleRow = ClassSession[];

export const prepareDataToSingleRowOfSlides = (data: Week) => {
  return data.classes.map((slide) => ({
    ...slide,
    // TODO I do not like it
    children: <TrailerFooter slideItem={slide} />,
  }));
};

export const HomePage = () => {
  const { data, loading, error } = useWeekData();

  const isDayCompleted = useCallback(
    (currentIterativeIndex: number) =>
      data!.completion[DAYS[currentIterativeIndex] as keyof Week['completion']],
    [data]
  );

  const dataAsSingleRowOfSlides: SingleRow = useMemo(() => {
    return data ? prepareDataToSingleRowOfSlides(data) : [];
  }, [data]);

  const initialSlide = useMemo(
    () =>
      dataAsSingleRowOfSlides.findIndex(
        (clas) =>
          getDayIndexAsMondayFirst(clas.scheduledAt) ===
          getDayIndexAsMondayFirst()
      ),
    [dataAsSingleRowOfSlides]
  );

  const pagination = useMemo(
    () =>
      dataAsSingleRowOfSlides
        ? getPaginationParams(dataAsSingleRowOfSlides, isDayCompleted)
        : undefined,
    [dataAsSingleRowOfSlides, isDayCompleted]
  );

  const onPaginationRender = useCallback(
    (swiper: Swiper, paginationEl: HTMLElement) => {
      paginationEl.onclick = function (event) {
        const paginationItemClickedSlideId = (event.target as HTMLButtonElement)
          .dataset.slideId;
        const indexOfClickedSlide = dataAsSingleRowOfSlides.findIndex(
          (slide) => slide.id === paginationItemClickedSlideId
        );
        swiper.slideTo(indexOfClickedSlide);
      };
    },
    [dataAsSingleRowOfSlides]
  );

  if (loading) {
    return (
      <Center h="100%">
        <Loader boxSize="40px" />
      </Center>
    );
  }

  // Our error handling now gives us possibility to go to the main screen, but we already on the
  // main screen so this error handling rather shows something different
  if (error) {
    return <ComingSoon />;
  }

  return data && data.classes?.length ? (
    <SwiperStabilizer>
      <SwiperVideoFeed
        data={dataAsSingleRowOfSlides}
        withSwipeMessage={{
          localStorageItemLabel: 'home-page-swipe-message',
          message: "Swipe up to see more of today's new classes",
        }}
        swiperOptions={{
          pagination,
          initialSlide,
          onPaginationRender,
        }}
      />
      <BookFirstSessionModal />
    </SwiperStabilizer>
  ) : (
    <ComingSoon />
  );
};
