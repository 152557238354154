import { Box, useDisclosure } from '@chakra-ui/react';
import {
  UIEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Hls from 'hls.js';
import {
  useIsDesktop,
  isH265Supported,
  Error,
  LoaderCenter,
} from '../../common';
import { useIsTouchDevice } from '../../common/hooks/use-is-touch-device';
import { DEFAULT_TIME } from '../constants/video';
import { POST_TRAINING_FEEDBACK } from '../../router/routes';
import { Completed } from '../components/complete';
import { getTime } from '../utils';
import { getPercentOfElapsedTime } from '../utils';
import { useCalculateActiveTime, useTraining } from '../hooks';
import { VideoStatisticsOverlay } from '../components/video-statistics-overlay';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';
import { useStore } from '../../common/model';
import { OgMetaTags } from '../../common/components/meta';
import { useTrainingOgMetaData } from '../hooks/use-training-og-meta-data';
import { WHEN_COMPLETE } from '../../common/constants/class';

dayjs.extend(utc);
dayjs.extend(timezone);

export const Training = () => {
  const params = useParams();
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();
  const isTouchDevice = useIsTouchDevice();
  const { isOpen: isOverlayVisible, onToggle } = useDisclosure({
    defaultIsOpen: true,
  });
  const [isCompleted, setCompleted] = useState(false);
  const [startTime, setStartTime] = useState<null | number>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const activeTime = useCalculateActiveTime(videoRef.current);
  const { data: training, error, loading } = useTraining();
  const trainingOgMetaData = useTrainingOgMetaData(training);
  const setClass = useStore((state) => state.setClass);
  const setFeedback = useStore((state) => state.setFeedback);

  const onTimeUpdate = useCallback(() => {
    if (
      videoRef.current &&
      getPercentOfElapsedTime(activeTime, videoRef.current?.duration) >
        WHEN_COMPLETE
    ) {
      setCompleted(true);
    }
  }, [activeTime]);
  const onVideoClick = useCallback(
    (e: UIEvent) => {
      if (isDesktop && !isTouchDevice) {
        e.preventDefault();
      }

      onToggle();
    },
    [onToggle, isDesktop, isTouchDevice]
  );
  const onEndClick = useCallback(async () => {
    // user did not start session (but it actually tried to be started)
    if (
      startTime === null ||
      (!videoRef.current?.paused && videoRef.current?.currentTime === 0)
    ) {
      return navigate('/');
    }

    const feedback = {
      id: uuid(),
      startedAt: startTime,
      endedAt: Date.now(),
      classId: params.id as string,
      timezone: dayjs.tz.guess(),
      activeTime,
    };

    setFeedback(feedback);

    navigate(POST_TRAINING_FEEDBACK);
  }, [activeTime, navigate, params, setFeedback, startTime]);

  const onPlay = useCallback(() => {
    setStartTime(Date.now());
  }, []);

  const zone = null; /*{name: 'Zone 3', value: '6:23',}*/
  const bpm = null; /*{name: 'BPM', value: '134',}*/
  const cal = null; /*{name: 'CAL', value: '294',}*/

  // TODO Most of the values will be not available in web, but time will be from the video
  const time = useMemo(
    () => ({
      name: 'time',
      value: activeTime ? getTime(activeTime) : DEFAULT_TIME,
    }),
    [activeTime]
  );

  const loadVideoViaHLSLib = useCallback(
    (src: string, video: HTMLVideoElement) => {
      if (Hls.isSupported()) {
        // TODO Handle hls specific errors
        /*
          hls.on(Hls.Events.ERROR, function (event, data) {
            var errorType = data.type;
            var errorDetails = data.details;
            var errorFatal = data.fatal;

            switch (data.details) {
              case Hls.ErrorDetails.FRAG_LOAD_ERROR:
                // ....
                break;
              default:
                break;
            }
          });
        */
        const hls = new Hls();
        hls.loadSource(src);
        hls.attachMedia(video);
        video.play().catch((e) => console.warn('error when start playing', e));
      } else {
        // TODO If even lib did not work, we should show something
      }
    },
    []
  );

  useEffect(() => {
    if (!training) {
      return;
    }

    const video = videoRef.current;

    if (!video) {
      return;
    }

    // if video is playing do nothing
    if (!video.paused) {
      return;
    }

    const videoSrc = isH265Supported
      ? training.video.h265
      : training.video.h264;

    const videoNativeOnError = (e: any) => {
      console.warn('native video did not run, try Hls lib', e);
      loadVideoViaHLSLib(videoSrc, video);
      video.removeEventListener('error', videoNativeOnError);
    };

    // only iPhone'ish stuff
    if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.addEventListener('error', videoNativeOnError);
      video.src = videoSrc;
      video.play().catch((e) => console.warn('error when start playing', e));
      //
      // If no native HLS support, check if HLS.js is supported
      //
    } else {
      loadVideoViaHLSLib(videoSrc, video);
    }

    // don't do it, because let's say user rotate device at 1:00, he will experience start
    //  back from 0:00 experience
    // return () => hls.destroy();
    return () => video.removeEventListener('error', videoNativeOnError);
  }, [isDesktop, loadVideoViaHLSLib, training]);

  useEffect(() => {
    if (training) {
      setClass(training);
    }
  }, [setClass, training]);

  if (error) {
    return <Error />;
  }

  if (loading) {
    return <LoaderCenter />;
  }

  return (
    <Box
      w="100%"
      h="100%"
      position="absolute"
      ref={containerRef}
      zIndex="overlay"
      top={0}
      left={0}
    >
      <OgMetaTags image={trainingOgMetaData} />
      <VideoStatisticsOverlay
        isOverlayVisible={isOverlayVisible}
        bpm={bpm}
        cal={cal}
        zone={zone}
        time={time}
        onEndClick={onEndClick}
      />
      <Completed value={training?.completed || isCompleted} />
      <video
        ref={videoRef}
        id="my-player"
        controls
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        onClick={onVideoClick}
        onTouchStart={onVideoClick}
        onTimeUpdate={onTimeUpdate}
        onPlay={onPlay}
        autoPlay
        playsInline
      />
    </Box>
  );
};
