import { vestResolver } from '@hookform/resolvers/vest';
import { KeyboardEvent } from 'react';
import { useToast } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { create, test, enforce } from 'vest';

import {
  UserInfo,
  useSetUserInfoToFirestore,
  useStore,
} from '../../common/model';
import { DEFAULT_HEALTH } from '../constants';
import { fromDPFormat, toDPFormat } from '../../common';

interface EditProfileFormData {
  name?: string;
  foots?: string;
  inches?: string;
  weight?: number;
  dateOfBirth?: string;
}

const suite = create(
  ({ name, foots, inches }: Partial<EditProfileFormData>) => {
    test('name', 'Name is required', () => {
      enforce(name).isNotEmpty();
    });

    test('foots', 'Please select foots', () => {
      if (inches !== ' ') {
        enforce(foots).isNotEmpty();
      }
    });
  }
);

const getNumberFromStringInput = (value: number | string) =>
  Number(String(value).replace(',', '.'));

const getFootsAndInchesFromHeight = (height: string) => {
  const [foots, inches] = height.replace(/"/, '').split("'");

  return { foots, inches };
};

const getHeightFromFootsAndInches = (foots: string, inches: string) =>
  `${foots}'${inches}"`;

export const useEditProfile = () => {
  const {
    name: userInfoName,
    id,
    health: {
      weight = 0,
      height = '',
      dateOfBirth = undefined,
    } = DEFAULT_HEALTH,
  } = useStore((state) => state.userInfo)!;
  const {
    handleSubmit,
    formState: { errors, isSubmitting, dirtyFields },
    register,
    reset,
    setValue,
  } = useForm<EditProfileFormData>({
    defaultValues: {
      name: userInfoName,
      weight: weight || undefined,
      ...getFootsAndInchesFromHeight(height),
      dateOfBirth: dateOfBirth ? toDPFormat(dateOfBirth) : '',
    },
    reValidateMode: 'onSubmit',
    resolver: vestResolver(suite),
  });

  const { updateUserInfoInFirestore } = useSetUserInfoToFirestore();

  const toast = useToast();

  const onSaveClick = async ({
    name,
    weight,
    foots = '',
    inches = '',
    dateOfBirth,
  }: EditProfileFormData) => {
    const result: Partial<UserInfo> = {
      name,
      health: {
        ...(weight && { weight: getNumberFromStringInput(weight) }),
        ...(foots && {
          height: getHeightFromFootsAndInches(foots, inches || '0'),
        }),
        ...(dateOfBirth && { dateOfBirth: fromDPFormat(dateOfBirth) }),
      },
    };

    await updateUserInfoInFirestore(id as string, result)
      .then(() => {
        toast({
          description: 'Profile has been updated',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'bottom',
        });
        // Reset form with new value to make all fields not dirty and disable submit button
        reset({ name, weight, foots, inches, dateOfBirth });
      })
      .catch((error) => {
        console.error(error);
        toast({
          description:
            'Ups, something goes wrong. Please try again or reload page',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom',
        });
      });
  };

  // Prevent to enter e, E, +, -
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key.toLowerCase() === 'e' || e.key === '+' || e.key === '-') {
      e.preventDefault();
    }
  };

  return {
    userInfoName,
    register,
    onSaveClick: handleSubmit(onSaveClick),
    submitButtonIsDisabled:
      isSubmitting || Object.keys(dirtyFields).length === 0,
    handleKeyDown,
    errors,
    setValue,
    dateOfBirth,
  };
};
