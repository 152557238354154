import { Box, chakra } from '@chakra-ui/react';
import { InlineWidget } from 'react-calendly';
import { useStore } from '../common/model';

const PageWrapper = chakra(Box, {
  baseStyle: {
    w: '100%',
    minH: '100%',
    h: '100%',

    '& .calendly-inline-widget': {
      w: '100%',
      h: '100% !important',
      minH: '630px',
    },
  },
});

export const BookSession = () => {
  const { name, email } = useStore((state) => state.userInfo)!;

  const userData = `name=${encodeURIComponent(
    name || ''
  )}&email=${encodeURIComponent(email || '')}`;
  const url = `https://calendly.com/hoa-personal-training/30min-personal-training?hide_gdpr_banner=1&background_color=1a1a1a&text_color=ffffff&primary_color=a78256&${userData}`;
  return (
    <PageWrapper>
      <InlineWidget url={url} />
    </PageWrapper>
  );
};
