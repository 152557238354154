import { Divider, Flex, Heading, Stack } from '@chakra-ui/react';

import { Calendar } from '../components/calendar/calendar';
import Achievements from '../components/achievements/achievements';
import ScheduleFeed from '../components/schedule-feed/schedule-feed';

import { useScheduleData } from '../presenter';

export const Schedule = () => {
  const {
    schedule,
    datesToMap,
    selectedDayValue,
    setSelectedDay,
    currentCalendarDate,
    setCurrentCalendarDate,
  } = useScheduleData();

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      pt="12"
      pb={{ base: '80px', md: 0 }}
      px="0"
      direction="column"
    >
      <Heading
        as="h1"
        variant={{ base: 'hoa-h1', md: 'hoa-h1-md' }}
        noOfLines={1}
        textAlign="center"
      >
        Schedule
      </Heading>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={{ base: 10, md: 1 }}
        mt={10}
        justifyContent={{ base: 'space-between', md: 'space-around' }}
        w={{ base: '350px', md: '100%' }}
      >
        <Stack direction="column" spacing="10" alignItems="center">
          <Calendar
            datesToMap={datesToMap}
            selectedDayValue={selectedDayValue}
            setSelectedDay={setSelectedDay}
            currentCalendarDate={currentCalendarDate}
            setCurrentCalendarDate={setCurrentCalendarDate}
          />
          <Divider display={{ base: 'none', md: 'block' }} />
          <Achievements display={{ base: 'none', md: 'block' }} />
        </Stack>

        <Divider display={{ base: 'block', md: 'none' }} />

        <Divider
          display={{ base: 'none', md: 'block' }}
          orientation="vertical"
          height="auto"
          mb="10 !important"
        />

        <Achievements display={{ base: 'block', md: 'none' }} />

        <Divider display={{ base: 'block', md: 'none' }} />

        <ScheduleFeed
          data={schedule}
          selectedDayValue={selectedDayValue}
          currentCalendarDate={currentCalendarDate}
          w={{ base: 'auto', md: '240px', lg: '360px' }}
        />
      </Stack>
    </Flex>
  );
};
