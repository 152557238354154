import { vestResolver } from '@hookform/resolvers/vest';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { create, test, enforce } from 'vest';
import { ROUTES } from '../../router';
import { useStore } from '../../common/model';
export interface NameFormData {
  name: string;
}

const suite = create(({ name }: NameFormData) => {
  test('name', 'Name is required', () => {
    enforce(name).isNotEmpty();
  });
});

export const useOnboardingName = () => {
  const setOnboardingData = useStore((state) => state.setOnboardingData);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NameFormData>({
    resolver: vestResolver(suite),
    reValidateMode: 'onSubmit',
  });

  const onSubmit = ({ name }: NameFormData) => {
    setOnboardingData({ name });
    navigate(`${ROUTES.ONBOARDING}/${ROUTES.ONBOARDING_TRAIN}`);
  };

  return {
    register,
    onSubmit: handleSubmit(onSubmit),
    errors,
  };
};
