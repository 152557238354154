import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { BookSessionIcon } from '../components';
import { useShowBookFirstSession } from '../presenter/use-show-book-first-session';

export const BookFirstSessionModal = () => {
  const { isOpen, onClose, name, onScheduleClick } = useShowBookFirstSession();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      motionPreset="slideInBottom"
      scrollBehavior="inside"
      size="lg"
      variant="hoa-bottom-modal"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <Flex w="100%" justifyContent="flex-end">
          <Button
            variant="link"
            color="clay.0"
            fontWeight="medium"
            fontSize="sm"
            onClick={onClose}
          >
            Skip
          </Button>
        </Flex>
        <VStack
          w="100%"
          justifyContent="center"
          textAlign="center"
          spacing="6"
          maxWidth="320px"
          margin="0 auto"
          pb="4"
        >
          <BookSessionIcon fontSize="64px" color="sand.0" />
          <Heading fontSize="xl" color="stone.400">
            {name}, book your first personal training session
          </Heading>
          <Text>
            You'll be able to schedule your following weekly sessions with your
            personal trainer
          </Text>
          <Button variant="hoa-primary" onClick={onScheduleClick} w="100%">
            Schedule now
          </Button>
        </VStack>
      </ModalContent>
    </Modal>
  );
};
