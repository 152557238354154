import { doc, DocumentReference, getDoc, onSnapshot } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { useStore } from '../../common/model';
import isoWeek from 'dayjs/plugin/isoWeek';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { Week } from '../../common/types/week';

dayjs.extend(isoWeek);

export type WeekLoadingData = {
  data: null | Week;
  loading: boolean;
  error: null | string;
};

const WEEK_DATA_INITIAL_STATE = { data: null, loading: true, error: null };

async function asyncQuery(
  ref: DocumentReference,
  setter: (d: WeekLoadingData) => void
) {
  const docSnap = await getDoc(ref);

  if (!docSnap.exists()) {
    setter({
      data: null,
      loading: false,
      error: 'No data for week!',
    });
  }
}

export const useWeekData = () => {
  // Why it is not outside the hook?
  // there is slight chance that user will be in the end of the week and request data right on the
  // border of current week and next week (like on Sunday 23:59) so this constant is placed here to
  // prevent incorrect data sending
  const WEEK_PATH = `${dayjs().year()}_${dayjs().isoWeek()}`;

  const user = useStore((state) => state.user);
  const userRef = useMemo(() => doc(firestore, 'users', user!.uid), [user]);
  const weeksRef = useMemo(
    () => doc(userRef, 'weeks', WEEK_PATH),
    [WEEK_PATH, userRef]
  );

  const [week, setWeek] = useState<WeekLoadingData>(WEEK_DATA_INITIAL_STATE);

  useEffect(() => {
    asyncQuery(weeksRef, setWeek);
  }, [weeksRef]);

  // Initial set of data and subscription for future data updates.
  // initial happens after `asyncQuery` is completed
  useEffect(() => {
    onSnapshot(weeksRef, {
      next(snapshot) {
        if (snapshot.exists()) {
          setWeek({
            data: snapshot.data() as Week,
            loading: false,
            error: null,
          });
        } else {
          // do nothing, because what if there was data, and we get update with incorrect data?
          // setWeek(WEEK_DATA_INITIAL_STATE);
        }
      },
      error(error) {
        console.error(error);
      },
    });
  }, [setWeek, weeksRef]);

  return week;
};
