/* TODO Instagram */
// https://stackoverflow.com/questions/62071327/share-content-of-a-webpage-to-instagram-story

import {
  HStack,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  IoLogoFacebook,
  IoLogoLinkedin,
  IoLogoTwitter,
  IoLogoWhatsapp,
  IoMailSharp,
  IoShareOutline,
} from 'react-icons/io5';

type ShareButtonsProps = {
  url?: string;
  text?: string;
};

const TelegramIcon = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="white"
    viewBox="0 -4 24 24"
    {...props}
  >
    <path d="M.707 8.475C.275 8.64 0 9.508 0 9.508s.284.867.718 1.03l5.09 1.897 1.986 6.38a1.102 1.102 0 0 0 1.75.527l2.96-2.41a.405.405 0 0 1 .494-.013l5.34 3.87a1.1 1.1 0 0 0 1.046.135 1.1 1.1 0 0 0 .682-.803l3.91-18.795A1.102 1.102 0 0 0 22.5.075L.706 8.475z" />
  </svg>
);

export const ShareButtons = ({
  url = window.location.href,
  text = `Look at my completed training on House of Athlete +`,
}: ShareButtonsProps) => {
  const encodedUrl = encodeURI(url);
  const encodedText = encodeURI(text);
  const encodedTextWithUrl = `${encodedText} \n ${encodedUrl}`;
  return (
    <HStack justifyContent="space-between" w="100%" mt="4 !important">
      <Link
        href={`https://facebook.com/sharer/sharer.php?u=${encodedUrl}`}
        target="_blank"
        rel="noopener noreferrer"
        isExternal
      >
        <VStack>
          <Icon borderRadius="3" boxSize="48px" as={IoLogoFacebook} />
          <Text fontWeight={500} fontSize="xs">
            Facebook
          </Text>
        </VStack>
      </Link>
      <Link
        href={`whatsapp://send?text=${encodedTextWithUrl}`}
        target="_blank"
        rel="noopener noreferrer"
        isExternal
      >
        <VStack>
          <Icon borderRadius="3" boxSize="48px" as={IoLogoWhatsapp} />
          <Text fontWeight={500} fontSize="xs">
            WhatsApp
          </Text>
        </VStack>
      </Link>
      <Link
        href={`https://twitter.com/intent/tweet/?text=${encodedTextWithUrl}`}
        target="_blank"
        rel="noopener noreferrer"
        isExternal
      >
        <VStack>
          <Icon borderRadius="3" boxSize="48px" as={IoLogoTwitter} />
          <Text fontWeight={500} fontSize="xs">
            Twitter
          </Text>
        </VStack>
      </Link>
      <Menu>
        <MenuButton aria-label="Options" _expanded={{ color: 'clay.0' }}>
          <Icon borderRadius="3" boxSize="48px" as={IoShareOutline} />
          <Text fontWeight={500} fontSize="xs">
            More
          </Text>
        </MenuButton>
        <MenuList bg="rgba(0, 0, 0, 0.35)" border="0 none" py="0">
          <Link
            href={`https://www.linkedin.com/shareArticle?url=${encodedUrl}`}
            target="_blank"
            rel="noopener noreferrer"
            isExternal
          >
            <MenuItem
              icon={
                <Icon borderRadius="3" boxSize="36px" as={IoLogoLinkedin} />
              }
              borderTopRadius="3"
            >
              <Text fontWeight={500}>LinkedIn</Text>
            </MenuItem>
          </Link>
          <Link
            href={`https://t.me/share/url?url=${encodedUrl}&text=${encodedText}`}
            target="_blank"
            rel="noopener noreferrer"
            isExternal
          >
            <MenuItem
              icon={<Icon borderRadius="3" boxSize="36px" as={TelegramIcon} />}
            >
              <Text fontWeight={500}>Telegram</Text>
            </MenuItem>
          </Link>
          <Link
            href={`mailto:?subject=${encodedText}&body=${encodedUrl}`}
            target="_blank"
            rel="noopener noreferrer"
            isExternal
          >
            <MenuItem
              icon={<Icon borderRadius="3" boxSize="36px" as={IoMailSharp} />}
              borderBottomRadius="3"
            >
              <Text fontWeight={500}>Email</Text>
            </MenuItem>
          </Link>
        </MenuList>
      </Menu>
    </HStack>
  );
};
