import { useAuthCheck } from './use-auth-check';
import { privateRouter } from './private-router';
import { publicRouter } from './public-router';
import { RouterProvider } from 'react-router-dom';
import { useUserInfo } from './use-user-info';

export const Router = () => {
  const { user, isPending } = useAuthCheck();
  useUserInfo();

  if (isPending) return null;

  const router = user ? privateRouter : publicRouter;

  return (
    <RouterProvider
      router={router}
      fallbackElement={<div>Router provider fallback</div>}
    />
  );
};
