import { Box, ChakraProps, Text } from '@chakra-ui/react';
import { UserAvatar } from '../../common';
import { FC, PropsWithChildren } from 'react';

export const ProfileAvatar: FC<
  PropsWithChildren<{ mode?: 'edit' } & ChakraProps>
> = ({ mode, ...props }) => (
  <Box
    borderRadius="50%"
    border="2px solid"
    borderColor="sand.0"
    marginTop="8"
    mb="12"
    w="120px"
    h="120px"
    padding="6px"
    pos="relative"
    {...props}
  >
    <UserAvatar />
    {mode === 'edit' ? (
      <Text
        fontSize="10px"
        w="200px"
        display="block"
        pos="absolute"
        left="calc(-50% + 15px)"
        bottom="-20px;"
        align="center"
        color="sand.0"
      >
        (click to edit your profile image)
      </Text>
    ) : null}
  </Box>
);
