import { Icon, IconProps } from '@chakra-ui/react';

export const TribeIcon: React.FC<IconProps> = (props) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 29"
    {...props}
  >
    <path
      d="M3.93 19.37a1.6 1.6 0 1 0 0-3.21 1.6 1.6 0 0 0 0 3.21ZM3.9 21.21a3 3 0 0 0-2.05.8C1.3 22.5 1 23.2 1 23.9v.78h5.8v-.78c0-.71-.31-1.4-.86-1.9a3 3 0 0 0-2.04-.8v0ZM3.93 8.57a1.6 1.6 0 1 0 0-3.21 1.6 1.6 0 0 0 0 3.2ZM3.9 10.41a3 3 0 0 0-2.05.8C1.3 11.7 1 12.4 1 13.1v.78h5.8v-.78c0-.71-.31-1.4-.86-1.9a3 3 0 0 0-2.04-.8v0ZM21.53 19.37a1.6 1.6 0 1 0 0-3.21 1.6 1.6 0 0 0 0 3.21ZM21.5 21.21a3 3 0 0 0-2.05.8c-.54.5-.85 1.19-.85 1.9v.78h5.8v-.78c0-.71-.31-1.4-.86-1.9a3 3 0 0 0-2.04-.8v0ZM21.53 8.57a1.6 1.6 0 1 0 0-3.21 1.6 1.6 0 0 0 0 3.2ZM21.5 10.41a3 3 0 0 0-2.05.8c-.54.5-.85 1.19-.85 1.9v.78h5.8v-.78c0-.71-.31-1.4-.86-1.9a3 3 0 0 0-2.04-.8v0ZM12.73 22.18a1.6 1.6 0 1 0 0-3.21 1.6 1.6 0 0 0 0 3.21ZM12.7 24.02a3 3 0 0 0-2.05.8c-.55.5-.85 1.19-.85 1.9v.78h5.8v-.78c0-.71-.31-1.4-.86-1.9a3 3 0 0 0-2.04-.8v0ZM12.73 4.21a1.6 1.6 0 1 0 0-3.21 1.6 1.6 0 0 0 0 3.21ZM12.7 6.05a3 3 0 0 0-2.05.8c-.55.5-.85 1.2-.85 1.9v.78h5.8v-.77c0-.72-.31-1.4-.86-1.91a3 3 0 0 0-2.04-.8v0Z"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
