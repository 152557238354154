import {
  useCallback,
  FC,
  PropsWithChildren,
  SetStateAction,
  Dispatch,
} from 'react';
import { CalendarCore } from './calendar-core';
import { convertDateToSingleFormat } from '../../../common/utils/convertDateToSingleFormat';
import { DatesMap } from '../../model/types';
import { Dayjs } from 'dayjs';

type CalendarComponentProps = {
  datesToMap: DatesMap;
  selectedDayValue: Date | null;
  setSelectedDay: Dispatch<SetStateAction<null | Date>>;
  currentCalendarDate: Dayjs;
  setCurrentCalendarDate: Dispatch<SetStateAction<Dayjs>>;
};

export const Calendar: FC<PropsWithChildren<CalendarComponentProps>> = ({
  datesToMap,
  selectedDayValue,
  setSelectedDay,
  currentCalendarDate,
  setCurrentCalendarDate,
}) => {
  const getDayData = useCallback(
    (day: Date) => {
      const dayInTheMap = datesToMap[convertDateToSingleFormat(day)] || [];
      const selected =
        !!selectedDayValue &&
        convertDateToSingleFormat(selectedDayValue) ===
          convertDateToSingleFormat(day);
      const dayData = {
        selected,
        setSelected: () => setSelectedDay(selected ? null : day),
        daysData: dayInTheMap,
      };
      return dayData;
    },
    [datesToMap, selectedDayValue, setSelectedDay]
  );

  return (
    <CalendarCore
      currentCalendarDate={currentCalendarDate}
      setCurrentCalendarDate={setCurrentCalendarDate}
      getDayData={getDayData}
      setSelectedDay={setSelectedDay}
    />
  );
};
