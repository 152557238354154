import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../router';

export const useStartInfo = () => {
  const navigate = useNavigate();
  const onStartClick = () =>
    navigate(`${ROUTES.ONBOARDING}/${ROUTES.ONBOARDING_NAME}`);

  const onHaveAccountClick = () => navigate(`${ROUTES.LOGIN}`);

  return { onStartClick, onHaveAccountClick };
};
