import { useNavigate } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import { ROUTES } from '../../router';
import { StyleProps } from '@chakra-ui/styled-system';

export const StartButton = ({ id, ...props }: { id: string } & StyleProps) => {
  const n = useNavigate();

  return (
    <Button
      variant="hoa-primary"
      onClick={() => n(`${ROUTES.TRAIN}/${id}`)}
      {...props}
    >
      Start session
    </Button>
  );
};
