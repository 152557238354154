import { ROUTES } from '../../router';
import { DescreteSlider, StepWrapper } from '../components';
import { useOnboardingStep } from '../presenter';

export const Fuel = () => {
  const { descreteSliderProps, goToNextStep, name, setOnboardingData } =
    useOnboardingStep(`${ROUTES.ONBOARDING}/${ROUTES.ONBOARDING_RECOVER}`, 5);

  const onNextClick = () => {
    setOnboardingData({
      fuel: descreteSliderProps.sliderValue,
    });
    goToNextStep();
  };

  return (
    <StepWrapper
      title="Fuel"
      text={`${name}, how would you rate your overall nutrition?`}
      buttonText="Next"
      onClick={onNextClick}
      step={2}
    >
      <DescreteSlider
        {...descreteSliderProps}
        min={0}
        max={10}
        startTitle="0"
        endTitle="10"
      />
    </StepWrapper>
  );
};
