import { useBreakpointValue } from '@chakra-ui/react';
import { useMatch } from 'react-router-dom';
import { useCoachesDrawer } from '../../chat/presenter';
import { isCoach } from '../../common';

import { useStore } from '../../common/model';
import { ROUTES } from '../../router';

export const useMainNavigation = () => {
  // could be useMatchMany when there are several pages
  const shouldMenuPreventOverlap = useMatch(ROUTES.TRAIN);
  const match = useMatch(ROUTES.PROFILE);
  const avatarPosition = useBreakpointValue({ base: 'fixed', md: 'static' });
  const roles = useStore((state) => state.roles);
  const { totalUnreadCount } = useStore((state) => state.chat);
  const { onListDrawerOpen, onProgressDrawerOpen } = useCoachesDrawer();

  return {
    shouldMenuPreventOverlap,
    match,
    avatarPosition,
    isCoach: isCoach(roles),
    onListDrawerOpen,
    onProgressDrawerOpen,
    totalUnreadCount,
  };
};
