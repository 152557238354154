import { VStack, HStack, Divider, Text } from '@chakra-ui/react';

import {
  SocialButton,
  AppleIcon,
  FacebookIcon,
  GoogleIcon,
} from '../../common/components';
import { AuthFlow } from '../../common/model';
import { useAuthWithSocial } from '../presenter';

interface SocialBlockProps {
  title: string;
  flow: AuthFlow;
}

export const SocialAuthBlock: React.FC<SocialBlockProps> = ({
  title,
  flow,
}) => {
  const { signInWithApple, signInWithFacebook, signInWithGoogle } =
    useAuthWithSocial(flow);

  return (
    <VStack w="100%" spacing="4">
      <HStack spacing="3.5" w="100%">
        <Divider orientation="horizontal" borderColor="clay.0" w="75%" />
        <Text textAlign="center" fontSize="sm" w="100%">
          {title}
        </Text>
        <Divider orientation="horizontal" borderColor="clay.0" w="75%" />
      </HStack>
      <HStack w="100%" spacing="6">
        <SocialButton onClick={signInWithFacebook}>
          <FacebookIcon w="24px" h="24px" />
        </SocialButton>
        <SocialButton onClick={signInWithGoogle}>
          <GoogleIcon w="24px" h="24px" />
        </SocialButton>
        <SocialButton onClick={signInWithApple}>
          <AppleIcon w="24px" h="24px" />
        </SocialButton>
      </HStack>
    </VStack>
  );
};
